
import { makeStyles } from 'tss-react/mui';



const useStyles = makeStyles()((theme: any) => ({
  //
  drawerHeaderContent: {
    height: 80, display: 'flex', alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar, justifyContent: 'flex-end',
  },
})
);

export default function LPDrawerHeader() {
  const {classes} = useStyles();
  return (
    <div className={classes.drawerHeaderContent} />
  );
}
