import { AppState } from "./rootReducer";

export const date = (state: AppState) => state.accounting.date;
export const jobId = (state: AppState) => state.accounting.jobId;
export const selectedJobList = (state: AppState) => state.accounting.selectedJobList;
export const userCred = (state: AppState) => state.auth.userCred;
export const sessionState = (state: AppState) => state.auth.sessionState;
//export const hasSibling = state => state.auth.hasSibling;
//export const hasLoggedOut = state => state.auth.hasLoggedOut;
export const isLogged = (state: AppState) => state.auth.isLogged;
export const tabId = (state: AppState) => state.auth.tabId;
export const helpVSEInitialized = (state: AppState) => state.trans.helpVSEInitialized;
export const isTransGetInProgress = (state: AppState) =>  state.trans.isTransGetInProgress;
export const termId = (state: AppState) =>  state.trans.termId;

// to be checked for removal
export const showHelpContent = state =>  (state as AppState).trans.showHelpContent;
export const showOpenModalWindow = state =>  (state as AppState).trans.showOpenModalWindow;
export const userProfileData = state =>  (state as AppState).profile.userProfileData;
//export const selected = state =>  (state as AppState).menuItem.selected;