import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Papyrus from '../../assets/images/background.jpg';
import LogoSimple from '../../components/SVGLogoSimple';
import U from '../../felles/Utils';
import UserCred from '../../models/UserCred';
import * as actions from '../../store/actions/index';
import { AppState } from '../../store/rootReducer';

const useStyles: any = makeStyles()((/*theme*/) => ({
  backgroundImg: {
    backgroundImage: `url(${Papyrus})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100vw',
    height: '100vh',
  },

  root: {
    minWidth: 250,
    minHeight: 400,
    width: 350,
    padding: 20,
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 10,
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    '& .MuiTextField-root': {
      marginBottom: '15px',
      width: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.85)',
      borderColor: '#fff',
    },
    '& .MuiButton-root': {
      marginBottom: '15px',
      width: '100%',
      backgroundColor: '#d30535',
      '&:hover': {
        borderColor: '#fff',
        backgroundColor: '#cd5a6e',
        boxShadow: 'none',
      },
    },
    overrides: {
      MuiOutlinedInput: {
        root: {
          position: 'relative',
          '& $notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
          },
          //'&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          '&:hover': {
            borderColor: '#4A90E2',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
            },
          },
          '&$focused $notchedOutline': {
            borderColor: '#4A90E2',
            borderWidth: 1,
          },
        },
      },
      MuiFormLabel: {
        root: {
          '&$focused': {
            color: '#4A90E2',
          },
        },
      },
    },
  },
}));
/*
 *
 */
const Logout = () => {
  //
  const dispatch = useDispatch();
  const open = useSelector((state) => (state as AppState).auth.isLoginDialogueOpen);
  const isLoginStateUI = useSelector((state) => (state as AppState).auth.sessionState);
  const {classes} = useStyles();
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const isLoading: boolean = useSelector((state) => (state as AppState).utils.isLoading);
  const isLogged: number = useSelector((state) => (state as AppState).auth.isLogged);
  const isAlreadyLoggedFromAnotherTab: boolean = useSelector((state) => (state as AppState).auth.isAlreadyLoggedFromAnotherTab);
  const activeTab: boolean = useSelector((state) => (state as AppState).auth.activeTab);

  useEffect(() => {
    // Forces a logout on home page enter
    if (activeTab && isLogged === 4 && isAlreadyLoggedFromAnotherTab) {
      dispatch(actions.setSessionUIState(3));
    }
    if (!activeTab && isLogged === 4 && isAlreadyLoggedFromAnotherTab) {
      dispatch(actions.setSessionUIState(3));
    }

    if (activeTab && isLogged === 4 && !isAlreadyLoggedFromAnotherTab) dispatch(actions.setSessionUIState(-1));
  }, [dispatch, isLogged, isAlreadyLoggedFromAnotherTab]);

  const handleClose = () => {
    dispatch(actions.setLoginDialogOpeningState(false));
  };
  //
  function handleSubmit(event) {
    event.preventDefault();
    dispatch(actions.userLoginToAutodataGet(new UserCred(user, password, '', false)));
  }

  function handleRefresh() {
    window.location.reload();
  }

  /*
  isLoginStateUI === -1 is the default state
  isLoginStateUI ===  0 autologin is in progress
  isLoginStateUI ===  1 session is autologged / cloned
  isLoginStateUI ===  2 user logged out from dispatched session
  */
  return (
    <>
      {/*'issssssssssssssssssssssssssssssssssss ' + isLoginStateUI + ' hasSibling: ' + hasSibling + '  isLogged: ' + isLogged*/}
      {isLoginStateUI === 0 ? (
        <>Initializing....</>
      ) : isLoginStateUI === 1 ? (
        <Dialog open={true} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{'Du er nå logget ut av denne fanen/vindu.'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Klikk på «Forbli innlogget» hvis du ønsker å logge deg inn igjen eller lukk fanen hvis du er ferdig.
              <br />
              Husk at du fremdeles er logget inn fra en annen fane.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRefresh} color="primary" autoFocus>
              Forbli innlogget.
            </Button>
          </DialogActions>
        </Dialog>
      ) : isLoginStateUI === 2 ? (
        <Dialog open={true} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{'Det ser ut til at du prøver å logge deg inn fra en annen fane/vindu.'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Klikk på «Logg inn» hvis du vil logge deg inn på fra denne fanen.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRefresh} color="primary" autoFocus>
              Logg inn
            </Button>
          </DialogActions>
        </Dialog>
      ) : isLoginStateUI === 3 ? (
        <Dialog open={true} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{'Du er logget inn fra en annen fane/vindu.'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Klikk på «Logg inn» hvis du vil logge deg inn på fra denne fanen.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRefresh} color="primary" autoFocus>
              Logg inn
            </Button>
          </DialogActions>
        </Dialog>
      ) : isLoginStateUI === -1 && isLoading ? (
        <>place holder....</>
      ) : isLoginStateUI === -1 && !isLoading ? (
        <div className={classes.backgroundImg}>
          <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">{'Opplysningene du oppga er feilaktige. '}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">Brukernavn og/eller passord er ikke korrekt. Forsøk igjen.</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
            <div className="form-login__logo">
              <LogoSimple width="150" />
            </div>
            <div className="form-login__h1 centered">Auto Online</div>
            <div className="form-login__h2 marginTopBottom15">Velkommen</div> <div className="marginTopBottom5">Brukernavn</div>
            <div className="form-login__texfield">
              <TextField
                type="text"
                name="user"
                onInput={(e) => setUser((e.target as HTMLTextAreaElement).value)}
                placeholder=""
                variant="outlined"
                inputProps={{ tabIndex: 1 }}
              />
            </div>
            <div>
              <div className="row marginTopBottom5">
                {' '}
                <div className="column ">Passord</div>{' '}
                <div className="column right">
                  <NavLink to={U.HOMEPAGE_CONTEXT + '/passwordLinkRecovery'}>Glemt passord?</NavLink>
                </div>
              </div>
              <TextField
                type="password"
                name="password"
                onInput={(e) => setPassword((e.target as HTMLTextAreaElement).value)}
                placeholder=""
                variant="outlined"
                inputProps={{ tabIndex: 2 }}
              />
            </div>
            <div className="form-login__submit-button centered marginTopBottom15">
              <Button type="submit" variant="contained" color="primary" disableElevation tabIndex={3}>
                Logg inn
              </Button>
            </div>
          </form>
        </div>
      ) : (
        <> ....</>
      )}
    </>
  );
};

export default Logout;
