import React, { useRef } from 'react';
import CustomInput from "./Input";
import { Paper, List, ListItem, ListItemText } from "@mui/material";

const SearchFields = ({
  alfa,
  artikkel,
  varegruppe,
  kundenummerInput,
  setAlfa,
  setArtikkel,
  setVaregruppe,
  setKundenummerInput,
  suggestedCustomers,
  handleCustomerSelect,
  kundenummerRef
}) => {
  const artikkelRef = useRef<HTMLInputElement>(null);
  const varegruppeRef = useRef<HTMLInputElement>(null);

  const handleChange = (setter, nextRef: React.RefObject<HTMLInputElement> | null = null, maxLength: number | null = null) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    console.log('Input Value:', value);
    setter(value);

    // If maxLength is specified and reached, focus the next input field
    if (maxLength && value.length === maxLength && nextRef?.current) {
      nextRef.current.focus();
    }
  };

  
  const handleKundenummerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setKundenummerInput(value);
  };

  const handleCustomerSelectWrapper = (customer) => {
    // Set kundenummerInput only, without modifying varegruppe
    setKundenummerInput(customer.id);
    // If any additional logic needs to be added when selecting a customer, do it here.
    handleCustomerSelect(customer);
  };

  const inputWidth = kundenummerRef.current ? kundenummerRef.current.offsetWidth : 'auto';

  return (
    <div style={{ padding:5, display: "flex", marginBottom: "10px", position: "relative" }}>
      <CustomInput
        value={alfa}
        onChange={handleChange(setAlfa, artikkelRef, 3)}
        placeholder="Alfa"
      />
      <CustomInput
        value={artikkel}
        onChange={handleChange(setArtikkel, varegruppeRef, 14)}
        placeholder="Artikkel"
        ref={artikkelRef}
      />
      <CustomInput
        value={varegruppe}
        onChange={handleChange(setVaregruppe, kundenummerRef, 5)}
        placeholder="Varegruppe"
        ref={varegruppeRef}
      />
      <div style={{ position: "relative", width: '100%' }}>
        <CustomInput
          value={kundenummerInput}
          onChange={handleKundenummerChange}
          placeholder="Kundenummer"
          ref={kundenummerRef}
        />
        {suggestedCustomers && suggestedCustomers.length > 0 && suggestedCustomers[0].id && (
          <Paper
            style={{
              position: "absolute",
              top: "100%",
              left: 0,
              width: inputWidth,
              zIndex: 1,
              maxHeight: "200px",
              overflowY: "auto",
            }}
          >
            <List>
              {suggestedCustomers.map((customer) => (
                <ListItem
                  button
                  key={customer.id}
                  onClick={() => handleCustomerSelectWrapper(customer)}
                >
                  <ListItemText primary={`${customer.id} - ${customer.name}`} />
                </ListItem>
              ))}
            </List>
          </Paper>
        )}
      </div>
    </div>
  );
};

export default SearchFields;
