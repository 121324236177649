import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import HjelpFormData from '../models/HjelpFormData';
import NavFormData from '../models/NavFormData';

import ErrorMSG from '../models/payload/ErrorMSG';
import HjelpPostFormData from '../models/HjelpPostFormData';
import LdapUser from '../models/LdapUser';
import MenuItemTarget from '../models/MenuItemTarget';
import PassParam from '../models/PassParams';
import PDFFormData from '../models/PDFFormData';
import SupportFormData from '../models/SupportFormData';
import UserCred from '../models/UserCred';
import UserUpdatePayload from '../models/UserUpdatePayload';
import U from './Utils';
import { LogMsg } from '../models/payload/LogMsg';
import TaleCriteriaItem from '../models/TaleCriteriaItem';
import TaleFavorittListe from '../models/TaleFavorittListe';
import TaleSearchParams from '../models/TaleSearchParams';
import ExcelFormData from '../models/ExcelFormData';
import BugFormData from '../models/BugFormData';

const _timeOut = 300000;

//          _  _   _ _____ ___  ___   _ _____ _       _   ___ ___ ___  _   _ _  _ _____ ___ _  _  ___
//         /_\| | | |_   _/ _ \|   \ /_\_   _/_\     /_\ / __/ __/ _ \| | | | \| |_   _|_ _| \| |/ __|
//        / _ \ |_| | | || (_) | |) / _ \| |/ _ \   / _ \ (_| (_| (_) | |_| | .` | | |  | || .` | (_ |
//       /_/ \_\___/  |_| \___/|___/_/ \_\_/_/ \_\ /_/ \_\___\___\___/ \___/|_|\_| |_| |___|_|\_|\___|

export const getACC1List = (date: Date) => {
  const config = {
    method: 'get',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/account`,
    maxContentLength: 1000000,
    timeout: 20000,
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    params: { date: date.getFullYear() + '-' + (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) },
    responseType: 'json',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
  /**/
};

export const getACC1CSVList = (date: Date) => {
  const config = {
    method: 'get',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/accountCSV`,
    maxContentLength: 1000000,
    timeout: 20000,
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    params: { date: date.getFullYear() + '-' + (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) },
    responseType: 'stream',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
  /**/
};

export const getJobIdMarkedAsDeleted = (jobId) => {
  const config = {
    method: 'get',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/account/asdeleted`,
    maxContentLength: 1000000,
    timeout: 20000,
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    params: { jobID: jobId },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'comma' });
    },
    responseType: 'json',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
  /**/
};

//          _  _   _ _____ ___  ___   _ _____ _       _  _   _ _____ _  _
//         /_\| | | |_   _/ _ \|   \ /_\_   _/_\     /_\| | | |_   _| || |
//        / _ \ |_| | | || (_) | |) / _ \| |/ _ \   / _ \ |_| | | | | __ |
//       /_/ \_\___/  |_| \___/|___/_/ \_\_/_/ \_\ /_/ \_\___/  |_| |_||_|

export const getUserProfileData = () => {
  const config = {
    method: 'get',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/profile/getuserdata`,
    maxContentLength: 1000000,
    timeout: 20000,
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    responseType: 'json',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

export const updateUserProfileData = (userProfileData: UserUpdatePayload) => {
  const config = {
    method: 'post',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/profile/updateuserdata`,
    maxContentLength: 1000000,
    timeout: 20000,
    data: userProfileData,
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    responseType: 'text',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

export const loginToAutodata = (userCred: UserCred, tabId: string) => {
  const config = {
    method: 'post',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/authtab`,
    maxContentLength: 1000000,
    timeout: 10000,
    headers: {
      'content-type': 'application/json',
      Accept: 'application/json;charset=utf-8',
    },
    data: {
      username: userCred.username,
      password: userCred.password,
      tabId,
    },
    responseType: 'json',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

/************************************************************** */

export const autoLoginToAutodata = (token: string, btsg: string) => {
  const tabId = btsg;
  const config = {
    method: 'post',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/autoauthtab`,
    maxContentLength: 1000000,
    timeout: 20000,
    headers: {
      'content-type': 'application/json',
      Accept: 'application/json;charset=utf-8',
    },
    data: {
      token,
      tabId,
    },
    responseType: 'json',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};


export const autoLoginLog = (userCred: UserCred, tabId: string) => {
  const config = {
    method: 'post',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/authLog`,
    maxContentLength: 1000000,
    timeout: 20000,
    headers: {
      'content-type': 'application/json',
      Accept: 'application/json;charset=utf-8',
    },
    data: {
      username: userCred.username,
      password: userCred.password,
      tabId,
    },
    responseType: 'json',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};


export const getRequestLinkPasswordLinkRecovery = (userCred: UserCred) => {
  const config = {
    method: 'post',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/pwdreset/link`,
    maxContentLength: 1000000,
    timeout: 20000,
    headers: {
      'content-type': 'application/json',
      Accept: 'application/json;charset=utf-8',
    },
    data: {
      username: userCred.username,
      dest: window.location.protocol + '//' + window.location.host + U.HOMEPAGE_CONTEXT + '/passwordupdate/',
    },
    //  params: { user: '{"upn":"' + userCred.username + '"}' },
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

export const requestOfPasswordChange = (p: PassParam) => {
  const config = {
    method: 'post',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/pwdreset/change`,
    maxContentLength: 1000000,
    timeout: 20000,
    headers: {
      'content-type': 'application/json',
      Accept: 'application/json;charset=utf-8',
    },
    data: {
      passwordA: p.passwordA,
      passwordB: p.passwordB,
      token: p.token,
    },
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

//          _  _   _ _____ ___  ___   _ _____ _     _  _   ___   _____ ___   _ _____ ___ ___  _  _
//         /_\| | | |_   _/ _ \|   \ /_\_   _/_\   | \| | /_\ \ / /_ _/ __| /_\_   _|_ _/ _ \| \| |
//        / _ \ |_| | | || (_) | |) / _ \| |/ _ \  | .` |/ _ \ V / | | (_ |/ _ \| |  | | (_) | .` |
//       /_/ \_\___/  |_| \___/|___/_/ \_\_/_/ \_\ |_|\_/_/ \_\_/ |___\___/_/ \_\_| |___\___/|_|\_|

export const getMenuItems = () => {
  const config = {
    method: 'get',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/item`,
    maxContentLength: 1000000,
    timeout: 20000,
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    //params: { roles: roles },
    responseType: 'json',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
  /**/
};

//        _  _ ___ ___ ___ _    ___   ___  __  __   _           _   ___ ___
//       | || | __|_ _| _ \ |  / _ \ / _ \|  \/  | ( )  ___    /_\ | _ \_ _|
//       | __ | _| | ||   / |_| (_) | (_) | |\/| | |/  (_-<   / _ \|  _/| |
//       |_||_|___|___|_|_\____\___/ \___/|_|  |_|     /__/  /_/ \_\_| |___|

export const loginToHeirloomAPI = (userCred: UserCred, tabId: string) => {
  const config = {
    method: 'get',
    baseURL: `${`${U.URL_ADDRESS}${U.HEIRLOOM_SERVICE}`}/menu.html`,
    maxContentLength: 1000000,
    timeout: 20000,
    auth: {
      username: userCred.username,
      password: userCred.password,
    },
    headers: {
      'content-type': 'application/json',
    },
    params: { user: userCred.username, password: userCred.password, btsg: tabId },
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

export const logoutFromHeirloomAPITerminal = () => {
  const config = {
    method: 'get',
    baseURL: `${`${U.URL_ADDRESS}${U.HEIRLOOM_SERVICE}`}/terminal`,
    maxContentLength: 1000000,
    timeout: 20000,
    headers: {
      'content-type': 'application/json',
    },
    params: { endsession: 'yes' },
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

export const fetchTransById = (menuItemTarget: MenuItemTarget, tabId: string) => {
  const _baseUrl = `${`${U.URL_ADDRESS}${U.HEIRLOOM_SERVICE}`}/servlet`;
  // U.log('fetchTransById aaa: ' + _baseUrl + '?transid=' + menuItemTarget.code.trim() + '&btsg=' + tabId + '   ');
  const config = {
    method: 'get', //GET
    baseURL: _baseUrl,
    maxContentLength: 1000000,
    timeout: _timeOut,
    headers: {
      'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Authorization: 'Basic ' + U.T_hauth,
    },
    params: { transid: menuItemTarget.code.trim(), btsg: tabId },
    responseType: 'document',
    responseEncoding: 'utf8',
    withCredentials: true,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

export const gettHelpTrans = () => {
  const _baseUrl = `${`${U.URL_ADDRESS}${U.HEIRLOOM_SERVICE}`}/servlet`;
  U.log('fetchTransById: ' + _baseUrl + '?transid=HJEP&btsg=' + U.T_tabId);
  const config = {
    method: 'get', //GET
    baseURL: _baseUrl,
    maxContentLength: 1000000,
    timeout: _timeOut,
    headers: {
      'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Authorization: 'Basic ' + U.T_hauth,
    },
    params: { transid: 'HJEP', btsg: U.T_tabId },
    responseType: 'document',
    responseEncoding: 'utf8',
    withCredentials: true,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

/****************************************************** */
/****************************************************** */
/****************************************************** */
/****************************************************** */
/****************************************************** */
/****************************************************** */
/****************************************************** */
export const postHelpTrans = (hD: HjelpFormData) => {
  const _baseUrl = `${`${U.URL_ADDRESS}${U.HEIRLOOM_SERVICE}`}/servlet`;
  const config = {
    method: 'post',
    baseURL: _baseUrl,
    maxContentLength: 1000000,
    timeout: _timeOut,
    data:
      'DFH_CURSOR=' +
      hD.DFH_CURSOR +
      '&DFH0003=' +
      'ORRE' +
      '&DFH0004=' +
      hD.DFH0004 +
      '&DFH0005=' +
      hD.DFH0005 +
      '&DFH0007=' +
      hD.DFH0007 +
      '&DFH_ENTER=' +
      hD.DFH_ENTER,
    headers: {
      'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Authorization: 'Basic ' + U.T_hauth,
    },
    params: { transid: 'HJEP', btsg: U.T_tabId },
    responseType: 'document',
    responseEncoding: 'utf8',
    withCredentials: true,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

// Gets Help payload from AutoData API
export const getHelpTransVitec = (hD: HjelpFormData) => {
  const _baseUrl = `${`${U.URL_ADDRESS}${U.HEIRLOOM_SERVICE}`}/servlet`;
  const _date = new Date().getTime().toString();

  const config = {
    method: 'post',
    baseURL: _baseUrl,
    maxContentLength: 1000000,
    timeout: _timeOut,
    data: {
      exci: 'yes',
      progId: 'WWWHELP',
      btsg: _date,
      TRANS: hD.DFH0003,
      FELTNAVN: hD.DFH0004,
      INNHOLD: hD.DFH0007,
      TERMID: hD.termid,
      P_BTSG: hD.btsg,
      keepsession: true,
    },
    headers: {
      'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Authorization: 'Basic ' + U.T_hauth,
    },
    params: {
      exci: 'yes',
      progId: 'WWWHELP',
      btsg: _date,
      TRANS: hD.DFH0003,
      FELTNAVN: hD.DFH0004,
      INNHOLD: hD.DFH0007,
      TERMID: hD.termid,
      P_BTSG: hD.btsg,
      keepsession: true,
    },
    // options are: 'arraybuffer', 'document', 'json', 'text', 'stream'
    // browser only: 'blob'
    responseType: 'arraybuffer',
    responseEncoding: 'binary',
    withCredentials: true,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

/****************************************************** */
/****************************************************** */
/****************************************************** */
/****************************************************** */
/****************************************************** */
/****************************************************** */
/****************************************************** */

// SAVE Help payload to AutoData API
export const saveHelpTrans = (hD: HjelpPostFormData) => {
  const config = {
    method: 'post',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/help/updateHelp`,
    maxContentLength: 1000000,
    timeout: 20000,
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
      Accept: 'application/json;charset=utf-8',
    },
    data: hD,
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

/****************************************************** */
/****************************************************** */
/****************************************************** */
/****************************************************** */
/****************************************************** */
/****************************************************** */
/****************************************************** */
/****************************************************** */
/****************************************************** */
/****************************************************** */
/****************************************************** */
/****************************************************** */
/****************************************************** */
/****************************************************** */
// timeout: 240000 previous

export const postTransFormDataAxios = (navFormData: NavFormData, tabId: string) => {
  const config = {
    method: 'post',
    baseURL: `${`${U.URL_ADDRESS}${U.HEIRLOOM_SERVICE}`}/servlet`,
    maxContentLength: 1000000,
    timeout: 720000,
    data: navFormData.formData.replaceAll('%20', '+'),
    headers: {
      'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Authorization: 'Basic ' + U.T_hauth,
    },
    params: { transid: navFormData.transId.trim(), btsg: tabId },
    responseType: 'document',
    responseEncoding: 'utf8',
    withCredentials: true,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

export const getWSDataFromAxios = (navFormData: NavFormData, tabId: string) => {
  const _baseURL = `${`${U.URL_ADDRESS}${U.HEIRLOOM_SERVICE}`}/servlet`;
  U.log('getWSDataFromAxios: ' + _baseURL);
  const config = {
    method: 'get',
    baseURL: _baseURL,
    maxContentLength: 1000000,
    timeout: 20000,
    headers: {
      'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Authorization: 'Basic ' + U.T_hauth,
    },
    params: { transid: navFormData.transId.trim(), scode: navFormData.scode.trim(), btsg: tabId },
    responseType: 'document',
    responseEncoding: 'utf8',
    withCredentials: true,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

//          _  _   _ _____ ___  ___   _ _____ _     ___ ___  ___
//         /_\| | | |_   _/ _ \|   \ /_\_   _/_\   | _ \   \| __|
//        / _ \ |_| | | || (_) | |) / _ \| |/ _ \  |  _/ |) | _|
//       /_/ \_\___/  |_| \___/|___/_/ \_\_/_/ \_\ |_| |___/|_|

export const getPDFDocument = (pdfFormData: PDFFormData) => {
  const config = {
    method: 'post',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/uploadAttachments`,
    maxContentLength: 1000000,
    timeout: 20000,
    data: pdfFormData.formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    responseType: 'blob',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

//          _  _   _ _____ ___  ___   _ _____ _     ___ _   _ ___ ___  ___  ___ _____
//         /_\| | | |_   _/ _ \|   \ /_\_   _/_\   / __| | | | _ \ _ \/ _ \| _ \_   _|
//        / _ \ |_| | | || (_) | |) / _ \| |/ _ \  \__ \ |_| |  _/  _/ (_) |   / | |
//       /_/ \_\___/  |_| \___/|___/_/ \_\_/_/ \_\ |___/\___/|_| |_|  \___/|_|_\ |_|

export const postBugReportToSupport = (bugFormData: BugFormData) => {
  const config = {
    method: 'post',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/bugReportSupport`,
    maxContentLength: 1000000,
    timeout: 20000,
    data: bugFormData.formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    responseType: 'blob',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

export const postErrorToSupport = (email = '', error: any, _formData = '', transId = '') => {
  const errorMSG = new ErrorMSG(
    window.location.toString(),
    email ? email : 'NO EMAIL PROVIDED',
    transId + 'ERROR - ' + U.T_sub + ' | ' + U.T_company,
    error,
    _formData
  );
  const config = {
    method: 'post',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/errorSupport`,
    maxContentLength: 1000000,
    timeout: 20000,
    //params: { from: email ? email : 'NO EMAIL PROVIDED', subject: 'ERROR - ' + U.T_sub + ' | ' + U.T_company, description: error },
    data: errorMSG,
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    responseType: 'text',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

export const postFeedbackToSupport = (supporFormData: SupportFormData) => {
  const config = {
    method: 'post',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/feedbackSupport`,
    maxContentLength: 1000000,
    timeout: 20000,
    data: supporFormData.formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    responseType: 'blob',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

// 
export const fetchNyheterPost = () => {
  const config = {
    method: 'get',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/msg/getNyheterPost`,
    maxContentLength: 1000000,
    timeout: _timeOut,
    headers: {
      'content-type': 'application/json',
      //Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    responseType: 'json',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

// 
export const fetchDriftPost = () => {
  const config = {
    method: 'get',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/msg/getDrifPost`,
    maxContentLength: 1000000,
    timeout: _timeOut,
    headers: {
      'content-type': 'application/json',
      //Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    responseType: 'json',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

//
//    _   _ ___ ___ ___     _   ___  __  __ ___ _  _ ___ ___ _____ ___    _ _____ ___ ___  _  _
//   | | | / __| __| _ \   /_\ |   \|  \/  |_ _| \| |_ _/ __|_   _| _ \  /_\_   _|_ _/ _ \| \| |
//   | |_| \__ \ _||   /  / _ \| |) | |\/| || || .` || |\__ \ | | |   / / _ \| |  | | (_) | .` |
//    \___/|___/___|_|_\ /_/ \_\___/|_|  |_|___|_|\_|___|___/ |_| |_|_\/_/ \_\_| |___\___/|_|\_|
//
//
// 
export const getUserList = () => {
  const config = {
    method: 'get',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/admin/getuserlist`,
    maxContentLength: 1000000,
    timeout: _timeOut,
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    responseType: 'json',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

export const getUserCVSList = (date: Date) => {
  const config = {
    method: 'get',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/admin/getusercvslist`,
    maxContentLength: 1000000,
    timeout: 20000,
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    params: { date: date.getFullYear() + '-' + (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) },
    responseType: 'blob',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
  /**/
};

// 
export const getLDAPUserData = (value: string) => {
  const config = {
    method: 'get',
    baseURL: `${U.URL_ADDRESS}${U.VITEC_SERVICE}/admin/getuserldapdata`,
    maxContentLength: 1000000,
    timeout: _timeOut,
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    params: { username: value },
    responseType: 'json',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

// TODO: Add doc
export const getGroupListFromAD = (/*value: string*/) => {
  const config = {
    method: 'get',
    baseURL: `${U.URL_ADDRESS}${U.VITEC_SERVICE}/admin/getallgroup`,
    maxContentLength: 1000000,
    timeout: _timeOut,
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    //params: { username: value },
    responseType: 'json',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

// TODO: Add doc
export const getAdminFirmListFromDB = (/*value: string*/) => {
  const config = {
    method: 'get',
    baseURL: `${U.URL_ADDRESS}${U.VITEC_SERVICE}/admin/getalladminFirmfromdb`,
    maxContentLength: 1000000,
    timeout: _timeOut,
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    //params: { username: value },
    responseType: 'json',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

// TODO: Add doc
export const getGroupListFromAdmin = (/*value: string*/) => {
  const config = {
    method: 'get',
    baseURL: `${U.URL_ADDRESS}${U.VITEC_SERVICE}/admin/getallgroupfromadmin`,
    maxContentLength: 1000000,
    timeout: _timeOut,
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    //params: { username: value },
    responseType: 'json',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

// TODO: Add doc

export const updateUserData = (payload: LdapUser) => {
  payload.pwdLink = window.location.protocol + '//' + window.location.host + U.HOMEPAGE_CONTEXT + '/passwordupdate/';
  //payload.sendPWDCreationLink = true;
  const config = {
    method: 'post',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/admin/updateuserdata`,
    maxContentLength: 1000000,
    timeout: 20000,
    data: payload,
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    responseType: 'text',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

// Gets
export const registerUser = (payload: LdapUser) => {
  payload.pwdLink = window.location.protocol + '//' + window.location.host + U.HOMEPAGE_CONTEXT + '/passwordupdate/';
  const config = {
    method: 'post',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/admin/registeruser`,
    maxContentLength: 1000000,
    timeout: 20000,
    data: payload,
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    responseType: 'text',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

// Gets 
export const deleteFromUserListSelected = (payload: any) => {
  const config = {
    method: 'post',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/admin/deleteuserlist`,
    maxContentLength: 1000000,
    timeout: 20000,
    data: payload,
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    responseType: 'text',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};





// Gets 
export const logToApiPost = (payload: LogMsg) => {
  const config = {
    method: 'post',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/utils/logtoapi`,
    maxContentLength: 1000000,
    timeout: 20000,
    data: payload,
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    responseType: 'text',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

//
//    _   _ ___ ___ ___     _   ___  __  __ ___ _  _ ___ ___ _____ ___    _ _____ ___ ___  _  _
//
//

// G
export const APIgetCriterialListItem = () => {
  const config = {
    method: 'get',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/tallenes/getcriterialitemlist`,
    maxContentLength: 1000000,
    timeout: _timeOut,
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    responseType: 'json',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

// 

export const APIpostUpdateCriteriaItem = (payload: TaleCriteriaItem) => {
  const config = {
    method: 'post',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/tallenes/updatecriterialitem`,
    maxContentLength: 1000000,
    timeout: 20000,
    data: payload,
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    responseType: 'text',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};


// Gets 
export const APIregisterCriteria = (payload: TaleCriteriaItem) => {


  const config = {
    method: 'post',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/tallenes/registercriteria`,
    maxContentLength: 1000000,
    timeout: 20000,
    data: payload,
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    responseType: 'text',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

// Gets 
export const APIdeleteCriteriaFromList = (payload: number[]) => {
  const config = {
    method: 'post',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/tallenes/deletecriteriafromList`,
    maxContentLength: 1000000,
    timeout: 20000,
    data: payload,
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    responseType: 'text',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

export const APIgetCriterialExcelList = () => {
  const config = {
    method: 'get',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/tallenes/getcriterialExcelllist`,
    maxContentLength: 1000000,
    timeout: 20000,
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    responseType: 'blob',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
  /**/
};

/**************************************************************************************/

// TODO: Add doc
export const APIgetFavorittListe = () => {
  const config = {
    method: 'get',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/tallenes/getfavorittliste`,
    maxContentLength: 1000000,
    timeout: _timeOut,
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    responseType: 'json',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

// TODO: Add doc
export const APIpostSaveFavorittListe = (payload: TaleFavorittListe) => {
  const config = {
    method: 'post',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/tallenes/savefavorittliste`,
    maxContentLength: 1000000,
    timeout: 20000,
    data: payload,
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    responseType: 'text',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

// TODO: Add doc
export const APIpostDeleteFavorittListe = (payload: number) => {
  const config = {
    method: 'post',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/tallenes/deletefavorittliste`,
    maxContentLength: 1000000,
    timeout: 20000,
    data: payload,
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    responseType: 'text',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

// Gets Help REPORT
export const APIpostSalesReport = (payload: TaleSearchParams) => {
  const config = {
    method: 'post',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/tallenes/gettallenestalereport`,
    maxContentLength: 1000000,
    timeout: _timeOut,
    data: payload,
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    responseType: 'json',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;

  // Changes the config based upon the mediaType 
  switch (true) {
    case payload.mediaType === 'pdf':
      //config.method = 'get';
      config.baseURL = `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/tallenes/gettallenestalereportpdf`;
      config.responseType = 'blob';
      break;

    case payload.mediaType === 'excel':
      //config.method = 'get';
      config.baseURL = `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/tallenes/gettallenestalereportexcel`;
      config.responseType = 'blob';
      break;

    case payload.mediaType === 'data':
      //config.method = 'get';
      config.baseURL = `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/tallenes/gettallenestalereportdataexcel`;
      config.responseType = 'blob';
      break;
  }
  return axios.request(config);
};



export const postUploadExcelDocumentWithSearchCriteria = (excelFormData: ExcelFormData) => {
  const config = {
    method: 'post',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/tallenes/uploadExcel`,
    maxContentLength: 1000000,
    timeout: 20000,
    data: excelFormData.formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    responseType: 'text',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};