import { Theme } from '@mui/material';
import { Component } from 'react';
import { Responsive } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import Utils from '../../felles/Utils';
import C from '../../felles/UtilsCSS';
import UISize from '../../models/UISize';
import * as actions from '../../store/actions/index';
import { AppState } from '../../store/rootReducer';
import LPContentCard from '../../components/LPContentCard';


// REDUX
interface sentFromParentComponent {
  size: any, setCompactType?: (val: any) => void;
}

//
interface StateProps {
  currentBreakpoint: string;
  //compactType: any;
  mounted: boolean;
  layouts: any;
  layout: any;
  classes?: any;
  drawerWidth: number;
  //gridWidth: number;
  //cols: any;
  screenSize: UISize;
  isDebugEnabled: boolean;
  button: boolean;
  charCode: string;
  user: any;// = useSelector((state) => (state as AppState).auth.userJWT);
}

//
interface DispatchProps {
  setLayouts: (layout: any, layouts: any) => void;
  setLayout: (layout) => void;
  setMoutedState: (val: boolean) => void;
  setDrawerState: (val: boolean) => void;
  setBreakpointState: (val: any) => void;
  setSandwichButtonStateClicked: (val: any) => void;
}

//
type Props = sentFromParentComponent & StateProps & DispatchProps;

//
const mapStateToProps = (state: AppState): StateProps => {
  return {
    currentBreakpoint: state.ui.currentBreakpoint,
    //compactType: state.ui.compactType,
    mounted: state.ui.mounted,
    layout: state.ui.layout,
    layouts: state.ui.layouts,
    drawerWidth: state.ui.drawerWidth,
    //gridWidth: state.ui.gridWidth,
    //cols: state.ui.cols,
    screenSize: state.ui.screenSize,
    isDebugEnabled: state.utils.isDebugEnabled,
    button: state.ui.isSandwichButtonTurnedOn,
    charCode: state.utils.charCode,
    user: state.auth.userJWT,
  }
}
//
const mapDispatchToProps = (dispatch): DispatchProps => {
  return {
    setLayouts: (layout, layouts) => dispatch(actions.setGridLayouts(layout, layouts)),
    setLayout: (layout) => dispatch(actions.setGridLayout(layout)),
    setMoutedState: (val) => dispatch(actions.setMouted(val)),
    setDrawerState: (val) => dispatch(actions.setDrawerState(val)),
    setBreakpointState: (val) => dispatch(actions.setCurrentBreakPoint(val)),
    setSandwichButtonStateClicked: (val) => dispatch(actions.setSandwichButtonStateClicked(val)),
  }
}

/*
*
*
*
*
*/
class LPContentDefault extends Component<Props> {
  //Init
  componentDidMount() {
    this.props.setLayouts(this.generateLayout(this.props.currentBreakpoint), getLayouts(this.props.button));
    if (this.props.currentBreakpoint === 'xs' || this.props.currentBreakpoint === 'xxs') this.props.setDrawerState(false);
    this.props.setMoutedState(true);
  }
  //
  generateLayout = (val) => {
    const layouts = getLayouts(false);
    return layouts[val];
  };
  // Check this - useless
  hBreak = '';
  onBreakpointChange = (breakpoint) => {
    this.hBreak = breakpoint;
  };

  //
  onLayoutChange = () => {
    return this.props.setLayouts(this.generateLayout(this.props.currentBreakpoint), getLayouts(this.props.button));
  };
  //
  generateDOM() {
    const { classes } = this.props;
    if (this.props.layouts && this.props.layout) {
      return this.props.layout.map((l, i) => {
        return (
          <div className={classes.gridItem} key={i}>
            <LPContentCard isDebugEnabled={this.props.isDebugEnabled} cardType={i} title={l.ti} link={l.li} />
          </div>
        );
      });
    } else return [];
  }
  //
  getRowHeight = (bT: boolean) => {
    let rowHeight = 125;
    const w = this.props.screenSize.width;
    switch (true) {
      /*XXS*/ case w <= 501:
        rowHeight = 145;
        break;
      /*XS*/ case w >= 501 && w < 1026:
        bT ? (rowHeight = 170) : (rowHeight = 157);
        break;
      /*SM*/ case w >= 1026 && w < 1341:
        bT ? (rowHeight = 170) : (rowHeight = 135);
        break;
      /*MD*/ case w >= 1341 && w < 1580:
        rowHeight = 145;
        break;
      /*LG*/ case w >= 1580 && w < 2220:
        rowHeight = 145;
        break;
      /*XL*/ case w >= 2220 && w < 3500:
        rowHeight = 145;
        break;
      /*XXL*/ case w > 3500:
        rowHeight = 145;
        break;
      // TOADD Specials case for tablets
    }
    return rowHeight;
  };


  //const user: any = useSelector((state) => (state as AppState).auth.userJWT);
  //
  render() {
    //
    const { classes } = this.props;
    //
    return (

      <>
        {<div className={classes.velkA}>{/*this.props.user ? 'Velkommen, ' + this.props.user?.firstName: ''}{this.props.user ? ' @ ' + this.props.user?.company: ''*/}</div>}
        {Utils.IS_ENV_DEV(this.props.isDebugEnabled) ? (
          <div className={classes.debug}>
            {'Button: ' + this.props.button + ' | '}
            <strong>{'Breakpoint: ' + this.props.currentBreakpoint}</strong>  |
            {this.props.screenSize ? <>{' charEnc: ' + this.props.charCode + ' width: ' + this.props.screenSize.width + ' height: ' + this.props.screenSize.height + ' '}</> : null}
            {this.props.size ? <>{' ||| innerDiv width: ' + this.props.size.width}</> : null}
          </div>
        ) : null}

        <Responsive
          className={classes.root}
          layouts={this.props.layouts}
          width={this.props.size.width  || 100}
          onBreakpointChange={this.onBreakpointChange}
          onLayoutChange={this.onLayoutChange}
          measureBeforeMount={false}
          useCSSTransforms={this.props.mounted}
          isResizable={false}
          isDraggable={false}
          rowHeight={this.getRowHeight(this.props.button)}
          breakpoints={{ xxl: 3500, xl: 2220, lg: 1580, md: 1341, sm: 1026, xs: 500, xxs: 0 }}
          cols={{ xxl: 24, xl: 24, lg: 24, md: 24, sm: 24, xs: 24, xxs: 24 }}
          margin={[20, 20]} //
        >

          {this.generateDOM()}

        </Responsive>
      </>
    );
  }
}

const LPContentDefaultStyled = withStyles(
  LPContentDefault,
  (t: Theme) => ({
    root: {
      display: ' inline-block',
      marginTop: 30, padding: 0,
    },
    debug: {
      position: 'absolute', display: ' inline-block', verticalAlign: ' top', fontSize: '1em', top: -70, right: 20, opacity: .8,
      [C.r(t, C.XXS, C.XXX)]: { top: -22 },
      [C.r(t, C.XS, C.XSX)]: { top: -22 },
      [C.r(t, C.SM, C.SMX)]: { top: -22 },
      [C.r(t, C.MD, C.MDX)]: { top: -22 },
      [C.r(t, C.LG, C.LGX)]: { top: -10 },
      [C.r(t, C.XL, C.XLX)]: { top: -10 },
      [C.r(t, C.XXL, C.YLX)]: { top: -10 },
      [C.IPAD_10]: { top: -30 },
      [C.SAMSUNG]: { top: -30 },
    },

    velkA: {
      textAlign: 'right', paddingRight: 20,
      [C.r(t, C.XXS, C.XXX)]: { marginBottom: -15, paddingTop: 20, },
      [C.r(t, C.XS, C.XSX)]: { marginBottom: -15, paddingTop: 20, },
      [C.r(t, C.SM, C.SMX)]: { marginBottom: -15, paddingTop: 20, },
      [C.r(t, C.MD, C.MDX)]: { marginBottom: -15, paddingTop: 20, },
      [C.r(t, C.LG, C.LGX)]: { marginBottom: -15, paddingTop: 55, },
      [C.r(t, C.XL, C.XLX)]: { marginBottom: -15, paddingTop: 50, },
      [C.r(t, C.XXL, C.YLX)]: { marginBottom: -15, paddingTop: 50, },
      [C.IPAD_10]: { marginBottom: -20, paddingTop: 20,  paddingRight: 20 },
      [C.SAMSUNG]: { marginBottom: -20, paddingTop: 20,  paddingRight: 20 },
    }
  })
);

export default connect<StateProps, DispatchProps, sentFromParentComponent, AppState>(mapStateToProps, mapDispatchToProps)(LPContentDefaultStyled);
//
function getLayouts(bT) {
  const DRIFTSTATUS = 'Driftstatus';
  const AUTODATA = 'Autodata Web';
  const NYHETER = 'Nyheter';
  const SUPPORT = 'Kontakt Oss';
  const LENKER = 'Nyttige Lenker';
  const LID = 'https://www.vitecsoftware.com/no/produktomrade/auto/driftsmeldinger/';
  const LIN = 'https://www.vitecsoftware.com/no/produktomrade/auto/nyheter/';

  const layouts = {
    xxl: [
      // over 2500
      { w: 9, h: 7, x: 0, y: 0, i: '0', ti: DRIFTSTATUS, li: LID },
      { w: 6, h: 2.45, x: 9, y: 0, i: '1', ti: AUTODATA, li: '' },
      { w: 9, h: 7, x: 18, y: 0, i: '2', ti: NYHETER, li: LIN },
      { w: 6, h: 2, x: 9, y: 2, i: '3', ti: SUPPORT, li: '' },
      { w: 6, h: 2.3, x: 9, y: 4, i: '4', ti: LENKER, li: '' },
    ],
    xl: [
      // over 2500
      { w: 9, h: 7, x: 0, y: 0, i: '0', ti: DRIFTSTATUS, li: LID },
      { w: 6, h: 2.45, x: 9, y: 0, i: '1', ti: AUTODATA, li: '' },
      { w: 9, h: 7, x: 18, y: 0, i: '2', ti: NYHETER, li: LIN },
      { w: 6, h: 2.3, x: 9, y: 2, i: '3', ti: SUPPORT, li: '' },
      { w: 6, h: 2.3, x: 9, y: 4, i: '4', ti: LENKER, li: '' },
    ],
    lg: [
      // 2561
      { w: 9, h: 6.1, x: 0, y: 0, i: '0', ti: DRIFTSTATUS, li: LID },
      { w: 6, h: 2.3, x: 9, y: 0, i: '1', ti: AUTODATA, li: '' },
      { w: 9, h: 6.1, x: 18, y: 0, i: '2', ti: NYHETER, li: LIN },
      { w: 6, h: 2, x: 9, y: 2, i: '3', ti: SUPPORT, li: '' },
      { w: 6, h: 1.8, x: 9, y: 4, i: '4', ti: LENKER, li: '' },
    ],
    md: [
      // 1920
      { w: 9, h: 5, x: 0, y: 0, i: '0', ti: DRIFTSTATUS, li: LID },
      { w: 6, h: 1.85, x: 9, y: 0, i: '1', ti: AUTODATA, li: '' },
      { w: 9, h: 5, x: 18, y: 0, i: '2', ti: NYHETER, li: LIN },
      { w: 6, h: 1.45, x: 9, y: 2, i: '3', ti: SUPPORT, li: '' },
      { w: 6, h: 1.7, x: 9, y: 4, i: '4', ti: LENKER, li: '' },
    ],
    sm: [
      // 1366 IPAD
      { w: 16, h: Utils.isTabletIpad ? 2 : Utils.isTabletAndroid ? 1.69 : 2.30, x: 0, y: 0, i: '0', ti: DRIFTSTATUS, li: LID },
      { w: 8, h: Utils.isTabletIpad ? 1.33 : Utils.isTabletAndroid ? 1.3 : 1.6, x: 16, y: 0, i: '1', ti: AUTODATA, li: '' },
      { w: 16, h: Utils.isTabletIpad ? 2 : Utils.isTabletAndroid ? 1.8 : 2.30, x: 0, y: 1, i: '2', ti: NYHETER, li: LIN },
      { w: 8, h: Utils.isTabletIpad ? 1.33 : Utils.isTabletAndroid ? 1.10 : 1.43, x: 16, y: 1, i: '3', ti: SUPPORT, li: '' },
      { w: 8, h: Utils.isTabletIpad ? 1.34 : Utils.isTabletAndroid ? 1.09 : 1.57, x: 16, y: 2, i: '4', ti: LENKER, li: '' },
    ],
    xs: [
      { w: bT ? 14 : 16, h: 2.45, x: 0, y: 0, i: '0', ti: DRIFTSTATUS, li: LID },
      { w: bT ? 10 : 8, h: 1.55, x: 16, y: 0, i: '1', ti: AUTODATA, li: '' },
      { w: bT ? 14 : 16, h: 1.8, x: 0, y: 1, i: '2', ti: NYHETER, li: LIN },
      { w: bT ? 10 : 8, h: 1.11, x: 16, y: 1, i: '3', ti: SUPPORT, li: '' },
      { w: bT ? 10 : 8, h: 1.46, x: 16, y: 2, i: '4', ti: LENKER, li: '' },
    ],
    xxs: [
      { w: 24, h: 1.2, x: 0, y: 0, i: '0', ti: AUTODATA, li: '' },
      { w: 24, h: 1.28, x: 0, y: 1, i: '1', ti: DRIFTSTATUS, li: LID },
      { w: 24, h: 1.28, x: 0, y: 2, i: '2', ti: NYHETER, li: LIN },
      { w: 24, h: 1.2, x: 0, y: 3, i: '3', ti: SUPPORT, li: '' },
      { w: 24, h: 1.2, x: 0, y: 4, i: '4', ti: LENKER, li: '' },
    ],
  };
  return layouts;
}
