import axios, { AxiosRequestConfig } from 'axios';

import UserCred from '../models/UserCred';
import {TradingPartnersResponse} from "../models/NO01Orku";
import U from './Utils';
import CSharpUtils from './CSharpUtils';
import Utils from './Utils';


export const fetchOrkuData = (companyId: number) => {
  const config: AxiosRequestConfig = {
    method: "get",
    url: `${`${Utils.URL_ADDRESS}${Utils.DOTNET_SERVICE}`}/company/tradingpartners/incoming`,
     params: { companyId },
    headers: {
        accept: 'application/json',
      Authorization: `Bearer ${CSharpUtils.Get_Token}`, 
    },
    responseType: "json",
    responseEncoding: "utf8",
    timeout: 10000,
  };

  return axios.request<TradingPartnersResponse>(config)
    .then(response => {
    //  console.log("API Response: ", response.data); 
      return response.data;
    })
    .catch(error => {
      console.error("API Error: ", error); 
      throw error;
    });
};
