import React from 'react';
import { Grid, Typography, TextField, InputAdornment, IconButton } from '@mui/material';
import { FilterAlt } from '@mui/icons-material';

interface FilterTextFieldProps {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  showFilter: boolean;
  setShowFilter: (show: boolean) => void;
}

const FilterTextField: React.FC<FilterTextFieldProps> = ({
  label,
  value,
  onChange,
  showFilter,
  setShowFilter,
}) => (
  <Grid container alignItems="center">
    <Grid item>
      
        {label}
     
    </Grid>
    <Grid item>
      {showFilter ? (
        <TextField
          size="small"
          variant="outlined"
          value={value}
          onChange={onChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowFilter(false)}>
                  <FilterAlt />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <IconButton onClick={() => setShowFilter(true)}>
          <FilterAlt   />
        </IconButton>
      )}
    </Grid>
  </Grid>
);

export default FilterTextField;
