
import { alpha, Autocomplete, Checkbox, FormControlLabel, Grid, lighten, Paper, TextField, Theme, Toolbar } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import ButtonVitec from '../../../../components/ButtonVitec';
import E from '../../../../felles/Enums';
import U from '../../../../felles/Utils';
import FirmNavnId from '../../../../models/FirmNavnId';
import TaleFavorittListe from '../../../../models/TaleFavorittListe';
import TaleSearchParams from '../../../../models/TaleSearchParams';
import * as actions from '../../../../store/actions/index';
import { AppState } from '../../../../store/rootReducer';


import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import "dayjs/locale/nb";

const useStyles = makeStyles()((theme: Theme) => ({
    root: { width: '100%', display: 'inline-block', },
    '& label': { height: 25, textAlign: 'center', color: '#945f67', },
    '& label.Mui-focused': { top: 12, animationIterationCount: 1, transform: 'translate(0, -10px) scale(1)', fontSize: '.75em', color: '#945f67', },
    '& .MuiInputLabel-formControl': { top: 11, left: 6, position: 'absolute', transform: 'translate(0, 0) scale(1)', color: '#945f67', },
    '& .MuiInput-underline:after': { height: 45, width: 0 },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": { border: "none", },
    /*  */
    highlight: { color: theme.palette.secondary.main, backgroundColor: lighten(theme.palette.secondary.light, 1) },
    spacer: { height: "25px" },
    actions: { color: theme.palette.text.secondary },
    buttonA: { width: 150, marginRight: theme.spacing(1) },
    title: { marginTop: '15px' }, //width: '100%', display: 'inline' 
    // I used the rgba color for the standard "secondary" color
    customTooltip: { backgroundColor: '#fff', fontSize: '1em', color: 'black', border: '1px solid #fff', margin: 0, marginTop: 10, marginLeft: -5, },
    customArrow: { color: ' #fff', },
    mySearchForm: { horizontalAlign: 'center', },
    mInt: { width: '100%', padding: '10px', marginTop: '2px', border: '1px solid #ccc' },
    myLabel: { fontSize: '1em' },
    formError: { fontSize: '1.4em', marginTop: '30px', color: 'red', align: 'center' },
    myButtons: { display: 'flex', alignItems: 'center', justifyContent: 'space-between', },
    dialogCustomizedWidth: { minWidth: '400px', maxWidth: '400px', minHeight: '150px' },
    myDialogTitle: { textAlign: 'center', fontSize: '1.4em', width: '100%', },
    rowField: { display: 'flex', verticalAlign: 'middle', margin: 10/*, border: '1px solid'*/ },
    box: { display: 'flex', alignItems: 'center', justifyContent: 'center', },
    sunKunde: { backgroundColor: '#FFCCCB', padding: 5 },
    tField: {
        display: 'flex',
        backgroundColor: alpha('#fff', 0.4),
        // border: '2px solid orange',
        '& label': { fontSize: 20, height: 45, lineHeight: .8, backgroundColor: '1px solid #4f4d4c', },
        height: 45,
        //'& label.Mui-focused': { top: 12, animationIterationCount: 1, transform: 'translate(0, -10px) scale(1)', fontSize: '.75em', color: '#d30535', },
        //'& .MuiInputLabel-formControl': { top: 11, left: 6, position: 'absolute', transform: 'translate(0, 0) scale(1)', color: '#d30535', },
        '& .MuiInput-underline:after': { height: 45, width: 0, fontSize: 20 },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            fontSize: 20,
            border: '1px solid #c4c4c4',
        },
        // (Note: space or no space after `&` matters. See SASS "parent selector".)
        '& .MuiOutlinedInput-root': {
            color: 'black',
            height: 47,
            fontSize: 20,

            // - The Input-root, inside the TextField-root
            '& fieldset': {
                border: '2px solid white',
                /* - The <fieldset> inside the Input-root */
            },
            '&:hover fieldset': {},
            '&.Mui-focused ': {
                backgroundColor: alpha('#fff', 0.8),

            },
        },
    },
    ba: { border: 'none', marginLeft: 20 },
    bb: { color: '#2E2D2C' },
    grd: { border: 'none', marginRight: 10 },

}));

//
const TallenesTaleTableToolbar = (/*props*/) => {
    const dispatch = useDispatch();
    const { classes } = useStyles();
    const user: any = useSelector((state) => (state as AppState).auth.userJWT);
    //const error: any = useSelector((state) => (state as AppState).tallenesTale.error);
    const firmListFromAdmin: FirmNavnId[] = useSelector((state) => (state as AppState).userAdmin.firmListFromAdmin);
    const isFetchingAdminFirmData: boolean = useSelector((state) => (state as AppState).userAdmin.isFetchingAdminFirmData);
    const taleFavorittListeList: TaleFavorittListe[] = useSelector((state) => (state as AppState).tallenesTale.taleFavorittListe);
    const isFetchingFavorittListe: boolean = useSelector((state) => (state as AppState).tallenesTale.isFetchingFavorittListe);
    const isFetchingSalesReport: boolean = useSelector((state) => (state as AppState).tallenesTale.isFetchingSalesReport);
    const selectedSubKunde: FirmNavnId = useSelector((state) => (state as AppState).tallenesTale.selectedSubKunde);
    const selectedKunde: FirmNavnId = useSelector((state) => (state as AppState).tallenesTale.selectedKunde);
    const [buttonSaveDisabled, setButtonSaveDisabled] = useState(false);
    const [buttonDeleteDisabled, setButtonDeleteDisabled] = useState(false);
    const [buttonReportDisabled, setButtonReportDisabled] = useState(false);
    const date = useSelector(state => (state as AppState).accounting.date);
    const userJWT = useSelector((state) => (state as AppState).auth.userJWT);

    const [buttonDataDisabled, setButtonDataDisabled] = useState(false);

    //
    const methods = useForm({
        defaultValues: {
            fList: null,
            bList: null/*, maned: null*/,
            startDate: dayjs(date).set('month', 0).startOf('month'),
            endDate: dayjs(date).endOf('month'),
            visning: null || '',
            brutto: false,
            listId: 0,
            betegnelse: '',
            merknader: ''
        }
    });

    const favListObj: any = methods.watch("fList");
    const betegnelseObj: any = methods.watch("betegnelse");
    const startDateObj: any = methods.watch("startDate");
    const endDateObj: any = methods.watch("endDate");
    const visningObj: any = methods.watch("visning");
    const bListObj: any = methods.watch("bList");




    useEffect(() => {
        if (!isFetchingFavorittListe/* && taleFavorittListeList?.length === 0*/)
            dispatch(actions.favorittListeGet());
        //
        if (!isFetchingAdminFirmData/* && firmListFromAdmin.length === 0*/)
            dispatch(actions.firmListFromDBGet());
        //
        if (favListObj) {
            dispatch(actions.setFullResetSalesReported());
            dispatch(actions.setSelectedKunde(new FirmNavnId(favListObj?.butikkId, favListObj?.butikk)));
            dispatch(actions.setSelectedSubKunde(new FirmNavnId(favListObj?.subButikkId, favListObj?.subButikk)));

            const butikkObj: any = firmListFromAdmin?.filter((item) => item.firmName.trim() === favListObj.butikk.trim());
            if (butikkObj) {
                methods.setValue(`bList`, butikkObj[0], { shouldDirty: true });
            }
            //
            methods.setValue(`startDate`, favListObj.startDate, { shouldDirty: true });
            methods.setValue(`endDate`, favListObj.endDate, { shouldDirty: true });
            methods.setValue(`visning`, favListObj.visning, { shouldDirty: true });
            methods.setValue(`brutto`, favListObj.brutto, { shouldDirty: true });
            methods.setValue(`listId`, favListObj.id, { shouldDirty: true });
            methods.setValue(`betegnelse`, favListObj.betegnelse, { shouldDirty: true });
            methods.setValue(`merknader`, favListObj.merknader, { shouldDirty: true });
            dispatch(actions.setfavorittListeVisningType(favListObj.visning))
        } else {
            methods.setValue(`bList`, null, { shouldDirty: true });
            methods.setValue(`listId`, 0, { shouldDirty: true });
            methods.setValue(`startDate`, dayjs(date).set('month', 0).startOf('month'), { shouldDirty: true });
            methods.setValue(`endDate`, dayjs(date).startOf('month'), { shouldDirty: true });
            methods.setValue(`visning`, null || '', { shouldDirty: true });
            methods.setValue(`brutto`, false, { shouldDirty: true });
            methods.setValue(`betegnelse`, '', { shouldDirty: true });
            methods.setValue(`merknader`, '', { shouldDirty: true });
            dispatch(actions.setSelectedKunde(new FirmNavnId(0, '')));
            dispatch(actions.setSelectedSubKunde(new FirmNavnId(0, '')));
            dispatch(actions.setfavorittListeVisningType(undefined));
        }
    }, [favListObj]);
    //
    useEffect(() => {
        //
        if (favListObj === null) {
            setButtonSaveDisabled(true);
            setButtonDeleteDisabled(true);
        } else {
            setButtonSaveDisabled(false);
            setButtonDeleteDisabled(false);
        }
        //
        if (favListObj?.visning !== betegnelseObj) {
            setButtonDeleteDisabled(true);
        }
        //
        if (bListObj === null || startDateObj === null || endDateObj === null || visningObj === null || visningObj === '') {
            setButtonReportDisabled(true);
            setButtonDataDisabled(true);
        } else {
            setButtonReportDisabled(false);
            setButtonDataDisabled(true);
        }
        //
        if (favListObj === null && betegnelseObj !== '' && bListObj !== null && startDateObj !== null && endDateObj !== null && visningObj !== null) {
            setButtonSaveDisabled(false);
        } else if (favListObj !== null) {
            setButtonDeleteDisabled(false);
        }

        if (bListObj !== null && startDateObj !== null && endDateObj !== null && visningObj === '' || visningObj === null) {

            setButtonDataDisabled(false);

        } else if (bListObj !== null && startDateObj !== null && endDateObj !== null && visningObj !== '') {
            setButtonDataDisabled(true);
        }


        // Enabled Rådata button to enabled rådata report on datter companies
        if (visningObj === 'Kunde' && selectedSubKunde.firmID !== 0) {
            setButtonDataDisabled(false);
        }

        if (userJWT.children && firmListFromAdmin.length === 0) {
            firmListFromAdmin.push(new FirmNavnId(userJWT.companyNr, userJWT.company, 0));
            methods.setValue(`bList`, (firmListFromAdmin[0] as any), { shouldDirty: true });
        }

        const loc = <>
            Salgsstatistikker {'>'} Tallenes Tale for {user?.company?.toString()} {selectedKunde?.firmID !== 0 ? <>, {selectedKunde?.firmName}</> : null}
            {selectedSubKunde?.firmID !== 0 ? <>, og {selectedSubKunde?.firmName}</> : null}</>
        dispatch(actions.setLocationPath(U.setLocationPath(loc)));
    }, [bListObj, startDateObj, visningObj, favListObj, betegnelseObj, firmListFromAdmin, selectedSubKunde]);

    //
    const onSubmitSaveFavorite = (o: any) => {
        dispatch(
            actions.favorittListeUpdated(
                new TaleFavorittListe(
                    o.betegnelse !== o.fList?.betegnelse ? 0 : o.fList.id, '', o.bList.firmID || '0',
                    o.bList.firmName, o.startDate, o.endDate, o.visning, o.brutto, o.betegnelse, o.merknader, selectedSubKunde?.firmID, selectedSubKunde?.firmName)
            )
        );
    };

    //
    const onSubmitDeleteFavorite = (o: any = undefined) => {
        dispatch(actions.favorittListeListSelectedDeletion(o.listId));
    };

    const checkIfItAlreadyExist = (val: string) => {
        if (taleFavorittListeList?.length > 0) {
            const favListObjExists: any = taleFavorittListeList?.filter((item) => item.betegnelse.trim() === val);
            if (favListObjExists.length > 0) {
                methods.setValue(`fList`, favListObjExists[0], { shouldDirty: true });
            } else {
                methods.setValue(`bList`, null, { shouldDirty: true });
                methods.setValue(`listId`, 0, { shouldDirty: true });
                methods.setValue(`visning`, null || '', { shouldDirty: true });
                methods.setValue(`brutto`, false, { shouldDirty: true });
            }
        }
    };

    const onSubmitGetSalesReport = (o: any = undefined, mediaType = 'web') => {
        //
        const displayDates =
            dayjs(o.startDate).format('YYYY-MM-DD').substring(0, 7) + ' - ' + dayjs(o.endDate).endOf('month').format('YYYY-MM-DD').substring(0, 7)
            + ' ' +
            dayjs(o.startDate).subtract(1, 'year').format('YYYY-MM-DD').substring(0, 7) + ' - ' + dayjs(o.endDate).subtract(1, 'year').endOf('month').format('YYYY-MM-DD').substring(0, 7);
        //
        const currentDate = dayjs(date).format('YYYYMM');
        const startDate = dayjs(o.startDate).format('YYYYMM');
        const endDate = dayjs(o.endDate).format('YYYYMM');
        // Default
        const taleSearchParams = new TaleSearchParams(U.T_parentId, o.bList.firmID || '0'
            , o.bList.firmName
            , '', '', '', ''
            , mediaType === 'data' ? 'data' : o.visning, o.brutto, mediaType, displayDates, selectedSubKunde?.firmID, selectedSubKunde?.firmName
            , '', '', '', '',)


        // Only get data with faks__fakt_omg - range is tomongh previous the current date.
        /**/
        if (currentDate === startDate) {
            taleSearchParams.datoAFO = dayjs(o.startDate).format('YYYYMM');
            taleSearchParams.datoBFO = dayjs(o.endDate).add(1, 'month').format('YYYYMM');
            taleSearchParams.datoCFO = dayjs(o.startDate).subtract(1, 'year').format('YYYYMM');
            taleSearchParams.datoDFO = dayjs(o.endDate).add(1, 'month').subtract(1, 'year').format('YYYYMM');
        }

        else {
            if (currentDate !== endDate) {
                taleSearchParams.datoA = dayjs(o.startDate).format('YYYYMM');
                taleSearchParams.datoB = dayjs(o.endDate).add(1, 'month').format('YYYYMM');
                taleSearchParams.datoC = dayjs(o.startDate).subtract(1, 'year').format('YYYYMM');
                taleSearchParams.datoD = dayjs(o.endDate).add(1, 'month').subtract(1, 'year').format('YYYYMM');
            }
            else {
                //
                taleSearchParams.datoA = dayjs(o.startDate).format('YYYYMM');
                taleSearchParams.datoB = dayjs(o.endDate).format('YYYYMM');
                taleSearchParams.datoC = dayjs(o.startDate).subtract(1, 'year').format('YYYYMM');
                taleSearchParams.datoD = dayjs(o.endDate).subtract(1, 'year').format('YYYYMM');
                //
                taleSearchParams.datoAFO = dayjs(o.endDate).format('YYYYMM');
                taleSearchParams.datoBFO = dayjs(o.endDate).add(1, 'month').format('YYYYMM');
                taleSearchParams.datoCFO = dayjs(o.endDate).subtract(1, 'year').format('YYYYMM');
                taleSearchParams.datoDFO = dayjs(o.endDate).add(1, 'month').subtract(1, 'year').format('YYYYMM');
            }
        }
        /**/
        switch (true) {
            case o.visning === 'Kunde':
                dispatch(actions.setTaleSearchKundeParams(taleSearchParams));
                break;
            case o.visning === 'Varegruppe':
                dispatch(actions.setTaleSearchVGruppeParams(taleSearchParams));
                break;
            case o.visning === 'Hovedgruppe':
                dispatch(actions.setTaleSearchHGruppeParams(taleSearchParams));
                break;
            case o.visning === 'Gruppe':
                dispatch(actions.setTaleSearchGruppeParams(taleSearchParams));
                break;
            case o.visning === 'Summeringsgruppe':
                dispatch(actions.setTaleSearchSGruppeParams(taleSearchParams));
                break;
        }
        //
        if (!isFetchingSalesReport) {
            dispatch(actions.tallenesGetSalgsstatiskk(taleSearchParams));
        }
    };

    //Clean up - Wipes out all reports
    const resetAllSalesReport = (item: any) => {
        dispatch(actions.setSelectedKunde(new FirmNavnId(item?.firmID, item?.firmName)));
        dispatch(actions.setFullResetSalesReported());
        dispatch(actions.setSelectedSubKunde(new FirmNavnId(0, '')));
    }

    //
    return (

        <FormProvider {...methods}>
            <form>
                <Grid container justifyContent='left' className={classes.ba} >
                    <Grid container className={classes.bb} direction="row"
                        justifyContent="left" alignItems="center">
                        <Grid className={classes.grd}>
                            <Controller control={methods.control} name="fList" render={({ field: { onChange } }) => (
                                <Autocomplete sx={{ width: '480px', }}
                                    onChange={(event, item) => { onChange(item); }}
                                    onKeyUp={(e) => { methods.setValue(`betegnelse`, (e.target as any).value) }}
                                    options={taleFavorittListeList ? taleFavorittListeList : []}
                                    getOptionLabel={(item: any) => (item.betegnelse ? item.betegnelse : "")}
                                    renderInput={(params) => (
                                        <TextField className={classes.tField}
                                            {...params}
                                            label="Vennligst velg en favorittliste eller opprett en ny" margin="normal" variant="outlined" />
                                    )}
                                />)}
                            />
                        </Grid>
                        <Grid className={classes.grd}>
                            <Controller control={methods.control} name={`bList`} render={({ field: { onChange, value } }) => (
                                <Autocomplete sx={{ width: '400px', marginLeft: '10px' }} id="butikk-select-demo"
                                    options={firmListFromAdmin ? firmListFromAdmin.sort((a: any, b: any) => (a.firmName.localeCompare(b.firmName))) : []}
                                    onChange={(event, item) => { onChange(item); resetAllSalesReport(item) }}
                                    value={value} getOptionLabel={(option) => option.firmID + ' ' + option.firmName}
                                    isOptionEqualToValue={(option, value: any) => value === undefined || option.firmName === value.butikk}
                                    renderInput={(params) => (
                                        <TextField className={classes.tField} {...params} label={"Butikk Liste"} inputProps={{ ...params.inputProps }} margin="normal" variant="outlined" />
                                    )}
                                />)}
                            />
                        </Grid>
                        <Grid className={classes.grd}>
                            <FormControlLabel sx={{ width: '140px', paddingLeft: '20px' }} label="Vis brutto" control={<Controller control={methods.control}
                                name="brutto" render={({ field: { onChange, value } }) => (
                                    <Checkbox onChange={(event, item) => { onChange(item); }} name={'brutto'} checked={value} />)}
                            />} />
                            <input className={classes.mInt} {...methods.register('listId')} style={{ width: '20%', }} hidden />
                        </Grid>
                        <Grid item xs="auto" />
                    </Grid>
                </Grid>


                {/***************************************************************************************************************/}
                {/***************************************************************************************************************/}
                <Grid container height={'10px'} />
                <Grid container justifyContent='left' className={classes.ba} >
                    <Grid container  className={classes.bb} direction="row"
                        justifyContent="left" alignItems="center">
                        <Grid className={classes.grd}>
                            <Controller control={methods.control} name={`startDate`} render={({ field: { onChange/*, value*/ } }) => (
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nb">
                                    <DatePicker label="fra" sx={{ width: '190px', marginTop: '8px' }} className={classes.tField}
                                        openTo="year"
                                        disableFuture={true}
                                        minDate={dayjs().set('year', 2022).set('month', 0)}
                                        maxDate={dayjs().set('year', 2024).set('month', 0)}
                                        value={dayjs(methods.getValues('startDate'))}
                                        views={["year", "month"]}
                                        onChange={(item) => { onChange(item); methods.setValue('startDate', item as any) }}
                                    />
                                </LocalizationProvider>
                            )}
                            />
                        </Grid>
                        <Grid className={classes.grd}>
                            <Controller control={methods.control} name={`endDate`} render={({ field: { onChange/*, value */ } }) => (
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nb">
                                    <DatePicker label="til" sx={{ width: '190px', marginLeft: '10px', marginTop: '8px' }} className={classes.tField}
                                        openTo="year"
                                        disableFuture={true}
                                        minDate={dayjs(methods.getValues('startDate'))}
                                        maxDate={dayjs(date).endOf('month')}
                                        value={dayjs(methods.getValues('endDate')).startOf('month')}
                                        views={["year", "month"]}
                                        onChange={(item) => { onChange(item); methods.setValue('endDate', (item as any).endOf('month')) }}
                                    />
                                </LocalizationProvider>
                            )}
                            />
                        </Grid><Grid item xs="auto" />
                        <Grid className={classes.grd}>
                            <Controller control={methods.control} name={`visning`} render={({ field: { onChange, value } }) => (
                                <Autocomplete sx={{ width: '250px', paddingLeft: '10px' }} id="butikk-select-demo" options={E.uVisning}
                                    onChange={(event, item) => {
                                        onChange(item); methods.setValue('visning', item as any);
                                        dispatch(actions.setfavorittListeVisningType(item));
                                    }}
                                    value={value} getOptionLabel={(option) => option}
                                    isOptionEqualToValue={(option, value: any) => value === undefined || option === value}
                                    renderInput={(params) => (
                                        <TextField className={classes.tField} {...params} label={"visning"} inputProps={{ ...params.inputProps }} margin="normal" variant="outlined" />
                                    )}
                                />)}
                            />
                        </Grid>

                        <Grid className={classes.grd}>
                            <ButtonVitec disabled={buttonReportDisabled} _onclick={methods.handleSubmit((o) => onSubmitGetSalesReport(o, 'web'))}
                                _width={'185px'} _height={33} _type={'button'} _label={'Hent salgsstatistikk'} />
                        </Grid>

                        <Grid className={classes.grd}>
                            <ButtonVitec disabled={buttonReportDisabled} _onclick={methods.handleSubmit((o) => onSubmitGetSalesReport(o, 'pdf'))}
                                _width={'50px'} _height={33} _type={'button'} _label={'PDF'} _color={'green'} />
                        </Grid>
                        <Grid className={classes.grd}>

                            <ButtonVitec disabled={buttonReportDisabled} _onclick={methods.handleSubmit((o) => onSubmitGetSalesReport(o, 'excel'))}
                                _width={'70px'} _height={33} _type={'button'} _label={'Excel'} _color={'green'} />
                        </Grid><Grid item xs="auto" />
                        <Grid className={classes.grd}>
                            <ButtonVitec disabled={buttonDataDisabled} _onclick={methods.handleSubmit((o) => onSubmitGetSalesReport(o, 'data'))}
                                _width={'70px'} _height={33} _type={'button'} _label={'rådata'} _color={'green'} _tooltip={'En krevende SQL-operasjon hvis du har mye data.' +
                                    ' Å redusere datoperioden i utvalget bidrar til å oppnå raskere resultater.'} />
                        </Grid>
                        
                    </Grid>
                </Grid>



                {/***************************************************************************************************************/}
                {/***************************************************************************************************************/}
                <Grid container height={'20px'} />
                <Grid container justifyContent='left' className={classes.ba} >
                    <Grid container className={classes.bb} direction="row"
                        justifyContent="left" alignItems="center">
                        <Grid className={classes.grd}>
                            <TextField label="Betegnelse" autoComplete="off" className={classes.tField} inputProps={{ maxLength: 3, shrink: 'false' }}
                                {...methods.register("betegnelse", { required: false })}
                                onKeyUp={(e) => { checkIfItAlreadyExist((e.target as any).value); methods.setValue(`betegnelse`, (e.target as any).value) }}
                            />
                        </Grid>
                        <Grid className={classes.grd}>
                            <TextField label="Merknader" autoComplete="off" className={classes.tField} inputProps={{ maxLength: 3, shrink: 'false' }}
                                {...methods.register("merknader", { required: false })}
                            />
                        </Grid>
                        <Grid className={classes.grd}>
                            <ButtonVitec disabled={buttonSaveDisabled} _onclick={methods.handleSubmit(onSubmitSaveFavorite)}
                             _width={180} _height={33} _type={'button'} _label={'Lagre Favorittliste'} />
                        </Grid>
                        <Grid className={classes.grd}>
                            <ButtonVitec disabled={buttonDeleteDisabled} _onclick={methods.handleSubmit(onSubmitDeleteFavorite)} 
                            _width={180} _height={33} _type={'button'} _label={'Fjern Favorittliste'} />
                        </Grid>
                       
                    </Grid>
                </Grid>
                {visningObj == null || visningObj === '' ? <Grid container height={'30px'} /> : null}
            </form>
        </FormProvider>


    );
};

export default TallenesTaleTableToolbar;
