import helpRed from '../assets/images/helpRed.svg';

const SVGHelpRed = () => {
  return (
    <div className="vitec-Help">
      <img src={helpRed} alt="Vitec-Help" />
    </div>
  );
};

export default SVGHelpRed;
