import { Backdrop, Checkbox, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Toolbar } from '@mui/material';
import { Component } from 'react';
import 'react-grid-layout/css/styles.css';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import ButtonVitec from '../../../components/ButtonVitec';
import SVGIconBin from "../../../components/SVGIconBin";
import SVGIconEdit from "../../../components/SVGIconEdit";
import Utils from "../../../felles/Utils";
import LdapUser from '../../../models/LdapUser';
import MenuEl from '../../../models/MenuEl';
import MenuItemTarget from '../../../models/MenuItemTarget';
import * as actions from '../../../store/actions/index';
import { AppState } from '../../../store/rootReducer';
import UserListTableHead from './UserListTableHead';
import UserListTableToolbar from './UserListTableToolbar';
import UserDelete from "./UserDelete";
import UserEdit from "./UserEdit";




/********************************************************************************/

class UserList extends Component<Props> {
  //
  state = {
    order: "asc",
    orderBy: "username",
    page: 0,
    rowsPerPage: -1 //10
  };

  //Init
  componentDidMount() {
    document.body.removeEventListener('touchmove', Utils.preventDefault);
    this.props.getUserList();
    this.props.setSandwichButtonStateClicked(false);
    // dispatch(actions.setSandwichButtonStateClicked(false));
    // for the tablet IPAD / GALAX

  }

  //
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "asc";
    if (this.state.orderBy === property && this.state.order === "asc") {
      order = "desc";
    }

    this.setState({ order, orderBy });
  };
  //
  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.props.onRequestSelected(this.props.userDataList.map(n => n.username));
      return;
    }
    this.props.onRequestSelected([]);
  };
  //
  handleCheckboxClick = (event, username: string) => {
    event.stopPropagation();
    const selectedIndex = this.props.selectedUserList.indexOf(username as string);
    //
    let newSelectedList: string[] = [];
    if (selectedIndex === -1) {
      newSelectedList = newSelectedList.concat(this.props.selectedUserList, username);
    } else if (selectedIndex === 0) {
      newSelectedList = newSelectedList.concat(this.props.selectedUserList.slice(1));
    } else if (selectedIndex === this.props.selectedUserList.length - 1) {
      newSelectedList = newSelectedList.concat(this.props.selectedUserList.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedList = newSelectedList.concat(
        this.props.selectedUserList.slice(0, selectedIndex),
        this.props.selectedUserList.slice(selectedIndex + 1)
      );
    }
    this.props.onRequestSelected(newSelectedList);
  };
  //
  handleSingleDelete = (event, username: string) => {
    event.stopPropagation();
    const newSelectedList: string[] = [];
    newSelectedList.push(username)
    this.props.onRequestSelected(newSelectedList);
    this.props.setUserDeletionState(1);
  };
  //
  //handleChangePage = (event, page) => { this.setState({ page }); };
  //
  handleChangeRowsPerPage = event => { this.setState({ rowsPerPage: event.target.value }); };
  //
  handleUserEdit = (event, val: string) => { this.props.setUserEditState(1); this.props.getUserLDAPData(val); };
  //
  //handleUserListDeletion = (event, val: string) => { this.props.setUserDeletionState(1); };
  //
  isSelected = (username: string) => this.props.selectedUserList.indexOf(username) !== -1;
  //
  generateEditStates = () => {
    const { userEditState } = this.props;
    switch (true) {
      case (userEditState === 0):
        return null;
      case (userEditState === 1):
        return <UserEdit />;
      case (userEditState === 2):
        return <UserEdit />;
      default:
        return null;
    }
  }

  sendRequest = () => {
    this.props.userListExportToCVS();
  }

  //
  generateDeletionStates = () => {
    const { userDeletionState } = this.props;
    switch (true) {
      case (userDeletionState === 0):
        return null;
      case (userDeletionState === 1):
        return <UserDelete />;
      case (userDeletionState === 2):
        return <UserDelete />;
      case (userDeletionState === 3):
        return <UserDelete />;
      default:
        return null;
    }
  }


  render() {
    const { classes } = this.props;
    const { order, orderBy, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, this.props.userDataList.length - page * rowsPerPage);
    //
    return (
      <div className={classes.root}>
        <Paper>
          <div>
            <UserListTableToolbar />
            <TableContainer className={classes.container}>
              <Backdrop className={classes.backdrop} open={this.props.isFetchingUserList}>
                <CircularProgress color="inherit" />
              </Backdrop>
              <div className={classes.tableWrapper}>
                <Table stickyHeader className={classes.table} size="small" aria-labelledby="tableTitle">
                  <UserListTableHead
                    numSelected={this.props.selectedUserList?.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={this.handleSelectAllClick}
                    onRequestSort={this.handleRequestSort}
                    rowCount={this.props.userDataList.length}
                  />
                  <TableBody>
                    {this.props.userDataList ? Utils.stableSort(this.props.userDataList, Utils.getSorting(order, orderBy))
                      .map((n: LdapUser) => {
                        const isSelected = this.isSelected(n.username as string);
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isSelected}
                            tabIndex={-1}
                            key={n.username}
                            selected={isSelected}
                          >
                            <TableCell padding="checkbox" sx={{ color: 'white', border: 'none' }}>
                              <Checkbox onClick={event => this.handleCheckboxClick(event, n.username)} className="selectCheckbox" checked={isSelected} sx={{ color: 'white', '&.Mui-checked': { color: "red" } }} />
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center" component="th" scope="row" padding="none">{n.username}</TableCell>
                            <TableCell className={classes.tableCell} align="center" component="th" scope="row" padding="none">{n.firstname}</TableCell>
                            <TableCell className={classes.tableCell} align="center" component="th" scope="row" padding="none">{n.lastname}</TableCell>
                            <TableCell className={classes.tableCell} align="center" component="th" scope="row" padding="none">{n.email}</TableCell>
                            <TableCell className={classes.tableCell} align="center" component="th" scope="row" padding="none">{n.operatorId}</TableCell>
                            <TableCell className={classes.tableCell} align="center" component="th" scope="row" padding="none">{n.lastLogonTimestamp}</TableCell>
                            <TableCell className={classes.tableCell} align="center" component="th" scope="row" padding="none"
                            >
                              {n.username !== this.props.userJWT.sub ? <div className={classes.iconWrapper}><div className={classes.locallinkA} onClick={event => this.handleUserEdit(event, n.username)}>

                                <SVGIconEdit title="Edit" width={22} />
                              </div><div className={classes.locallinkB} onClick={event => this.handleSingleDelete(event, n.username)}>  <SVGIconBin width={22} /></div></div> : null}
                            </TableCell>
                          </TableRow>
                        );
                      }) : null}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 49 * emptyRows }}><TableCell colSpan={9} /></TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
          </div>
          <Toolbar sx={{ backgroundColor: '#2E2D2C', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px' }}>
            <div className={classes.spacer} />
            <div className={classes.actions}>
              <ButtonVitec className={classes.button} variant="contained" _onClick={() => this.sendRequest()} _width={150} _label={'Export (.xlsx)'} />
            </div>
          </Toolbar>
        </Paper>
        {/*this.props.userDeletionState*/}
        {this.generateEditStates()}
        {this.generateDeletionStates()}
      </div>
    );
  }
}

const UserListStyled = withStyles(
  UserList,
  (theme, props) => ({
    root: {
      marginTop: 65,
      marginLeft: 20,
      marginRight: 20,
      paddingTop: 0,
      width: '100%',//1900, //props.size.width + 'px',

      '@media only screen and (min-width: 1024px) and (max-height: 1366px)  and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 1.5)': {
        paddingTop: 0,
        marginTop: 8,

      },
    },
    backdrop: { zIndex: theme.zIndex.drawer + 1, color: '#fff', },
    container: {
      height: `calc(100vh - ${210}px)`, backgroundColor: '#2E2D2C', border: 'none', "&.MuiTableContainer-root": {
        "&::-webkit-scrollbar": {
          width: "20px"
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#4f4d4c",

        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#706c6a",

        }
      }
    },

    paper: { marginTop: 30, },
    table: {
      minWidth: 700,
      fontSize: 200,
    },
    tableCell: { paddingTop: 0, height: 24, color: 'white', border: 'none', },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    locallinkA: { cursor: 'pointer', paddingTop: 6, },
    locallinkB: { cursor: 'pointer', paddingTop: 4, paddingLeft: 14, paddingRight: 14, },
    button: { width: 150, marginRight: theme.spacing(1) },
    spacer: { flex: "1 1 100%" },
    actions: { color: theme.palette.text.secondary, marginTop: -10 },
    iconWrapper: { display: 'flex' },
  })
);

interface sentFromParentComponent {
  size: any,
  classes?: any, //place Holder
}

interface StateProps {
  onRequestSort?: (event: any, property: any) => void;
  userDataList: LdapUser[];
  selectedUserList: any[];
  isFetchingUserList: boolean;
  userEditState: number;
  userDeletionState: number;
  userJWT: any,
  currentBreakpoint: string;
  className?: string;
  classes?: Partial<Record<"root" | "story" | "label" | "backdrop" | "container" | "table" | "tableWrapper" | "tableCell" | "locallinkA" | "locallinkB" | "iconWrapper" | "spacer" | "actions" | "button", string>>;
  menuItemTarget?: MenuItemTarget;
  menuItems?: MenuEl[];
}
//
interface DispatchProps {
  getUserList: () => void;
  userListExportToCVS: () => void;
  getUserLDAPData: (val: string) => void;
  setUserEditState: (val: number) => void;
  setUserDeletionState: (val: number) => void;
  onRequestSelected: (Selected: any[]) => void;
  setSandwichButtonStateClicked: (val: boolean) => void;
}

type Props = sentFromParentComponent & StateProps & DispatchProps;

export function mapStateToProps(state: AppState): StateProps {
  return {
    menuItems: state.menuItem.menuItems,
    menuItemTarget: state.menuItem.menuItemTarget,
    userDataList: state.userAdmin.userDataList,
    isFetchingUserList: state.userAdmin.isFetchingUserList,
    selectedUserList: state.userAdmin.selectedUserList,
    userEditState: state.userAdmin.userEditState,
    userDeletionState: state.userAdmin.userDeletionState,
    userJWT: state.auth.userJWT,
    currentBreakpoint: state.ui.currentBreakpoint,
  }
}
//
export function mapDispatchToProps(dispatch): DispatchProps {
  return {
    getUserList: () => dispatch(actions.userListFetch()),
    userListExportToCVS: () => dispatch(actions.userListExportToCVS()),
    getUserLDAPData: (val: string) => dispatch(actions.userDataFetched(val)),
    setUserEditState: (val: number) => dispatch(actions.setUserEditState(val)),
    setUserDeletionState: (val: number) => dispatch(actions.setUserDeletionState(val)),
    onRequestSelected: (Selected: string[]) => dispatch(actions.userListSelectedUpdated(Selected)),
    setSandwichButtonStateClicked: (val: boolean) => dispatch(actions.setSandwichButtonStateClicked(val)),
  }
}
export default connect<StateProps, DispatchProps, sentFromParentComponent, AppState>(mapStateToProps, mapDispatchToProps)(UserListStyled);

