import { Drawer } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import UISize from '../models/UISize';
import * as actions from '../store/actions/index';
import { AppState } from '../store/rootReducer';
import LPNavigationWrapper from './LPNavigationWrapper';

const useStyles = makeStyles()((/*theme: Theme*/) => ({
  drawer: { width: 340, flexShrink: 0, },
  dialogPaper: { width: 340, border: '0px  solid', backgroundColor: 'tranparent imporm' },
  '@media print': { drawer: { visibility: 'hidden', width: 0, }, },
}),
);

export default function LPDrawer() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const screenSize: UISize = useSelector(state => (state as AppState).ui.screenSize);
  const open: boolean = useSelector(state => (state as AppState).ui.isDrawerOpened);
  const isButtonVisible: boolean = useSelector(state => (state as AppState).ui.isSandwichButtonTurnedOn);

  useEffect(() => {
    dispatch(actions.setDrawerState(!(screenSize.width > 0 && screenSize.width < 1200)));
  }, [dispatch, screenSize]);
  //

  return (
    <Drawer PaperProps={{ style: { backgroundColor: 'transparent', boxShadow: 'none', width: 340, border: 'none' } }}
      className={classes.drawer} variant="persistent" anchor="left" open={isButtonVisible ? true : open}>
      <LPNavigationWrapper />
    </Drawer>
  );
}
