import link from '../assets/images/links.svg';

const SVGLinks = (props) => {
  return (
    <div className="vitec-links">
      <img src={link} width={props.width} alt="Vitec-links" />
    </div>
  );
};

export default SVGLinks;
