import { CssBaseline, Theme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SizeMe } from 'react-sizeme';
import { makeStyles } from 'tss-react/mui';
import Utils from '../../../felles/Utils';
import MenuItemTarget from '../../../models/MenuItemTarget';
import * as actions from '../../../store/actions/index';
import { AppState } from '../../../store/rootReducer';
import LPDrawer from '../../../components/LPDrawer';
import LPAppBar from '../../../components/LPAppBar';
import ACC1FormContent from './components/ACC1FormContent';


const useStyles = makeStyles()((theme: Theme) => ({
  root: { display: 'flex', height: '100vh', width: '100vw' },
  content: {
    marginTop: 40,
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -340,
  },
  contentShift: {
    //marginTop: 15,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}),
);

export default function ACC1Page(props) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  //const screenSize: UISize = useSelector(state => (state as AppState).ui.screenSize);
  //const menuItems: MenuEl[] = useSelector(state => (state as AppState).menuItem.menuItems);
  //const open = screenSize.width > 0 && screenSize.width < 1200 ? false : true;
  const navigate = useNavigate();
  const n: MenuItemTarget = useSelector((state) => (state as AppState).menuItem.menuItemTarget);
  //
  const handlePressedKey = (event) => {
    event.preventDefault();
    const { key } = event;
    if ((event.shiftKey && key === 'End') || key === 'F3') {
      dispatch(actions.menuItemSetTarget(new MenuItemTarget(n.rootItem, n.item !== '' ? n.item : n.rootItem, '', '', n.tilpasset, n.tilpassetBeta, n.kodeSynlig, n.labelSynlig, n.hasChildren, n.title)));
      dispatch(actions.menuItemSetSelected([n.rootItem, n.item + n.rootItem]));
      navigate(Utils.HOMEPAGE_CONTEXT + '/menu');
    }
  }
  //
  return (
    <div tabIndex={0} onKeyDown={(e) => handlePressedKey(e)}>
      <div className={classes.root}>
        <CssBaseline />
        <LPDrawer />
        <>
          <LPAppBar ismenuvisible={true} />
          <SizeMe>{({ size }) => <ACC1FormContent size={size} />}</SizeMe>
        </>
      </div >
    </div>
  );
}