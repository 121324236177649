import { put } from 'redux-saga/effects';
import { fetchOrkuData } from '../../felles/APICalls_NO01';
import * as actions from '../../store/actions';
import * as actionsNV01 from '../actions/indexNO01';


export function* fetchTradingPartnersSaga(action) {
  //console.log("Saga called with action: ", action);
  try {
    yield put(actions.setIsloading(true));
    const response = yield fetchOrkuData(action.payload);
    //console.log("Saga response: ", response);
    if (response) {
      yield put(actionsNV01.fetchTradingPartnersSuccess(response));
    } else {
      yield put(actionsNV01.fetchTradingPartnersFailure("No data received"));
    }
  } catch (error) {
    console.error("Saga error: ", error);
    yield put(actionsNV01.fetchTradingPartnersFailure("Error"));
  } finally {
    yield put(actions.setIsloading(false));
  }
}