import { GlobalStyles } from '@mui/material';

//
const SVGHeaderLogo = props => {
  return (<>
    <GlobalStyles
      styles={{
        '.svgCls>g': { fill: '#2E2D2C', },
        '@media only screen and (min-width: 1024px) and (max-height: 1366px)  and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 1.5)': {
          '.svgCls>g': { fill: '#2E2D2C', },
        },

        // Samsung GalaxyTab
        '@media only screen and (min-width: 1205px) and (max-height: 650px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5)': {
          '.svgCls>g': { fill: '#2E2D2C', },
        },
      }}
    />

    <svg className="svgCls" xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 567.10439 149.8" width={props.width} >
      <g id="g183" transform="translate(-60.79564,-58.3)"><path
        className="logo"
        d="m 601,131.1 c -22.6,0 -38.7,-12.9 -38.7,-30.3 0,-16.4 15.8,-30.8 39.9,-30.8 8.7,0 15.8,1.9 22.9,4.8 v -12 c -5.8,-1.8 -13.8,-3.1 -23,-3.1 -33.5,0 -55.5,18.6 -55.5,42.1 0,23.1 19.3,40.7 52,40.7 9.7,0 19.5,-1.7 29.3,-5.7 l -4.3,-9.8 c -7,2.5 -14.6,4.1 -22.6,4.1"
        id="path103"
      /><rect x="311.5" y="60.700001" width="14.2" height="81.199997" id="rect105" />
        <path id="path107"
          d="m 431.4,72.3 h -36.7 v 69.6 H 380.3 V 72.3 h -36.8 c 0,0 0,-11.5 11.7,-11.5 h 76.2 z"
        />
        <path id="path109"
          d="M 249.1,141.9 H 237.4 L 196,60.7 h 15.9 l 32,62.6 c 2.4,-4.3 28.8,-52.9 30.1,-55.2 5.8,-10.2 19.6,-7.3 19.6,-7.3 z"
        />
        <path id="path111"
          d="m 458.4,83.4 c 4.2,-8.2 14.9,-14.5 28,-14.5 17.6,0 30.5,11.6 30.5,22.9 h -39.1 c -0.2,0 -11.3,0.3 -11.3,11.3 v 0 h 65.2 v -2.9 c 0,-25.3 -15.8,-41.9 -45.5,-41.9 -27.8,0 -46.5,17.5 -46.5,41.1 0,23.2 17.3,44 53.4,44 13.4,0 27,-3.7 35.3,-8.5 l -4.7,-9.6 c -6.8,3.1 -17.2,6.2 -26.9,6.3 -19.9,0.3 -34.6,-8.7 -38.9,-20.5 -1.8,-3.5 -6.1,-15.2 0.5,-27.7"
        />
        <path id="path113" fill='#d30535'
          d="m 129.5,70.1 c 3.3,-3.3 6.7,-6.4 10.2,-9.4 h -27.2 c -35.9,1.4 -28.8,31.5 -27.1,37.1 0.1,0.3 0.2,0.6 0.3,0.9 v 0 0 c 1.4,4.3 3,8.6 4.7,12.8 1.4,3.4 3,6.7 4.6,10 0.2,-0.4 0.3,-0.8 0.5,-1.2 8.1,-18.7 19.5,-35.6 34,-50.2"
        />
        <path id="path115"
          d="m 161.2,60.5 c -3.8,2.1 -7.3,4.6 -9.8,6.6 -1.2,0.9 -2.3,1.9 -3.5,2.8 -0.3,0.3 -0.5,0.5 -0.5,0.5 l 0.1,-0.1 c -3.1,2.7 -6.2,5.5 -9.1,8.4 -13.4,13.4 -24,29.1 -31.4,46.5 -1.4,3.4 -2.7,6.8 -3.9,10.2 -0.7,2.2 -1.4,4.5 -2.1,6.7 2,0 4.3,0 6.8,0 6,0 13.2,0 21.9,0 31.5,0 31.5,-31.3 31.5,-31.3 z"
        />
        <path id="path117"
          d="M 89.5,137.2 C 85.5,130.6 82,123.6 78.9,116.4 75.1,107.5 72.2,98.3 70.1,89 53.5,125.4 59.5,142.1 85,142.1 c 0.9,0 1.9,0 3.2,0 1.3,0 2.8,0 4.5,0 -1.1,-1.6 -2.2,-3.2 -3.2,-4.9"
        />
      </g>
    </svg>
  </>
  );
};

export default SVGHeaderLogo;