import axios, { AxiosRequestConfig } from 'axios';

import UserCred from '../models/UserCred';
import U from './Utils';
import CSharpUtils from './CSharpUtils';

export const loginToAutodataDotNet = (userCred: UserCred) => {
  const config = {
    method: 'post',
    baseURL: `${`${U.URL_ADDRESS}${U.DOTNET_SERVICE}`}/auth/login`,
    maxContentLength: 1000000,
    timeout: 10000,
    headers: {
      'content-type': 'application/json',
      Accept: 'application/json;charset=utf-8',
    },
    data: {
      username: userCred.username,
      password: userCred.password,
    },
    responseType: 'json',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};


//
export const autoLoginToAutodataDotNetAPI = () => {
  const config = {
    method: 'get',
    baseURL: `${`${U.URL_ADDRESS}${U.DOTNET_SERVICE}`}/auth/refresh`,
    maxContentLength: 1000000,
    timeout: 20000,
    headers: {
      'content-type': 'application/json',
      Accept: 'application/json;charset=utf-8',
      Authorization: `Bearer ${CSharpUtils.Get_Token}`, 
    },
    responseType: 'json',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};


