import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import LogoSimple from '../../components/SVGLogoSimple';
import UserCred from '../../models/UserCred';
import * as actions from '../../store/actions/index';


//import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from 'tss-react/mui';
import { useNavigate } from "react-router-dom";
import Utils from '../../felles/Utils';
import { AppState } from '../../store/rootReducer';


const useStyles: any = makeStyles()((/*theme*/) => ({
  root: {
    // color: '#0f68a3',
    minWidth: 250,
    width: 350,
    padding: 20,
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 10,
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    '& .MuiTextField-root': {
      marginBottom: '15px',
      width: '100%'
    }
    /* '& .MuiTextField-root': {
       margin: theme.spacing(1),
       width: 300,
       border: '1px solid',
       '&:hover': {
         backgroundColor: '#0069d9',
         borderColor: '#0062cc',
         boxShadow: 'none',
       },
       '&:active': {
         boxShadow: 'none',
         backgroundColor: '#0062cc',
         borderColor: '#005cbf',
       },
       '&:focus': {
         boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
       },
     },*/






  },

}));
/*
 *
 */
export default function PasswordLinkRecovery() {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const [user, setUser] = useState('');
  const navigate = useNavigate();
  const open = useSelector(state => (state as AppState).auth.isPasswordLinkRecoveryDialogOpen);
  const feedbackLinkRecoveryDialog: any = useSelector(state => (state as AppState).auth.feedbackLinkRecoveryDialog);
  const isRecoveryButtonVisible = useSelector(state => (state as AppState).auth.isFeedbackLinkRecoveryCloseButtonDialogVisible);
  // const isRedirectLinkRecoveryDialogEnabled = useSelector(state => (state as AppState).auth.isRedirectLinkRecoveryDialogEnabled);

  const isRecoveryLinkSentSuccessfully: any = useSelector(state => (state as AppState).auth.isRecoveryLinkSentSuccessfully);

  // TODO MOVE  TO SAGA!
  function handleDiagClose(val: boolean) {
    dispatch(actions.passwordLinkRecoveryDialogClosed(val));


    if (isRecoveryLinkSentSuccessfully && val)
      navigate(Utils.HOMEPAGE_CONTEXT + "/home");
  }

  // TODO REFAKTOR TO SAGA!
  function handleSubmit(event) {
    event.preventDefault();
    handleDiagClose(false);
    dispatch(actions.setPasswordLinkRecoveryDialogButtonVisible(false));
    dispatch(actions.updateRecoveryDialog('Vennligst vent...'));
    dispatch(actions.userPasswordLinkRecoveryFetch(new UserCred(user, '', '', false)));
  }

  return (
    <div>
      <Dialog
        open={!open}
        onClose={() => handleDiagClose(true)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Tilbakestilling av passord"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{feedbackLinkRecoveryDialog}
          </DialogContentText>
        </DialogContent>
        <DialogActions>

          {isRecoveryButtonVisible ? (<Button onClick={() => handleDiagClose((true))} color="primary" autoFocus>
            Ok
          </Button>) : []}
        </DialogActions>
      </Dialog>
      <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
        <div className="form-login__logo"><LogoSimple width="150" /></div>
        <div className="form-login__h2 centerer marginTopBottom20">Tilbakestilling av passord</div>
        <div className="form-login__h4 centered marginTopBottom20">Oppgi ditt brukernavn.</div>
        <div className="form-login__textfield marginTopBottom20">
          <TextField type="text" name="user" onInput={e => setUser((e.target as HTMLTextAreaElement).value)} placeholder="" variant="outlined" />
        </div>

        <div className="form-login__submit-button centered marginTopBottom10">
          <Button type="submit" variant="contained" color="primary" disableElevation>
            Send</Button>
        </div>
        <div className="form-login__h4 centered marginTopBottom20">Hvis ditt brukernavn er kjent i våre systemer vil du motta en e-post med instruksjoner på registrert e-post.
          <br />Mottar du ikke e-post innen kort tid sjekk mappen for <strong>søppelpost/spam</strong></div>
        <div className="centered marginTopBottom10"><NavLink to={Utils.HOMEPAGE_CONTEXT + '/home'}>Tilbake til innlogging</NavLink> </div>
        <div className="centered marginTopBottom10">Trenger du hjelp ta kontakt med <a href="mailto:support.autodata@vitecsoftware.com?subject=Tilbakestilling av passord">support.autodata@vitecsoftware.com</a> </div>
      </form>

    </div>

  );
} 