import { lighten, Toolbar } from '@mui/material';
import classNames from "classnames";
import { useDispatch } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import { makeStyles } from 'tss-react/mui';
import ButtonVitec from '../../../../components/ButtonVitec';

const useStyles: any = makeStyles()((theme) => ({
    root: {
        paddingRight: theme.spacing(1) , backgroundColor: '#2E2D2C', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px' 
    },
    button: {
        width: 150,
        marginRight: theme.spacing(1),
    },
    highlight:
        theme.palette.primary.light === "light"
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85)
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark
            },
    spacer: {
        flex: "1 1 100%"
    },
    actions: {
        color: theme.palette.text.secondary
    },
    title: {
        flex: "0 0 auto"
    }
}));

const ACC1FormFooter = (/*props*/) => {
    const { classes } = useStyles();
    const dispatch = useDispatch();
    function sendRequest() {
        dispatch(actions.accountingJobCVSListGet());
    }

    return (
        <Toolbar className={classNames(classes.root)} >
            <div className={classes.spacer} />
            <div className={classes.actions}>
                <ButtonVitec _onclick={() => sendRequest()} className={classNames(classes.button)}  _width={180} _height={37} _type={'submit'} _label={'Export (.csv)'} />
            </div>
        </Toolbar>
    );
};

export default ACC1FormFooter;
