import { Backdrop, CircularProgress, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import ButtonVitec from '../../../components/ButtonVitec';
import DialogVitec from '../../../components/DialogVitec';
import * as actions from '../../../store/actions/index';
import { AppState } from '../../../store/rootReducer';




const useStyles = makeStyles()((theme: Theme) => ({
  root: { width: '100%', marginTop: -40, paddingRight: theme.spacing(1) },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: '#fff' },
  myButtons: { display: 'inline-flex', verticalAlign: 'top' },
  myButton: { padding: '10px', margin: '0px 10px 20px 10px' },
  myButtonsB: { display: 'inline-flex', verticalAlign: 'top', margin: '0 50%', transform: 'translateX(-80px)' },
  myButtonB: { padding: '10px', margin: '0px 10px 20px 10px' },
  dialogCustomizedWidth: { padding: '20px 0px 2px 0px', 'min-width': '25%', 'max-width': '25%' },

  myDialogTitle: {
    textAlign: 'center',
    fontSize: '1.9em',
    width: '100%',
  },
  myDialogText: { textAlign: 'center', fontSize: '1.em', marginTop: '40px' },
}));

//

const UserDelete = (/*props*/) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const selectedUserList: string[] = useSelector((state) => (state as AppState).userAdmin.selectedUserList);
  const userDeletionState: number = useSelector((state) => (state as AppState).userAdmin.userDeletionState);
  const isDeletingUserData: boolean = useSelector((state) => (state as AppState).userAdmin.isDeletingUserData);
  //
  const handleDiagOKClickDelete = () => {
    dispatch(actions.setUserDeletionState(2));
    dispatch(actions.userListSelectedDeletionFetch(selectedUserList));
  };
  //
  const handleDiagValidateDelition = () => {
    dispatch(actions.userListSelectedUpdated([]));
    dispatch(actions.userListFetch());
    dispatch(actions.setUserDeletionState(0));
  };

  //
  const handleDiagCancel = () => {
    dispatch(actions.setUserDeletionState(0));
    dispatch(actions.userListSelectedUpdated([]));
  };

  //
  const generateDeletionStates = () => {
    switch (true) {
      case userDeletionState === 1:
        return (
          <DialogVitec>
            <div className={classes.myDialogTitle}>{'Slett bruker ?'}</div>
            {isDeletingUserData ? (
              <>
                {'please wait'} <br />
                <CircularProgress color="inherit" />
              </>
            ) : (
              <>
                <div className={classes.myDialogText}>
                  {selectedUserList.length > 1 ? 'Er du sikker på at du vil slette brukerne?' : 'Er du sikker på at du vil slette denne brukeren?'}
                  <br />
                  {selectedUserList.map(function (item, index) {
                    return <strong>{item + (index < selectedUserList.length - 1 ? ', ' : '')}</strong>;
                  })}
                  <br />
                  <div className={classes.myButtons}>
                    <div className={classes.myButton}>
                      <ButtonVitec _onclick={handleDiagOKClickDelete} _width={80} _height={37} _type={'button'} _label={'Ok'} />
                    </div>
                    <div className={classes.myButton}>
                      <ButtonVitec _onclick={handleDiagCancel} _width={80} _height={37} _type={'button'} _label={'Avbryt'} />

                    </div>
                  </div>
                </div>
              </>
            )}
          </DialogVitec>
        );
      case userDeletionState === 2:
        return (
          <DialogVitec>
            <div className={classes.myDialogTitle}>{selectedUserList.length > 1 ? 'Bruker slettet' : 'Brukere slettet'}</div>
            <div className={classes.myDialogText}>
              {selectedUserList.map(function (item, index) {
                return <strong>{item + (index < selectedUserList.length - 1 ? ', ' : '')}</strong>;
              })}
              <br />
              <div className={classes.myButtons}>
                <div className={classes.myButton}>
                  <ButtonVitec _onclick={handleDiagValidateDelition} _width={80} _height={37} _type={'button'} _label={'Ok'} />
                </div>
              </div>
            </div>
          </DialogVitec>
        );
      default:
        return null;
    }
  };

  //
  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={true}>
        {generateDeletionStates()}
      </Backdrop>
    </div>
  );
};

export default UserDelete;
