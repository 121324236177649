import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, IconButton, Paper, Popper, TextField, Tooltip } from '@mui/material';
import { createRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import LogoSym from '../components/SVGLogoSym';
import { default as U, default as Utils } from '../felles/Utils';
import MenuEl from '../models/MenuEl';
import MenuItemTarget from '../models/MenuItemTarget';
import SearchSel from '../models/SearchSel';
import * as actions from '../store/actions/index';
import { AppState } from '../store/rootReducer';

const useStyles = makeStyles()((/*theme: Theme*/) =>
({
  customTooltip: { display: 'float', right: -50, backgroundColor: '#fff', fontSize: '1em', color: '#2E2D2C', },
  customArrow: { color: ' #fff', },
  //
  searchCss: { paddingTop: -5, width: 150, height: 45, backgroundColor: '#fff', flex: 1, },
  // 
  searchBar: {
    display: 'flex', marginBottom: 10,
    '& label': { height: 25, textAlign: 'center', color: '#d30535', },
    '& label.Mui-focused': { top: 12, animationIterationCount: 1, transform: 'translate(0, -10px) scale(1)', fontSize: '.75em', color: '#d30535', },
    '& .MuiInputLabel-formControl': { top: 11, left: 6, position: 'absolute', transform: 'translate(0, 0) scale(1)', color: '#d30535', },
    '& .MuiInput-underline:after': { height: 45, width: 0 },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": { border: "none", },
  },
  //
  iconButton: { padding: 6, marginLeft: 2, backgroundColor: '#d30535', color: '#fff' },
  buttonRoot: { width: '100%', display: 'inline-block', textAlign: 'left' },
  buttonCode: { fontWeight: 600, color: '#d30535' },
  buttonDesc: { color: '#2E2D2C', marginLeft: 5, fontSize: '.9em' },
  buttonHeading: { textAlign: 'left', color: '#2E2D2C', marginLeft: 5, fontSize: '.9em', verticalAlign: 'bottom' },
  path: { display: 'inline-block', color: '#2E2D2C', width: 500, fontSize: '.9em' },
  rootName: { color: '#243367' },
  parentName: {},
  headName: { color: '#d30535' },
  //
  inputRoot: {
    paddingLeft: 15,
    paddingBottom: 7,
    paddingTop: 7,
    cursor: 'pointer',
    "&.Mui-focused": { background: '#f6f6f6', },
  },
})
);

const PopperMy = function (props) {
  return <Popper {...props} style={{ width: 550 }} placement="bottom-start" />;
};

export default function LPNavigationSearch() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedSearchOption: SearchSel = useSelector((state) => (state as AppState).search.searchSelectionOptions);
  const inputValue: string = useSelector((state) => (state as AppState).search.searchInputValue);
  //const isFilled: boolean = useSelector((state) => (state as AppState).search.isSearchInputPopulated);
  const menuItems: MenuEl[] = useSelector((state) => (state as AppState).menuItem.menuItems);
  const [searchVal, setSearchVal] = useState('');
  const isSearchFieldFocused: boolean = useSelector((state) => (state as AppState).search.isSearchFieldFocused);
  const formRef: any = createRef();
  const isTransGetInProgress: boolean = useSelector((state) => (state as AppState).trans.isTransGetInProgress);

  //
  useEffect(() => {
    if (isSearchFieldFocused) {
      const _target = formRef.current?.childNodes[0]?.childNodes[1]?.childNodes[0];
      if (_target) {
        _target.focus();
      }
    }
  }, [isSearchFieldFocused, formRef, isTransGetInProgress]);
  //
  const handleHomeClick = () => {
    // Refreshes news and operations feed
    dispatch(actions.nyheterMeldingFetchStarted());
    dispatch(actions.driftMeldingFetchStarted());
    //
    dispatch(actions.menuItemSetTarget(new MenuItemTarget()));
    dispatch(actions.setSearchSelectionOptions(new SearchSel()));
    dispatch(actions.setSearchInputPopulatedState(false));
    dispatch(actions.menuItemSetExpanded([undefined]));
    dispatch(actions.menuItemSetSelected([undefined, undefined]));
    navigate(Utils.HOMEPAGE_CONTEXT + '/menu');
  };
  //
  function handleRightClick(e, data) {
    const clickX = e.clientX;
    const clickY = e.clientY;
    dispatch(actions.setContextMenuVisible(true));
    dispatch(actions.setContexMenuX(clickX));
    dispatch(actions.setContexMenuY(clickY));
    dispatch(actions.setTransidRedirect(data.code));/**/
    dispatch(actions.setTilpasset(data.tilpasset));/**/
  }
  //
  const handleSearchClick = (e: any) => {
    dispatch(actions.setSandwichButtonStateClicked(false));
    // Prevent form submit
    e?.preventDefault();
    e?.stopPropagation();
    // require a min two char
    if (searchVal?.length > 2) {
      if (searchVal === 'ACC1' || searchVal === 'ACC' || searchVal === 'AC') {
        navigate(Utils.HOMEPAGE_CONTEXT + '/ACC1');
      } else {
        if (searchVal.length >= 2) {
          for (const element of menuOptions()) {
            if (element.code.includes(searchVal)) {
              if (element.tilpasset === 1) {
                navigate(Utils.HOMEPAGE_CONTEXT + '/' + element.code);
              } else if (!isTransGetInProgress) {
                dispatch(actions.transGet(new MenuItemTarget(element.menuItem.rootName, element.menuItem.parentName
                  , element.menuItem.name, element.code, element.menuItem.tilpasset
                  , element.menuItem.tilpassetBeta, element.menuItem.kodeSynlig, element.menuItem.labelSynlig, false, element.menuItem.description)));
              }
              dispatch(actions.menuItemSetExpanded([element.menuItem.rootName]));
              dispatch(actions.menuItemSetSelected([element.menuItem.rootName, element.menuItem.parentName]));
              break;
            }
          }
        }
      }
      // Clean up
      setSearchVal('');
      dispatch(actions.setSearchInputFocused(false));
      dispatch(actions.setSearchInputValue('')); // To check
      dispatch(actions.setSearchSelectionOptions({}));
    }
  };

  //
  function menuOptions() {
    const options: SearchSel[] = [];
    if (menuItems && menuItems.length > 0) {
      menuItems.forEach((item: MenuEl) => {
        item.nodes.forEach((subItem: MenuEl) => {
          subItem.nodes.forEach((last: MenuEl) => {
            last.nodes.forEach((lst: MenuEl) => {
              options.push({ code: lst.code, tilpasset: +lst.tilpasset, name: lst.description, indexing: lst.code + ' ' + lst.description, menuItem: lst } as SearchSel);
            });
          });
        });
      });
    }
    return options;
  }

  return (
    <Paper component="form" className={classes.searchBar} style={{ backgroundColor: '#d30535', width: '100%' }}>
      <IconButton className={classes.iconButton} aria-label="menu" onClick={handleHomeClick}>
        <NavLink to={U.HOMEPAGE_CONTEXT + '/menu'}>
          {' '}
          <LogoSym width="20px" />
        </NavLink>
      </IconButton>
      <Autocomplete
        open={isSearchFieldFocused}
        PopperComponent={PopperMy}
        value={selectedSearchOption}
        // closeIcon={false}
        id="code-select"
        ref={formRef}
        noOptionsText={'Ingen alternativer funnet.'}
        clearOnEscape={true}
        clearIcon={null}
        clearText={'clear'}
        forcePopupIcon={false}
        options={menuOptions().sort((a, b) => -b.indexing.localeCompare(a.indexing))}
        onChange={(event: any, val: any) => {
          if (val) {
            setSearchVal(val);
            handleSearchClick(event);
          }
        }}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            dispatch(actions.setSearchInputFocused(false));
            handleSearchClick(e);
          } else dispatch(actions.setSearchInputFocused(true));
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          dispatch(actions.setSearchInputValue(newInputValue));
          if (newInputValue.length > 0) dispatch(actions.setSearchInputPopulatedState(true));
        }}
        className={classes.searchCss}
        onFocus={() => { dispatch(actions.setSearchInputFocused(true)); }}
        onBlur={() => { dispatch(actions.setSearchInputFocused(false)); }}
        autoHighlight={true}
        getOptionLabel={(option: any) => option.indexing || ''}
        isOptionEqualToValue={(option: any, value: any) => { return value.fieldType === option.fieldType; }}
        onHighlightChange={(event, newInputValue) => { setSearchVal(newInputValue?.code || ''); }}
        filterOptions={(options, s) => {
          const _result: SearchSel[] = [];
          // first pass searches code
          options.forEach((element) => { if (element.code.startsWith(s.inputValue.toUpperCase())) { _result.push(element); } });
          // second pass searches and add indexing
          options.forEach((element: SearchSel) => {
            if (!_result.includes(element) && element.indexing.toLowerCase().includes(s.inputValue.toLowerCase())) {
              if (!s.inputValue.toLowerCase().startsWith(element.code.toLowerCase())) _result.push(element);
            }
          });
          return _result;
        }}
        renderOption={(props: any, s: SearchSel) => {
          return (
            <li {...props} key={props.id} className={classes.inputRoot}
              onContextMenu={(event) =>
                handleRightClick(
                  event,
                  new MenuItemTarget(
                    s.menuItem.rootName,
                    s.menuItem.parentName,
                    s.menuItem.name,
                    s.menuItem.code,
                    s.menuItem.tilpasset,
                    s.menuItem.tilpassetBeta,
                    s.menuItem.kodeSynlig,
                    s.menuItem.labelSynlig,
                    s.menuItem.hasChildren,
                    s.menuItem.description
                  )
                )
              }
            >
              <span className={classes.buttonCode}>
                {s.code}
                <span className={classes.buttonDesc}>{s.name}</span>{' '}
              </span>
              <div className={classes.path}>
                {s.menuItem.rootName ? <span className={classes.rootName}>{s.menuItem.rootName}</span> : null}
                {s.menuItem.parentName ? (
                  <span className={classes.parentName}>
                    {' '}
                    {'>'} {s.menuItem.parentName}
                  </span>
                ) : null}
                {s.menuItem.heading ? (
                  <span className={classes.headName}>
                    {' '}
                    {'>'} {s.menuItem.heading}
                  </span>
                ) : null}
              </div>
            </li>
          );
        }}
        renderInput={(params) =>
          <Tooltip classes={{
            tooltip: classes.customTooltip,
            arrow: classes.customArrow

          }} title={'CTRL + S'} placement={"right"} arrow>
            <TextField {...params} label="Søk i Online" inputProps={{ ...params.inputProps, autoComplete: 'off' }} />
          </Tooltip>
        }
      />
      <IconButton className={classes.iconButton} aria-label="search" onClick={handleSearchClick}>
        <SearchIcon style={{ color: 'white' }} />
      </IconButton>
    </Paper>
  );
}
