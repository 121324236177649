import binIcon from '../assets/images/binIcon.svg';

const SVGIconBin = (props) => {
  return (
    <div>
      <img src={binIcon} width={props.width} alt="Vitec SVGIconBin " />
    </div>
  );
};

export default SVGIconBin;
