import { Theme } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import sizeMe, { SizeMe } from 'react-sizeme';
import { makeStyles } from 'tss-react/mui';
import C from '../../felles/UtilsCSS';
import MenuItemTarget from '../../models/MenuItemTarget';
import { AppState } from '../../store/rootReducer';
import LPContentDefault from './LPContentDefault';
import LPContentItem from './LPContentItem';
import * as actions from '../../store/actions/index';
import Utils from '../../felles/Utils';
import LPAppBarLogo from '../../components/LPAppBarLogo';
import LPAppBar from '../../components/LPAppBar';

//
const useStyles: any = makeStyles()((t: Theme) => ({
  contWrap: {
    marginTop: 50,
    position: 'relative',
    border: 'none',
    maxWidth: `calc(100vh  + ${600}px)`, 
    height: `calc(100vh  - ${90}px)`,
    [C.r(t, C.XXS, C.XXX)]: { marginTop: 60 },
    [C.r(t, C.XS, C.XSX)]: { marginTop: 20 },
    [C.r(t, C.SM, C.SMX)]: { marginTop: 20 },
    [C.r(t, C.MD, C.MDX)]: { marginTop: 20 },
    [C.r(t, C.LG, C.LGX)]: { marginTop: 20 },
    [C.r(t, C.XL, C.XLX)]: { marginTop: 30 },
    [C.r(t, C.XXL, C.YLX)]: { marginTop: 30 },
    
    [C.IPAD_10]: { marginTop: 10 },
    [C.SAMSUNG]: { marginTop: -10 }, 
  },
}));

function LPContent(props) {
  //
  const menuItemTarget: MenuItemTarget = useSelector((state) => (state as AppState).menuItem.menuItemTarget);
  const { classes } = useStyles();
  const dispatch = useDispatch();

  function handleScroll() {
    if (document.getElementById('xxx')) {
      const dod = document.getElementById('xxx')?.getBoundingClientRect();
      dispatch(actions.setScrollTop(dod?.y));
    }
  }

  //
  useEffect(() => {
    document.getElementById('xxx')?.addEventListener("wheel", Utils.debounce(() => { handleScroll(); }, 150));
    document.title = 'Vitec Autodata';
    return () => {
      document.getElementById('xxx')?.removeEventListener('wheel', Utils.debounce(() => { handleScroll(); }));
    };
  }, []);

  //
  const generateDOM = () => {
    switch (true) {
      case menuItemTarget.rootItem === '':
        return (<>
          <LPAppBarLogo /> {/*displayWelcome={true} */}
          <SizeMe>{({ size }) => <LPContentDefault size={size} />}</SizeMe>
        </>
        )
      default:
        return (
          <>
            <LPAppBar ismenuvisible={true} />
            <SizeMe>{({ size }) => <LPContentItem size={size} />}</SizeMe>
          </>
        );
    }
  };
  return <div className={classes.contWrap} id="xxx">{generateDOM()}</div>;
}
export default sizeMe()(LPContent);
