import fileDownload from 'js-file-download';
import { put } from 'redux-saga/effects';
import { APIgetLagerLabelByFirmId, APIgetNV03ExcelData, APIgetNV03ListItemWithVeil } from '../../felles/APICalls_NV03';
import { NV03Firma } from '../../models/NV03Vare';
import PageImpl from '../../models/PageImpl';
import * as actions from '../actions';
import * as actionsNV03 from '../actions/indexNV03';

//
export function* GetNV03VALFItemListSaga(payload) {
  //
  try {
    yield put(actions.setIsloading(true));
    let response: any = undefined;
    let responseFirmLagerLAbel: any = undefined;
    //
    switch (true) {
      //
      case payload.formData.exportToExcel:
        response = yield APIgetNV03ExcelData(payload.formData);
        break;
      //
      default:
        response = yield APIgetNV03ListItemWithVeil(payload.formData)
        responseFirmLagerLAbel = yield APIgetLagerLabelByFirmId();
        break;
    }
    //
    if (responseFirmLagerLAbel && responseFirmLagerLAbel.data) {
      yield put(actionsNV03.NV03VALFLabelGetSuccess(responseFirmLagerLAbel.data as NV03Firma));
    }
    //
    if (response && response.data) {
      if (payload.formData.exportToExcel)
        yield fileDownload(response.data, 'NV03-list__' + new Date().getTime() + '.xlsx');
      else {
        yield put(actionsNV03.NV03VALFListeGetSuccess(response.data as PageImpl));
      }
      yield put(actions.setIsloading(false));
    }
  } catch (error) {
    yield put(actionsNV03.NV03VALFListeGetFailed(error));
  } finally {
    yield put(actions.setIsloading(false));
  }
}
