import { CssBaseline } from '@mui/material';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import Utils from '../../felles/Utils';
import MenuItemTarget from '../../models/MenuItemTarget';
import UISize from '../../models/UISize';
import * as actions from '../../store/actions/index';
import { AppState } from '../../store/rootReducer';
import ProfilForm from './components/ProfilForm';
import LPDrawer from '../../components/LPDrawer';
import LPAppBar from '../../components/LPAppBar';


const useStyles = makeStyles()((theme: any) => ({
    root: { display: 'flex', height: '100vh', width: '100vw' },

    content: {
        marginTop: 25,
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -340,
    },
    contentShift: {
        marginTop: 25,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
})
);

export default function PageProfil() {
    const { classes } = useStyles();
    const dispatch = useDispatch();
    const screenSize: UISize = useSelector(state => (state as AppState).ui.screenSize);
    const open = screenSize.width > 0 && screenSize.width < 1200 ? false : true;

    const navigate = useNavigate();
    const n: MenuItemTarget = useSelector((state) => (state as AppState).menuItem.menuItemTarget);
    //const open: boolean = useSelector((state) => (state as AppState).ui.isDrawerOpened);
    const transError: any = useSelector((state) => (state as AppState).trans.error);
    useEffect(() => {



        dispatch(actions.setLocationPath(<>Brukeradministrasjon {'>'} Min profil</>));
        dispatch(actions.userProfileDataFetch());
    }, [dispatch]);
    //
    const handlePressedKey = (event) => {
        const { key } = event;
        if ((event.shiftKey && key === 'End') || key === 'F3') {
            dispatch(actions.menuItemSetTarget(new MenuItemTarget(n.rootItem, n.item !== '' ? n.item : n.rootItem, '', '', n.tilpasset, n.tilpassetBeta
                , n.kodeSynlig, n.labelSynlig, n.hasChildren, n.title)));
            dispatch(actions.menuItemSetSelected([n.rootItem, n.item + n.rootItem]));

            navigate(Utils.HOMEPAGE_CONTEXT + '/menu');
        }
    }

    const button: boolean = useSelector((state) => (state as AppState).ui.isSandwichButtonTurnedOn);

    return (
        <div className={classes.root} tabIndex={0} onKeyDown={(e) => handlePressedKey(e)}>
            <CssBaseline />
            <LPDrawer />
            <main className={clsx(classes.content, { [classes.contentShift]: button ? true : open })}>
                <LPAppBar ismenuvisible={true} />
                <ProfilForm />
            </main>
        </div>
    );
}
