import { Box } from "@mui/material";
import ButtonVitec from "../../../../components/ButtonVitec";

interface PaginationControlsProps {
  hasNextPage: boolean;
  page: number;
  handleLoadMoreNext: () => void;
  handleLoadMorePrevious: () => void;
  isFirstSearch: boolean;
}

export const PaginationControls: React.FC<PaginationControlsProps> = ({
  hasNextPage,
  page,
  handleLoadMoreNext,
  handleLoadMorePrevious,
  isFirstSearch,
}) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
      {/* Forrige side Button */}
      {!isFirstSearch && page > 1 && (
        <Box>
          <ButtonVitec
            _type="button"
            _label="Forrige side"
            _onclick={handleLoadMorePrevious}
            _fontsize=".7em"
            _width={110}
            _height={40}
            _padding={0}
            _margin={0}
          />
        </Box>
      )}

      {/* Spacer to push "Neste side" to the right */}
      <Box flexGrow={1} />

      {/* Neste side Button */}
      {hasNextPage && (
        <Box>
          <ButtonVitec
            _type="button"
            _label="Neste side"
            _onclick={handleLoadMoreNext}
            _fontsize=".8em"
            _width={110}
            _height={40}
            _padding={2}
            _margin={0}
          />
        </Box>
      )}
    </Box>
  );
};
