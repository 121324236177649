
import { useDispatch } from 'react-redux';
import * as actions from '../store/actions/index';

export default function ShiftHome(/*props*/) {
    const dispatch = useDispatch();
    //
    function handlePressedKey(event): void {
        const { key } = event;
        // console.log('shift home ' + key);
        switch (true) {
            case event.shiftKey && key === 'Home':
                event.preventDefault();
                dispatch(actions.setSearchInputFocused(true));
                break;
            default:
                break;
        }
    }
    //
    return <div tabIndex={0} onKeyUp={(e) => handlePressedKey(e)} />
}