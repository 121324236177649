import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../store/actions/index';
import { AppState } from '../../store/rootReducer';
import SupportFormData from '../../models/SupportFormData';
import UserUpdatePayload from '../../models/UserUpdatePayload';
import SimpleMSG from '../../models/SimpleMSG';
import { createRef, useEffect } from 'react';



const useStyles:any = makeStyles()(() =>
    ({
  root: {
    width: '100vw', marginLeft: 40, marginRight: 40, paddingTop: 30,
  },
  button: {
    backgroundColor: '#fff', padding: 4, fontSize: '1.4em',
    margin: '20px', height: '40px', width: '150px', border: '1px solid black',
    '&:hover': { cursor: 'pointer', backgroundColor: "#f6f6f6", },
    '&:active': { cursor: 'pointer', backgroundColor: "#000", color: '#f6f6f6' }
  },
  title: { fontSize: '2em', margin: '10px', },
  from: { fontSize: '1.2em', margin: '10px', },
  myInput: { width: 700, padding: '12px', margin: '10px', border: '1px solid #ccc', },
  myButton: { width: 300, padding: '12px', border: '1px solid #ccc', },
  myForm: { padding: '12px' }

}),
);


const ErrorHandler = (props) => {
  //
  props.error;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const userData: UserUpdatePayload = useSelector(state => (state as AppState).profile.userProfileData);
  const error: any = useSelector(state => (state as AppState).error.error);
  const errorInfo: string = useSelector(state => (state as AppState).error.errorInfo);
  const transError: SimpleMSG = useSelector(state => (state as AppState).trans.error);
  const formRef: any = createRef();

  //
  useEffect(() => {
    if (formRef.current) {
      formRef.current.elements.description.value = transError?.description || '';
      formRef.current.elements.subject.value = transError?.title || '';
    }

    if (userData.username === '')
      dispatch(actions.userProfileDataFetch());

  }, [formRef, dispatch, error, errorInfo, userData, transError])

  //
  const onSubmit = (e) => {
    e.preventDefault();
    const body = new FormData();
    // eslint-disable-next-line
    let fileName = '';
    // eslint-disable-next-line
    let subject = '';
    // eslint-disable-next-line
    let from = '';
    // eslint-disable-next-line
    let description = '';
    const data = Array.prototype.filter.call(e.target.elements,
      (input) => {
        switch (input.id) {
          case 'file':
            fileName = input.value;
            break;
          case 'from':
            from = input.value;
            break;
          case 'subject':
            subject = input.value;
            break;
          case 'description':
            description = input.value;
            break;
          default:
            break;
        }

        if (input.nodeName === 'BUTTON') return false;
        return true;
      });
    //
    Array.prototype.map.call(data, (input) => {
      input.id !== 'file' ? body.append(input.id, input.value) : body.append('file', input.files[0]);

    });
    if (subject !== '' && from !== '')
      dispatch(actions.sendFeedbackToSupportPost(new SupportFormData(from, subject, description, fileName, body)));
  }

  return (
    <div className={classes.root}>



      <div style={{ width: '40vw', margin: 'auto', textAlign: 'left' }}>
        <div style={{ marginLeft: '15px', fontSize: '1.5em', display: 'flex', textAlign: 'center' }}> Oops... Noe gikk galt! Beklager!</div>
        <form ref={formRef} onSubmit={onSubmit} encType="multipart/form-data" className={classes.myForm}>
          <div className={classes.from}>{userData.email}</div>
          <input className={classes.myInput} type="hidden" id="from" readOnly value={userData.email} />
          <input className={classes.myInput} type="text" id="subject" placeholder="Subject..." />
          <textarea className={classes.myInput} id="description" rows={10}  >
          </textarea>
          <input className={classes.myInput} type="file" id="file" accept=".txt" />
          <button className={classes.myInput} type="submit">Submit</button>< br />
          {/*<Link target={'self'} to={Utils.HOMEPAGE_CONTEXT + '/menu'}>Tilbake til Meny</Link>*/}
        </form>
      </div>
    </div>
  )

}


export default ErrorHandler;

