import { Theme, alpha } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import C from '../felles/UtilsCSS';
import MenuEl from '../models/MenuEl';
import MenuItemTarget from '../models/MenuItemTarget';
import UISize from '../models/UISize';
import * as actions from '../store/actions/index';
import { AppState } from '../store/rootReducer';

//
const useStyles = makeStyles()((theme: Theme) =>
({
  base: {
    fontFamily: '"Inter", sans-serif', fontWeight: 'normal', fontSize: '1.1em', marginLeft: 0, marginTop: 0, color: '#fff',
    [C.r(theme, C.XXS, C.XXX)]: { fontSize: '1.1em', },
    [C.r(theme, C.XS, C.XSX)]: { fontSize: '1.1em', },
    [C.r(theme, C.SM, C.SMX)]: { fontSize: '1.2em', },
    [C.r(theme, C.MD, C.MDX)]: { fontSize: '1.3em', },
    [C.r(theme, C.LG, C.LGX)]: { fontSize: '1.4em', },
    [C.r(theme, C.XL, C.XLX)]: { fontSize: '1.5em', },
    [C.r(theme, C.XXL, C.YLX)]: { fontSize: '1.6em', },
    [C.IPAD_10]: { color: '#000', fontSize: '1em', marginTop: 15, },
    [C.SAMSUNG]: { color: '#000', fontSize: '1em', marginTop: 15, },
  },
  pathItem: { display: 'flex', columnGap: '6px' },
  visible: {
    visibility: 'visible',
    opacity: '1',
    transition: 'opacity .3s linear', color: '#201f1f', padding: '3px 1px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  hidden: {
    visibility: 'hidden',
    opacity: '0',
    transition: 'visibility 0s .1s, opacity .1s linear', backgroundColor: alpha('#2E2D2C', 1), borderRadius: '5px', color: '#f6f6f6', padding: '3px 17px',
  },
})
);

//
export default function LPLocationPath(/*props*/) {
  const { classes } = useStyles();
  const mIT: MenuItemTarget = useSelector((state) => (state as AppState).menuItem.menuItemTarget);
  const termID: string = useSelector((state) => (state as AppState).trans.termId);
  const menuItems: MenuEl[] = useSelector((state) => (state as AppState).menuItem.menuItems);
  const isDebugEnabled: boolean = useSelector((state) => (state as AppState).utils.isDebugEnabled);
  const currentBreakpoint: string = useSelector((state) => (state as AppState).ui.currentBreakpoint);
  const scrollTop: number = useSelector((state) => (state as AppState).ui.scrollTop);
  const selected: string = useSelector((state) => (state as AppState).menuItem.selected);
  const dispatch = useDispatch();
  const screenSize: UISize = useSelector((state) => (state as AppState).ui.screenSize);
  const locationPath: string = useSelector((state) => (state as AppState).ui.locationPath);

  //
  useEffect(() => {
    // Traversing meny item list for new opened tab or windows
    // setups the location and selected menu
    if (mIT.code !== '' && mIT.code !== document.title.substring(0, 4)) {
      if (mIT.rootItem === '') {
        menuItems.forEach((A: MenuEl) => {
          A.nodes.forEach((B: MenuEl) => {
            B.nodes.forEach((C: MenuEl) => {
              C.nodes.forEach((D: MenuEl) => {
                if (mIT.code === D.code) {
                  mIT.rootItem = D.rootName;
                  mIT.item = D.parentName;
                  mIT.subitem = D.name;
                  mIT.title = D.description;
                  dispatch(actions.menuItemSetExpanded([D.rootName]));
                  dispatch(actions.menuItemSetSelected([D.rootName, D.parentName + D.rootName]));
                  document.title = mIT.code + ' - ' + mIT.title;
                }
              });
            });
          });
        });
      } else {
        document.title = mIT.code + ' - ' + mIT.title;
      }
    }
  }, [dispatch, termID, mIT, selected, screenSize, locationPath]);

  //
  return (
    <div>
      {
        <div key="locatA" className={classes.base}>
          <div key="locatB" className={classes.pathItem}>{isDebugEnabled ? currentBreakpoint + ' | ' : null}
            <div className={(scrollTop > 20 || scrollTop === 0) ? classes.visible : classes.hidden}>{locationPath}</div>
          </div>
        </div>
      }
    </div>
  );
}
