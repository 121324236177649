import { Backdrop, CircularProgress, Dialog, DialogContentText, DialogTitle, Typography } from '@mui/material';
import classNames from 'classnames';
import clsx from 'clsx';
import { createRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import ButtonVitec from '../../../components/ButtonVitec';
import Utils from '../../../felles/Utils';
import UserUpdatePayload from '../../../models/UserUpdatePayload';
import * as actions from '../../../store/actions/index';
import { AppState } from '../../../store/rootReducer';


const drawerWidth = 340;

const useStyles = makeStyles()((theme: any) => ({
  root: { width: '100%', marginTop: -40, paddingRight: theme.spacing(1), },
  formWrapper: { width: '100vw', display: 'table-cell', verticalAlign: 'middle', height: '93vh', },
  formAdd: {
    boxShadow: '3px 3px 3px rgba(0, 0, 0, 0.2)',
    horizontalAlign: 'center',
    verticalAlign: 'center',
    backgroundColor: '#f3f3f3',
    width: 630,
    margin: '0 auto',
    padding: 30,
    paddingTop: 15,
    paddingBottom: 5,
    border: '1px solid #899499',
    borderRadius: '5px',
  },
  drawerHeaderContent: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    marginTop: -10,
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  myInputWrapper: { width: '100%', },
  err: { color: 'black', fontSize: '.9em', },
  myTextArea: { width: '100%', padding: '10px', marginBottom: '14px', marginTop: '3px', border: 'none', backgroundColor: '#f6f6f6' },
  myInput: { width: '100%', padding: '10px', marginBottom: '14px', marginTop: '3px', border: '1px solid #ccc' },
  myInputShort: { width: '60px', padding: '10px', marginBottom: '14px', marginTop: '3px', border: '1px solid #ccc' },
  myLabel: { width: 600, fontSize: '1.2em' },
  myTitle: { width: 600, fontSize: '1.6em', marginBottom: '15px', color: 'black' },
  backdrop: { zIndex: theme.zIndex.drawer + 100, color: '#fff' },
  myButtons: { display: 'inline-flex', verticalAlign: 'top' },
  myButton: { padding: '10px', margin: '0px 10px 0px 10px' },
  dialogCustomizedWidth: { padding: '20px 0px 40px 0px', 'min-width': '400px', 'max-width': '400px', 'min-height': '150px' },

  '@media only screen and (min-width: 1024px) and (max-height: 1366px)  and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 1.5)': {
    root: { marginTop: 0 },
    formAdd: { width: 430 },
    myTextArea: { padding: 0, marginBottom: 0, marginTop: 4 },
    myInput: { width: '100%', padding: '5px', marginBottom: '7px', marginTop: '3px' },
    myInputShort: { width: '60px', padding: '5px', marginBottom: '7px', marginTop: '3px' },
    myLabel: { width: 400, fontSize: '1em' },
    myTitle: { width: 400, fontSize: '1.2em', marginBottom: '7px' },
    myButton: { margin: 0 },
  },
})
);
const ProfilForm = (/*props*/) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const username: string = useSelector((state) => (state as AppState).profile.userProfileData.username);
  const userJWT = useSelector((state) => (state as AppState).auth.userJWT);
  const firstname: string = useSelector((state) => (state as AppState).profile.userProfileData.firstname);
  const lastname: string = useSelector((state) => (state as AppState).profile.userProfileData.lastname);
  const email: string = useSelector((state) => (state as AppState).profile.userProfileData.email);
  const emailConfirmation: string = useSelector((state) => (state as AppState).profile.userProfileData.emailConfirmation);
  const mobile: string = useSelector((state) => (state as AppState).profile.userProfileData.mobile);
  const feedbackMsg: string = useSelector((state) => (state as AppState).profile.error);
  const open: boolean = useSelector((state) => (state as AppState).ui.isDrawerOpened);
  const isTransactionInProgress: boolean = useSelector((state) => (state as AppState).profile.isFetchingUserProfileData);
  const isDialogOpen: boolean = useSelector((state) => (state as AppState).profile.isUserProfileDataDialogueOpen);
  const navigate = useNavigate();
  const formRef: any = createRef();

  const { register, getValues, setValue, handleSubmit, formState: { errors }, } = useForm({
    defaultValues: {
      firstname: firstname,
      lastname: lastname,
      email: email,
      emailConfirmation: emailConfirmation,
      mobile: mobile,

    },
  })

  useEffect(() => {
    setValue('firstname', firstname);
    setValue('lastname', lastname);
    setValue('email', email);
    setValue('emailConfirmation', emailConfirmation);
    setValue('mobile', mobile);
  }, [setValue, firstname, lastname, email, emailConfirmation, mobile]);
  /*  */
  const grupper = () => {
    return (userJWT.roles as string)
      ?.replaceAll('ROLE_', '')
      ?.replaceAll(' ', '')
      ?.split(',')
      ?.sort()
      .toString()
      .replaceAll(',', ', ')
      .replaceAll(', ALLUSERS', '');
  };

  //
  const onSubmit = (data: any = undefined) => {
    dispatch(actions.userProfileDataUpdate(new UserUpdatePayload('', data.firstname, data.lastname, data.email, data.emailConfirmation, data.mobile)));
  };
  //
  const handleDiagClose = () => {
    dispatch(actions.userProfileDataDialogUpdated(false));
    navigate(Utils.HOMEPAGE_CONTEXT + '/menu');
  };
  //
  return (
    <>
      {' '}

      <div className={classes.root}>
        <main className={clsx(classes.content, { [classes.contentShift]: open })}>
          <div className={classes.drawerHeaderContent} />
          <div className={classes.formWrapper}>
            <Backdrop className={classes.backdrop} open={isTransactionInProgress}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
              open={isDialogOpen}
              fullWidth
              classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
              onClose={handleDiagClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title"> </DialogTitle>
              <Typography align="center" variant="subtitle1" component="div">
                <DialogContentText id="alert-dialog-description">{feedbackMsg}</DialogContentText>
                <div className={classes.myButtons}>
                  <div className={classes.myButton}>
                    <ButtonVitec _onclick={handleDiagClose} _width={80} _height={37} _type={'button'} _label={'Lukk'} />
                  </div>
                </div>
              </Typography>
            </Dialog>
            <form ref={formRef} className={classes.formAdd}>
              <div className={classes.myTitle}>Min profil {username}</div>
              <label htmlFor="firstname" className={classNames(classes.myLabel)}>
                Fornavn
              </label>
              <input
                className={classNames(classes.myInput)}
                type="text"
                id="firstname"
                {...register("firstname", { required: true })}
                onChange={(e) => {
                  setValue('firstname', (e.target as any).value);
                  dispatch(actions.userProfileMemberStateUpdate('firstname', (e.target as any).value));
                }}
              />
              <label htmlFor="lastname" className={classNames(classes.myLabel)}>
                Etternavn
              </label>
              <input
                className={classNames(classes.myInput)}
                type="text"
                id="lastname"

                {...register("lastname", { required: true })}
                onChange={(e) => {
                  setValue('lastname', (e.target as any).value);
                  dispatch(actions.userProfileMemberStateUpdate('lastname', (e.target as any).value));
                }}
              />
              <label htmlFor="emailA" className={classNames(classes.myLabel)}>
                E-post
              </label>
              <input
                className={classNames(classes.myInput)}
                type="email"
                id="email"

                {...register("email", { required: true })}

                onChange={(e) => {
                  setValue('email', (e.target as any).value);
                  dispatch(actions.userProfileMemberStateUpdate('email', (e.target as any).value));
                }}
              />
              <label htmlFor="emailB" className={classNames(classes.myLabel)}>
                Bekreft E-post
              </label>
              <input
                className={classNames(classes.myInput)}
                type="text"
                id="emailConfirmation"
                {...register("emailConfirmation", { validate: { emailEqual: (value) => value === getValues().email || 'Email confirmation error!' } })}

                onChange={(e) => {
                  setValue('emailConfirmation', (e.target as any).value);
                  dispatch(actions.userProfileMemberStateUpdate('emailConfirmation', (e.target as any).value));
                }}
              />
              <label htmlFor="mobile" className={classNames(classes.myLabel)}>
                Mobil
              </label>
              <input className={classNames(classes.myInput)} type="tel" id="mobile" {...register("mobile", { required: true })}
                onChange={(e) => {
                  setValue('mobile', (e.target as any).value);
                  dispatch(actions.userProfileMemberStateUpdate('mobile', (e.target as any).value));
                }}
              />
              <label htmlFor="mobile" className={classNames(classes.myLabel)}>Grupper</label>

              <textarea className={classNames(classes.myTextArea)} id="grupper" name="grupper" readOnly value={grupper() + ''} />

              <Typography align="center" variant="subtitle1" component="div">
                <div className={classes.myButtons}>
                  <div className={classes.myButton}>
                    <ButtonVitec _onclick={handleSubmit(onSubmit)} _width={180} _height={37} _type={'button'} _label={'Lagre'} />
                  </div>
                  <div className={classes.myButton}>
                    <ButtonVitec _onclick={handleDiagClose} _width={80} _height={37} _type={'button'} _label={'Avbryt'} />
                  </div>
                </div>
              </Typography>
              {errors.firstname && errors.firstname.type === 'required' ? <div>Vennligst skriv inn fornavnet ditt.</div> : <div>&nbsp;</div>}
              {errors.firstname && errors.firstname.type === 'maxLength' && <div> Maks lengde av fornavnet er 40 bokstaver.</div>}
              {errors.lastname && errors.lastname.type === 'required' && <div>Vennligst skriv inn fornavnet ditt.</div>}
              {errors.lastname && errors.lastname.type === 'maxLength' && <div> Maks lengde av fornavnet er 40 bokstaver.</div>}
              {errors.mobile && errors.mobile.type === 'required' && <div>Vennligst skriv inn mobilnummeret ditt.</div>}
              {errors.mobile && errors.mobile.type === 'maxLength' && <div> Maks lengde av mobilnummeret er 20 nummer.</div>}
            </form>
          </div>
        </main>
      </div>

    </>
  );
};

export default ProfilForm;