import Utils from "./Utils";


export default class Enums {

    static get APP_TITLE() {
        return 'Vitec Autodata Web';
    }

    // State
    static readonly DRAWER_WITH = 340;
    //
    static readonly uVisning = ['Kunde', 'Varegruppe', 'Hovedgruppe', 'Gruppe', 'Summeringsgruppe'];
    //
    static readonly kVisning = ['Varegruppe', 'Hovedgruppe', 'Gruppe', 'Summeringsgruppe'];

    //
    static readonly validKey: string[] = [
        'f1',
        'f2',
        'f3',
        'f4',
        'f5',
        'f6',
        'f7',
        'f8',
        'f9',
        'f10',
        'f11',
        Utils.IS_ENV_DEV(true) ? 'esc' : 'f12',
        'ctrl + shift + f1',
        'shift + f1',
        'shift + f2',
        'shift + f3',
        'shift + f4',
        'shift + f5',
        'shift + f6',
        'shift + f7',
        'shift + f8',
        'shift + f9',
        'shift + f10',
        'shift + f11',
        'shift + f12',
        'ctrl + s',
        'ctrl + f1',
        'ctrl + f2',
        'ctrl + f3',
        'enter',
        'tab',
        'arrowleft',
        'arrowright',
        'arrowdown',
        'arrowup',
        'shift + home',
        'shift + end',
    ];
    //
    static readonly programIncludeListWithF3MenyKeyEnabled: string[] = [
        'ATL3'
        , 'BES1'
        , 'ENMA'
        , 'EM01'
        , 'EM03'
        , 'ENLO'
        , 'ENVE'
        , 'ETTI'
        , 'KVAP'
        , 'KVA1'
        , 'KVA2'
        , 'LAFI'
        , 'LAG4'
        , 'LOC7'
        , 'LAVA'
        , 'OMLO'
        , 'ROR3'
        , 'SE01'
        , 'TIL1'
        , 'TIL2'
        , 'TIL3'
        , 'TIL5'
        , 'TIL6'
        , 'TIL7'
        , 'TIL8'
        , 'VE03'
        , 'VE04'
        , 'VETT'
        , 'VARN'
        , 'VASA'
        , 'VASK'
        , 'VARA'
        , 'VARE'
        , 'VAVE'
    ];
    //
    static readonly programExcludeListWithF3MenyKeyDisabled: string[] = ['BES1'];

    /*
    * Return a predefined url parameter
    *
    */
    static getInputParamfromKeyPressed = (key: string) => {
        switch (key) {
            // case 'f1':
            //   return '&DFH_PF1=PF1';
            case 'f2':
                return '&DFH_PF2=PF2';
            case 'f3':
                return '&DFH_PF3=PF3';
            case 'f4':
                return '&DFH_PF4=PF4';
            case 'f5':
                return '&DFH_PF5=PF5';
            case 'f6':
                return '&DFH_PF6=PF6';
            case 'f7':
                return '&DFH_PF7=PF7';
            case 'f8':
                return '&DFH_PF8=PF8';
            case 'f9':
                return '&DFH_PF9=PF9';
            case 'f10':
                return '&DFH_PF10=PF10';
            case 'f11':
                return '&DFH_PF11=PF11';
            case 'f12':
                return '&DFH_PF12=PF12';
            case 'shift + f1':
                return '&DFH_PF13=PF13';
            case 'shift + f2':
                return '&DFH_PF14=PF14';
            case 'shift + f3':
                return '&DFH_PF15=PF15';
            case 'shift + f4':
                return '&DFH_PF16=PF16';
            case 'shift + f5':
                return '&DFH_PF17=PF17';
            case 'shift + f6':
                return '&DFH_PF18=PF18';
            case 'shift + f7':
                return '&DFH_PF19=PF19';
            case 'shift + f8':
                return '&DFH_PF20=PF20';
            case 'shift + f9':
                return '&DFH_PF21=PF21';
            case 'shift + f10':
                return '&DFH_PF22=PF22';
            case 'shift + f11':
                return '&DFH_PF23=PF23';
            case 'shift + f12':
                return '&DFH_PF24=PF24';
            case 'enter':
                return '&DFH_ENTER=Enter';
            case 'clear':
                return ' &DFH_CLEAR=Clear';
            default:
                return '';
        }
    };

}



