import { Box } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export const YearOptions = ({ selectedYears, handleYearChange }) => (
    <Box sx={{ marginLeft:1, marginBottom: 2 }}>
      {Object.keys(selectedYears).map((year) => (
        <FormControlLabel
          key={year}
          control={
            <Checkbox
              checked={selectedYears[parseInt(year, 10)]}
              onChange={() => handleYearChange(parseInt(year, 10))}
            />
          }
          label={year}
        />
      ))}
    </Box>
  );
  