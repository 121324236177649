import ReactDOM from 'react-dom/client';
import App from './App';
import { createTheme } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@emotion/react';
import './assets/styles/styles.scss';
import configureStore from './store/_-_-_-_-__-_-_-_-__configureStore';
import * as actions from './store/actions/index';
// import 'normalize.css'; 
const store: any = configureStore();
const token = sessionStorage.getItem('token');




/*
const theme = createTheme({
  palette: {
    primary: {
      main: "#00F"
    }
  },
  typography: {
    body1: {
       color: 'white',
    }
  },

  
  components: {
    MuiToolbar: {
       
      styleOverrides: {
       
        // Name of the slot
        root: {
          border:'3px solid red',
          backgroundColor:'BLACK',
          color: 'white',
          fontSize: '1rem',
        },
      },
    },
    
    MuiCheckbox: {
      
      styleOverrides: {
        // Name of the slot
        root: {
          border:'3px solid red',
          backgroundColor:'BLACK',
          // Some CSS
          fontFamily: "Comic Sans", color: 'white',
          fontSize: '1rem',
        },
      },
    },
    
    MuiInputBase: {
      styleOverrides: {
        input: {
          // Some CSS
          fontFamily: "Comic Sans", color: 'white',
          fontSize: '1rem',
        },
        // Name of the slot
        root: {
          border:'1px solid #f6f6f6',
          // Some CSS
          fontFamily: "Comic Sans", color: 'white',
          fontSize: '1rem',
        },
      },
    },

    MuiOutlinedInput: {
       
      styleOverrides: {
        input: {
          
          // Some CSS
        
          fontSize: '1rem',
        },
        // Name of the slot
        root: {
          
          // Some CSS
          fontFamily: "Comic Sans", color: 'white',
          fontSize: '1rem',
        },
      },
    },
//MuiInputBase-input MuiOutlinedInput-input
  



    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: '1rem',
        },
      },
    },
    
    MuiButtonBase: {
      
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple, on the whole application 💣!
        
      },
      
    },
  },
 
});
*/

const theme = createTheme();
//
if (token) {
  store.dispatch(actions.updateAutodataUserAuth(true));
  store.dispatch(actions.updateAutodataUserJWT(token));
  store.dispatch(actions.userAutologinToAutodataAuthGet(token, '', true, 0/*, 0*/)); // No transID | silent login | stateLogout
}
//
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
//
root.render(
  
    <Provider store={store}>
      <App />
    </Provider>
 
);