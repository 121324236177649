import { Theme } from '@mui/material';
import { createRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Utils from '../felles/Utils';
import C from '../felles/UtilsCSS';
import * as actions from '../store/actions/index';
import { AppState } from '../store/rootReducer';

const useStyles: any = makeStyles()((theme: Theme) =>
({
  contextMenu: {
    position: 'fixed', background: 'white', border: '1px solid #ccc', boxShadow: '0px 5px 10px #999999', with: 'auto', minWidth: 250, zIndex: 3000,
  },
  /**/ contextMenuOption: {
    minHeight: 40, background: 'white', paddingTop: 10, paddingLeft: 10, borderBottom: '1px solid #ccc', minWidth: '160px', cursor: 'default',
    '&:last-child': { border: 'none', },
    '&:hover': { background: ' linear-gradient(to top, #171b3d, #333)', color: 'white', cursor: 'pointer', },
    '&:active': { color: '#e9e9e9', background: 'linear-gradient(to top, #555, #444)', },
    ' &__disabled': { color: '#999999', pointerEvents: 'none', },
  },

  [C.IPAD_10]: {
    base: {},
  },

  [C.SAMSUNG]: {
    base: {},
  },


  [C.r(theme, C.XXS, C.XXX)]: {},
  [C.r(theme, C.XS, C.XSX)]: {},
  [C.r(theme, C.SM, C.SMX)]: {},
  [C.r(theme, C.MD, C.MDX)]: {},
  [C.r(theme, C.LG, C.LGX)]: {},
  [C.r(theme, C.XL, C.XLX)]: {},
  [C.r(theme, C.XXL, C.YLX)]: {},


}));

const RightClickMenu = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const contextMenuVisible = useSelector((state: AppState) => state.ui.contextMenuVisible);
  const contextMenuX = useSelector((state: AppState) => state.ui.contextMenuX);
  const contextMenuY = useSelector((state: AppState) => state.ui.contextMenuY);
  const transidRedirect = useSelector((state: AppState) => state.ui.transidRedirect);
  const ctxMeny: any = createRef();

  useEffect(() => {
    document.addEventListener('contextmenu', _handleContextMenu);
    document.addEventListener('click', _handleClick);
    return () => {
      document.removeEventListener(
        'contextmenu',
        Utils.debounce(function () {
          _handleContextMenu;
        })
      );
      document.removeEventListener(
        'click',
        Utils.debounce(function () {
          _handleClick;
        })
      );
    };
  }, [dispatch, contextMenuVisible]);

  //
  const _handleContextMenu = (event) => {
    event.preventDefault();
  };

  //
  const _handleClick = (event) => {
    const wasOutside = !(event.target.contains === ctxMeny);
    if (wasOutside && contextMenuVisible) {
      dispatch(actions.setContextMenuVisible(false));
    }
  };
  //
  const _mouseClickEventHanmdler = (event, url: string, windowed = false) => {
    event.preventDefault();
    //const childWindow = 
    !windowed ? window.open(url, '_blank') : window.open(url, '_blank', 'location=no,status=no');
    // childWindow?.sessionStorage.clear();
    // childWindow?.location.reload();
  };
  return (
    (contextMenuVisible || null) && (
      <div style={{ left: contextMenuX, top: contextMenuY }} ref={ctxMeny} className={classes.contextMenu}>
        <div
          className={classes.contextMenuOption}
          onClick={(e) => _mouseClickEventHanmdler(e, Utils.HOMEPAGE_CONTEXT + '/vse?new=1&transid=' + transidRedirect)}
        >
          Åpne <strong>{transidRedirect}</strong> i en ny fane.
        </div>
        <div
          className={classes.contextMenuOption}
          onClick={(e) => _mouseClickEventHanmdler(e, Utils.HOMEPAGE_CONTEXT + '/vse?new=1&transid=' + transidRedirect, true)}
        >
          Åpne <strong>{transidRedirect}</strong> i et nytt vindu.
        </div>
      </div>
    )
  );
};



export default RightClickMenu;
