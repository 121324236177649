import jwtDecode from "jwt-decode";
export default class CSharpUtils {
  static get T_companyID() {
    const ctoken = sessionStorage.getItem("ctoken");
    return ctoken
      ? (jwtDecode(ctoken || "") as any).Department.toString()
      : undefined;
  }
  static get Get_Token() {
    const ctoken = sessionStorage.getItem("ctoken");
    return ctoken
      
  }
  static get DOTNET_BASE_URL() {
    return process.env.REACT_APP_C_BASE_URL;
  }
}
export const validateKundenummer = (kundenummer: string): boolean => {
  const kundenummerPattern = /^\d{6}$/;
  return kundenummerPattern.test(kundenummer);
};

export const validateAlfa = (alfa: string): boolean => {
  // Minimum 3 alphabetic characters and can contain digits
  const hasMinThreeAlpha = /[a-zA-Z].*[a-zA-Z]/;
  return hasMinThreeAlpha.test(alfa);
};

export const validateArtikkle = (artikkle: string): boolean => {
  // Maximum 14 characters and can contain both digits and letters
  const artikklePattern = /^[a-zA-Z0-9]{1,14}$/;
  return artikklePattern.test(artikkle);
};

export const validateVaregruppe = (varegruppe: string): boolean => {
  // Must be exactly 5 characters and can only contain digits or Norwegian alphabetic characters (A-Z, a-z, Æ, Ø, Å, æ, ø, å)
  const varegruppePattern = /^[0-9a-zA-ZÆØÅæøå]{5}$/;
  return varegruppePattern.test(varegruppe);
};

