import U from '../../felles/Utils';
import HjelpFormData from '../../models/HjelpFormData';
import HjelpPostFormData from '../../models/HjelpPostFormData';
import MenuItemTarget from '../../models/MenuItemTarget';
import NavFormData from '../../models/NavFormData';
import SimpleMSG from '../../models/SimpleMSG';
import * as actionTypes from '../actions/actionTypes';
import * as fromApp from '../rootReducer';

export interface FeatureState extends fromApp.AppState {
  state: StateTrans;
}

export interface StateTrans {
  termId: string;
  progH: string;
  fieldH: string;
  searchH: string;
  omvFormClickedBtn: string;
  transTarget: MenuItemTarget;
  isTransGetInProgress: boolean;
  isTransPostInProgress: boolean;
  error: SimpleMSG;
  content: any;
  contentOMW: any;
  helpContent: any;
  isTransDialogueOpen: boolean;
  pressedKey: string;
  wsMsg: any;
  keyBindingFieldInputStateObj: any;
  inputArrayState: any[];
  inputArrayLineState: any[];
  isInputStateInitialized: boolean;
  fieldToAttachFocus: string;
  currentFieldwithFocus: any;
  defaultFieldToAttachFocus: string;
  isFieldSelectEnabled: boolean;
  isVSEHelpDialogOpen: boolean;
  isEditHelpDialogOpen: boolean;
  helpText: any;
  helpParams: HjelpFormData;
  helpPostParams: HjelpPostFormData;
  istransVSEHelpFetchInProgress: boolean;
  isTransAPIHelpFetchInProgress: boolean;
  isTransAPIHelpPostFetchInProgress: boolean;
  helpVSEInitialized: boolean;
  showHelpContent: boolean;
  showOpenModalWindow: boolean;
  lastestFormFata: NavFormData;
}

const initialState = {
  termId: '',
  progH: '',
  fieldH: '',
  searchH: '',
  omvFormClickedBtn: '',
  transTarget: new MenuItemTarget(''),
  isTransGetInProgress: false,
  isTransPostInProgress: false,
  error: new SimpleMSG('', ''),
  content: undefined,
  contentOMW: undefined,
  helpContent: undefined,
  isTransDialogueOpen: false,
  pressedKey: '',
  wsMsg: undefined,
  keyBindingFieldInputStateObj: {},
  inputArrayState: [],
  inputArrayLineState: [],
  isInputStateInitialized: false,
  fieldToAttachFocus: '',
  currentFieldwithFocus: undefined,
  defaultFieldToAttachFocus: '',
  isFieldSelectEnabled: false,
  isVSEHelpDialogOpen: false,
  isEditHelpDialogOpen: false,
  helpText: undefined,
  helpParams: new HjelpFormData(),
  helpPostParams: new HjelpPostFormData(),
  istransVSEHelpFetchInProgress: false,
  isTransAPIHelpFetchInProgress: false,
  isTransAPIHelpPostFetchInProgress: false,
  helpVSEInitialized: false,
  showHelpContent: false,
  showOpenModalWindow: false,
  lastestFormFata: new NavFormData(),
};


const setFormDataForBugReport = (state: StateTrans, action: any) => {
  return U.updateStore(state, { lastestFormFata: action.payload });
};


const setIsTransGetInProgress = (state: StateTrans, action: any) => {
  return U.updateStore(state, { isTransGetInProgress: action.payload });
};

const setIsTransPostInProgress = (state: StateTrans, action: any) => {
  return U.updateStore(state, { isTransPostInProgress: action.payload });
};

const transGetInit = (state: StateTrans) => {
  return U.updateStore(state, { isTransGetInProgress: true });
};


const transGet = (state: StateTrans, action: any) => {
  return U.updateStore(state, { isTransGetInProgress: true, termId: '', transTarget: action.payload });
};

const transGetSuccess = (state: StateTrans, action: any) => {
  return U.updateStore(state, { isTransGetInProgress: false, content: action.payload, termId: action.termId });
};


const transGetFailed = (state: StateTrans, payload: SimpleMSG) => {
  return U.updateStore(state, { isTransDialogueOpen: true, error: payload });
};

//
const transFormDataPostInit = (state: StateTrans) => {
  return U.updateStore(state, { isTransPostInProgress: true });
};

const transFormDataPost = (state: StateTrans, action: any) => {
  return U.updateStore(state, { isTransPostInProgress: true, transTarget: action.payload });
};

const transFormDataPostSuccess = (state: StateTrans, action: any) => {
  return U.updateStore(state, { isTransPostInProgress: false, content: action.payload });
};

const transFormDataPostFailed = (state: StateTrans, payload: SimpleMSG) => {
  return U.updateStore(state, { isTransPostInProgress: false, isTransDialogueOpen: true, error: payload });
};

// HELP
const transHelpGetInit = (state: StateTrans) => {
  return U.updateStore(state, { istransVSEHelpFetchInProgress: true });
};
const transHelpGet = (state: StateTrans, action: any) => {
  return U.updateStore(state, { progH: action.progH, fieldH: action.fieldH });
};
const transHelpGetSuccess = (state: StateTrans, action: any) => {
  return U.updateStore(state, { istransVSEHelpFetchInProgress: false, helpVSEInitialized: true, helpContent: action.payload });
};
const transHelpGetFailed = (state: StateTrans, payload: SimpleMSG) => {
  return U.updateStore(state, { istransVSEHelpFetchInProgress: false, error: payload });
};


//////////////////////////////////////////////////////////////////////////////////////////////////////
// OPEN MODAL WINDOW //
//////////////////////////////////////////////////////////////////////////////////////////////////////
const transOpenModalWindowPostInit = (state: StateTrans) => {
  return U.updateStore(state, { /*keyBindingFieldInputStateObj: new Object, */ showOpenModalWindow: true,/* isTransWSInProgress: true*/ });
};

const transOpenModalWindowPost = (state: StateTrans, action: any) => {
  return U.updateStore(state, { /* keyBindingFieldInputStateObj: new Object,*/ /*isTransWSInProgress: true,*/ transTarget: action.payload });
};

const transOpenModalWindowPostSuccess = (state: StateTrans, action: any) => {
  return U.updateStore(state, { /*isTransWSInProgress: false, */contentOMW: action.payload });
};

const transOpenModalWindowPostFailed = (state: StateTrans, payload: SimpleMSG) => {
  return U.updateStore(state, { showOpenModalWindow: true, /*isTransWSInProgress: false,*/ isTransDialogueOpen: true, error: payload });
};

/////////////////////////////////////////////////////////////////////////////////////////////////////
const updatePressedKey = (state: StateTrans, action: any) => {
  return U.updateStore(state, { pressedKey: action.payload });
};

const transErrorDialogUpdated = (state: StateTrans, action: any) => {
  return U.updateStore(state, { isTransDialogueOpen: action.payload });
};

const setTermID = (state: StateTrans, action: any) => {
  return U.updateStore(state, { termId: action.payload });
};

const updateInputState = (state: StateTrans, action: any) => {
  return U.updateStore(state, { keyBindingFieldInputStateObj: { ...state.keyBindingFieldInputStateObj, [action.inputName]: action.value } });
};

const updateInputArrayState = (state: StateTrans, action: any) => {
  initialState.inputArrayState = [];
  return U.updateStore(state, { inputArrayState: [...action.payload] });
};

const updateInputArrayLineState = (state: StateTrans, action: any) => {
  initialState.inputArrayLineState = [];
  return U.updateStore(state, { inputArrayLineState: [...action.payload] });
};

const setInputState = (state: StateTrans, action: any) => {
  return U.updateStore(state, { isInputStateInitialized: action.payload ? true : false, keyBindingFieldInputStateObj: { ...action.payload } });
};

const setFieldToAttachFocus = (state: StateTrans, action: any) => {
  return U.updateStore(state, { fieldToAttachFocus: action.payload });
};

const setCurrentFieldwithFocus = (state: StateTrans, action: any) => {
  return U.updateStore(state, { currentFieldwithFocus: action.payload });
};

const setDefaultFieldFocus = (state: StateTrans, action: any) => {
  return U.updateStore(state, { defaultFieldToAttachFocus: action.payload });
};

const setFieldSelectEnabled = (state: StateTrans, action: any) => {
  return U.updateStore(state, { isFieldSelectEnabled: action.payload });
};
/*
const setVSEHelpDialog = (state: StateTrans, action: any) => {
  return U.updateStore(state, { isVSEHelpDialogOpen: action.payload });
};
*/
const setHelpText = (state: StateTrans, action: any) => {
  return U.updateStore(state, { helpText: action.payload });
};

const setShowHelpContent = (state: StateTrans, action: any) => {
  return U.updateStore(state, { showHelpContent: action.payload });
};

const setShowOpenModalWindow = (state: StateTrans, action: any) => {
  return U.updateStore(state, { showOpenModalWindow: action.payload });
};

const setHelpParams = (state: StateTrans, action: any) => {
  return U.updateStore(state, { helpParams: action.payload });
};
/*
const setEditHelpDialogOpenState = (state: StateTrans, action: any) => {
  return U.updateStore(state, { isEditHelpDialogOpen: action.payload });
};

const setEditHelpParams = (state: StateTrans, action: any) => {
  return U.updateStore(state, { helpPostParams: action.payload });
};
*/
// Re-initialyze Clear
export const resetHTMLContent = (state: StateTrans) => {
  return U.updateStore(state, { inputArrayState: [], keyBindingFieldInputStateObj: {}, content: undefined /**/ });
};

// Re-initialyze Clear
export const resetHTMLHelpContent = (state: StateTrans) => {
  return U.updateStore(state, { helpContent: undefined /**/ });
};

// Re-initialyze keyBindingFieldInputStateObj
export const resetKeyInputFieldBinding = (state: StateTrans) => {
  return U.updateStore(state, { keyBindingFieldInputStateObj: {} });
};

const setOmvClickedBtn = (state: StateTrans, action: any) => {
  return U.updateStore(state, { omvFormClickedBtn: action.payload });
};

const transReducer = (state = initialState, action: any = '') => {
  switch (action.type) {

    // case actionTypes.SET_WEBSOCKET_ACTIVE:
    //  return setWebSocketActive(state, action);
    /**/
    case actionTypes.TRANS_GET_INIT:
      return transGetInit(state);
    case actionTypes.TRANS_GET:
      return transGet(state, action);
    case actionTypes.TRANS_GET_SUCCESS:
      return transGetSuccess(state, action);

    case actionTypes.TRANS_GET_FAILED:
      return transGetFailed(state, action.error);
    //help
    case actionTypes.TRANS_HELP_GET_INIT:
      return transHelpGetInit(state);
    case actionTypes.TRANS_HELP_GET:
      return transHelpGet(state, action);
    case actionTypes.TRANS_HELP_GET_SUCCESS:
      return transHelpGetSuccess(state, action);
    case actionTypes.TRANS_HELP_GET_FAILED:
      return transHelpGetFailed(state, action.error);
    /**/
    case actionTypes.TRANS_FORMDATA_POST_INIT:
      return transFormDataPostInit(state);
    case actionTypes.TRANS_FORMDATA_POST:
      return transFormDataPost(state, action);
    case actionTypes.TRANS_FORMDATA_POST_SUCCESS:
      return transFormDataPostSuccess(state, action);
    case actionTypes.TRANS_FORMDATA_POST_FAILED:
      return transFormDataPostFailed(state, action.error);
    /**/
    case actionTypes.TRANS_OMW_FORMDATA_POST_INIT:
      return transOpenModalWindowPostInit(state);
    case actionTypes.TRANS_OMW_POST_FORMDATA_INIT:
      return transOpenModalWindowPost(state, action);
    case actionTypes.TRANS_OMW_POST_FORMDATA_SUCCESS:
      return transOpenModalWindowPostSuccess(state, action);
    case actionTypes.TRANS_OMW_POST_FORMDATA_FAILED:
      return transOpenModalWindowPostFailed(state, action);
    /**/
    case actionTypes.UPDATE_PRESSED_KEY:
      return updatePressedKey(state, action);
    case actionTypes.UPDATE_DIALOG_STATE:
      return transErrorDialogUpdated(state, action);
    // case actionTypes.TRANS_WEBSOCKET_GET:
    //   return transWebsocketGet(state, action);
    case actionTypes.TRANS_TARGET_UPDATED:
      return setTermID(state, action);
    case actionTypes.TRANS_UPDATE_INPUT_STATE:
      return updateInputState(state, action);
    case actionTypes.TRANS_UPDATE_INPUT_ARRAY_STATE:
      return updateInputArrayState(state, action);
    case actionTypes.TRANS_UPDATE_INPUT_ARRAY_LINE_STATE:
      return updateInputArrayLineState(state, action);
    case actionTypes.TRANS_SET_INPUT_STATE:
      return setInputState(state, action);
    case actionTypes.TRANS_SET_FOCUSED_FIELD:
      return setFieldToAttachFocus(state, action);
    case actionTypes.TRANS_SET_CURRENT_FOCUSED_FIELD:
      return setCurrentFieldwithFocus(state, action);
    case actionTypes.TRANS_DEFAULT_SET_FOCUSED_FIELD:
      return setDefaultFieldFocus(state, action);
    case actionTypes.TRANS_SET_FOCUSED_ENABLED:
      return setFieldSelectEnabled(state, action);
    //case actionTypes.TRANS_SET_VSE_DIALOG:
    //  return setVSEHelpDialog(state, action);
    case actionTypes.TRANS_RESET_HTML_CONTENT:
      return resetHTMLContent(state);
    case actionTypes.TRANS_RESET_HTML_HELP_CONTENT:
      return resetHTMLHelpContent(state);
    case actionTypes.TRANS_RESET_KEY_INPUT_FIELD_BINDING:
      return resetKeyInputFieldBinding(state);
    case actionTypes.TRANS_SET_HELP_TEXT:
      return setHelpText(state, action);
    case actionTypes.TRANS_SET_SHOW_HELP_CONTENT:
      return setShowHelpContent(state, action);
    case actionTypes.TRANS_SET_SHOW_OPEN_MODAL_WINDOW:
      return setShowOpenModalWindow(state, action);
    case actionTypes.TRANS_SET_HELP_PARAMS:
      return setHelpParams(state, action);
    case actionTypes.TRANS_SET_CLICKED_BTN:
      return setOmvClickedBtn(state, action);
    case actionTypes.TRANS_SET_GET_IN_PROGRESS:
      return setIsTransGetInProgress(state, action);
    case actionTypes.TRANS_SET_POST_IN_PROGRESS:
      return setIsTransPostInProgress(state, action);
    case actionTypes.TRANS_UPDATE_FORMDATA_FOR_BUG_REPORT:
      return setFormDataForBugReport(state, action);
    case actionTypes.DESTROY_SESSION:
      return initialState;
    default:
      return state;
  }
};

export default transReducer;
