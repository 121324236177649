import axios, { AxiosRequestConfig } from 'axios';

import U from './Utils';
import NV03FormData from '../models/payload/NV03FormData';

const _timeOut = 300000;

export const APIgetNV03ListItemWithVeil = (formData: NV03FormData) => {
  const config = {
    method: 'post',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/nvvalf/getitemlistWithVeil`,
    maxContentLength: 1000000,
    timeout: _timeOut,
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    data: formData,
    responseType: 'json',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

// Excel
export const APIgetNV03ExcelData = (formData: NV03FormData) => {
  const config = {
    method: 'post',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/nvvalf/getValfExcelData`,
    maxContentLength: 1000000,
    timeout: _timeOut,
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    data: formData,
    responseType: 'blob',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
  /**/
};


export const APIgetLagerLabelByFirmId = () => {
  const config = {
    method: 'get',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/nvvalf/getLagerLabelByFirmId`,
    maxContentLength: 1000000,
    timeout: 20000,
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    responseType: 'json',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
  /**/
};

