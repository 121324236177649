
export default class PageImpl {
    //
    private _content: any[] = [];
    private _pageable: any = {};
    private _totalPages: number;
    
    //
    constructor(content = [], pageable = {}, totalPages=0) {
        this._content = content;
        this._pageable = pageable;
        this._totalPages = totalPages;

    }

    get content(): any[] {
        return this._content;
    }

    get pageable(): any {
        return this._pageable;
    }

    get totalPages(): number {
        return this._totalPages;
    }
}

