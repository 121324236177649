import { Button, Tooltip } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  vitecBt: {
    display: "inline-block",
    color: "#fff",
    borderRadius: 3,
    background: "#d30535",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
    verticalAlign: "middle",
    width: "100px",
    padding: "5px",
    marginTop: "13px",
    textAlign: "center",
    transition: "background 0.3s ease, transform 0.2s ease",
    "&:hover": {
      background: "#961932",
      color: "white",
      transform: "translateY(-2px)",
      boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
      cursor: "pointer",
    },
    "&:active": {
      color: "white",
      background: "#cd5a6e",
      transform: "scale(0.98)",
    },
    "&:disabled": {
      color: "white",
      background: "#e3e3e3",
      boxShadow: "none",
    },
  },
  highlighted: {
    backgroundColor: "#850f0f",
    color: "#fff",
    border: "3px solid #fff",
    boxShadow: "0 5px 12px rgba(255, 82, 82, 0.4)",
    transform: "scale(1.02)",
    transition: "all 0.3s ease",
  },
}));

export default function ButtonVitec(props) {
  const { classes } = useStyles();

  return (
    <Tooltip
      title={props._tooltip || ""} // Tooltip content
      placement={"top"} // Tooltip placement (can be top, bottom, left, right)
      arrow // Display arrow on tooltip
    >
      <Button
        type={props._type ? props._type : "button"}
        className={`${classes.vitecBt} ${props.highlighted ? classes.highlighted : ""}`}
        onClick={props._onclick}
        disabled={props.disabled}
        style={{
          fontSize: props._fontsize,
          width: props._width,
          height: props._height,
          margin: props._padding,
          backgroundColor: props.disabled ? undefined : props._backgroundColor,
        }}
      >
        {props._label}
      </Button>
    </Tooltip>
  );
}
