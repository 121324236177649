import { alpha, Paper } from '@mui/material';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { AppState } from '../../../../store/rootReducer';

import TallenesTaleListVaregruppe from './TallenesTaleListVaregruppe';
import TallenesTaleTableToolbar from './TallenesTaleTableToolbar';
import TallenesTaleListSum from './TallenesTaleListSum';
import TallenesTaleListHoved from './TallenesTaleListHoved';
import TallenesTaleListGruppe from './TallenesTaleListGruppe';
import TallenesTaleListKunde from './TallenesTaleListKunde';


const useStyles = makeStyles()((/*theme: Theme*/) => ({
  root: { marginLeft: '20px', marginRight: '20px', marginTop: '25px', fontSize: '1em !important', color: '#456456', },
}),
);

//
export default function TallenesTaleReport(/*props*/) {
  const { classes } = useStyles();
  const visningType: string = useSelector((state) => (state as AppState).tallenesTale.visningType);
  //
  const handleVisningChangedComponent = (val) => {
    switch (true) {
      case val === 'Kunde': return <TallenesTaleListKunde />
      case val === 'Varegruppe': return <TallenesTaleListVaregruppe />
      case val === 'Gruppe': return <TallenesTaleListGruppe />
      case val === 'Hovedgruppe': return <TallenesTaleListHoved />
      case val === 'Summeringsgruppe': return <TallenesTaleListSum />
      default: return null;
    }
  }
  //
  return (
    <div className={classes.root} >
        <Paper elevation={0} sx={{ backgroundColor: alpha('#fff', .6), boxShadow: 'rgba(149, 157, 165, 0.4) 0px 8px 8px', }} >
        <TallenesTaleTableToolbar />
        {handleVisningChangedComponent(visningType)}
      </Paper>
    </div>
  )
}