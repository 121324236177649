import editIcon from '../assets/images/editIcon.svg';

const SVGIconEdit = (props) => {
  return (
    <div>
      <img src={editIcon} width={props.width} alt="Vitec SVGIconEdit " />
    </div>
  );
};

export default SVGIconEdit;
