import U from "../../felles/Utils";
import { NV03Firma, NV03Headers } from "../../models/NV03Vare";
import pageImpl from "../../models/PageImpl";
import * as actionTypesNV03 from "../actions/actionTypesNV03";
import * as fromApp from "../rootReducer";

export interface FeatureState extends fromApp.AppState {
  state: NV03;
}

export interface NV03 {
  page: pageImpl;
  nV03Headers: NV03Headers[];
  nV03Firma: NV03Firma;
  redirectPath: string;
  error: any;
  isFetchingNV03ListItem: boolean;
  order?: string;
  orderBy?: string;
  isVeilPrisChecked: boolean;
  isVisAlleLagerChecked: boolean;
  isBeholdningChecked: boolean;
  isReset: boolean;
  isResetFormEnabled: boolean;
}

const initialState = {
  page: new pageImpl(),
  nV03Headers: [],
  nV03Firma: new NV03Firma(),
  redirectPath: "/",
  error: undefined,
  isFetchingNV03ListItem: false,
  order: '',
  orderBy: '',
  isVeilPrisChecked: false,
  isVisAlleLagerChecked: false,
  isBeholdningChecked: false,
  isReset: false,
  isResetFormEnabled: true,

};


const setIsVeilPrisCheckboxState = (state: NV03, action: any) => {
  return U.updateStore(state, { isVeilPrisChecked: action.payload });
};

const setIsVisAlleLagerCheckboxState = (state: NV03, action: any) => {
  return U.updateStore(state, { isVisAlleLagerChecked: action.payload });
};


const setIsBeholdningCheckboxState = (state: NV03, action: any) => {
  return U.updateStore(state, { isBeholdningChecked: action.payload });
};

const NV03UpdateColumnHeaderList = (state: NV03, list: NV03Headers[]) => {
  return U.updateStore(state, { nV03Headers: list });
};

//
const NV03ListeGetStart = (state: NV03) => {
  return U.updateStore(state, { isFetchingNV03Liste: true });
};

//
const NV03ListeGetSuccess = (state: NV03, action: any) => {
  return U.updateStore(state, {
    page: action.payload,
    isFetchingNV03Liste: false,
  });
};

//
const NV03ListeGetFailed = (state: NV03, action: any) => {
  return U.updateStore(state, { isFetchingNV03Liste: false, error: action });
};

//  Reset form
const NV03ResetForm = (state: NV03, action: any) => {
  if (action.payload) {
    return U.updateStore(state, { isReset: true, page: new pageImpl(), nV03Headers: [] });
  } else {
    return U.updateStore(state, { isReset: false });
  }
};

//  Set Reset state enabled
const NV03SetResetFormEnabled = (state: NV03, action: any) => {
  return U.updateStore(state, { isResetFormEnabled: action.payload });
};

//
const NV03ClearComponentStore = (state: NV03) => {
  return U.updateStore(state, { ...initialState });
};

//
const NV03VALFLabelGetSuccess = (state: NV03, action: any) => {
  return U.updateStore(state, { isFetchingNV03Liste: false, nV03Firma: action.payload });
};

//
const NV03VALFLAbelGetFailed = (state: NV03, error: any) => {
  return U.updateStore(state, { isFetchingNV03Liste: false, error: error });
};



const NV03 = (state = initialState, action: any) => {
  switch (action.type) {


    case actionTypesNV03.NV03_VALF_LABEL_GET_SUCCESS:
      return NV03VALFLabelGetSuccess(state, action);
    case actionTypesNV03.NV03_VALF_LABEL_GET_FAILED:
      return NV03VALFLAbelGetFailed(state, action);
    case actionTypesNV03.NV03_SET_VEILPRIS_CHECKBOX_STATE:
      return setIsVeilPrisCheckboxState(state, action);
    case actionTypesNV03.NV03_SET_VISALLELAGER_CHECKBOX_STATE:
      return setIsVisAlleLagerCheckboxState(state, action);
    case actionTypesNV03.NV03_SET_BEHOLDNING_CHECKBOX_STATE:
      return setIsBeholdningCheckboxState(state, action);
    case actionTypesNV03.NV03_SET_COLUMN_HEADER_LIST:
      return NV03UpdateColumnHeaderList(state, action.list);
    case actionTypesNV03.NV03_VALF_LIST_GET_INIT:
      return NV03ListeGetStart(state);
    case actionTypesNV03.NV03_VALF_LIST_GET_SUCCESS:
      return NV03ListeGetSuccess(state, action);
    case actionTypesNV03.NV03_VALF_LIST_GET_FAILED:
      return NV03ListeGetFailed(state, action);
    case actionTypesNV03.NV03_RESET_FORM:
      return NV03ResetForm(state, action);
    case actionTypesNV03.NV03_RESET_COMPONENT_STORE:
      return NV03ClearComponentStore(state);
    case actionTypesNV03.NV03_SET_RESET_FORM_ENABLED:
      return NV03SetResetFormEnabled(state, action);

    default:
      return state;
  }
};

export default NV03;
