
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, lighten, Theme, Toolbar, Tooltip, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import * as actions from '../../../store/actions/index';
import { AppState } from '../../../store/rootReducer';
//
const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        paddingRight: theme.spacing(1),
        backgroundColor: '#2E2D2C', borderTopLeftRadius: '5px', borderTopRightRadius: '5px'
    },
    highlight:
        theme.palette.primary.light === "light"
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85)
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark
            },
    spacer: {
        flex: "1 1 100%"
    },
    actions: {
        color: theme.palette.text.secondary
    },
    title: {
        flex: "0 0 auto"
    },
}));
//
const UserListTableToolbar = (/* props*/) => {
    const dispatch = useDispatch();
    const { classes } = useStyles();
    const selectedUserList = useSelector(state => (state as AppState).userAdmin.selectedUserList);
    const setUserDeletionState = () => dispatch(actions.setUserDeletionState(1));
    const user: any = useSelector((state) => (state as AppState).auth.userJWT);
    //
    useEffect(() => {
        dispatch(actions.setLocationPath(<>Brukeradministrasjon {'>'} Brukerliste for {user?.company?.toString()}</>));
    }, [dispatch]);
    //
    return (
        <Toolbar sx={{ color: 'red', backgroundColor: '#2E2D2C', border: 'none' }}
            className={classes.root}>
            <div className={classes.title}>
                {selectedUserList.length > 0 ? (
                    <Typography variant="subtitle1" sx={{ color: 'white', backgroundColor: '#2E2D2C' }}>
                        {selectedUserList.length} selected
                    </Typography>
                ) : (
                    null
                )}
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
                {selectedUserList.length > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton aria-label="Delete" onClick={() => setUserDeletionState()}>
                            <DeleteIcon sx={{ color: 'white' }} />
                        </IconButton>
                    </Tooltip>
                ) : null}
            </div>
        </Toolbar>
    );
};

export default UserListTableToolbar;
