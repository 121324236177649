import { put } from 'redux-saga/effects';
import { fetchSalgData4AAR, fetchSalgVareData,fetchCustomerData, fetchKundeData,fetchAllTransactions } from '../../felles/APICalls_NV01';
import * as actions from '../actions';
import * as actionTypesNV01SALG from "../actions/actionTypesNV01";
import * as actionsNV01 from '../actions/indexNV01'

export function* fetchSalgData(action) {

  try {
    yield put(actions.setIsloading(true));
    
    let response;
    if (action.type === actionTypesNV01SALG.SALG_SISTE_4_AAR_GET) {
      response = yield fetchSalgData4AAR(action.payload.firmaid, action.payload.alfa, action.payload.artikkel);
    } else if (action.type === actionTypesNV01SALG.SALGVAREDATA_GET) {
      response = yield fetchSalgVareData(action.payload.firmaid, action.payload.alfa, action.payload.artikkel);
    } else if (action.type === actionTypesNV01SALG.KUNDE_SALG_DATA_GET) {
            response = yield fetchCustomerData(action.payload.kundenummer, action.payload.year, action.payload.firmaid,action.payload.varegruppe);
    }else if (action.type === actionTypesNV01SALG.KUNDE_DATA_GET) {
         response = yield fetchKundeData(action.payload.firmaid,action.payload.kundenummer,action.payload.kundenavn);
    }else if (action.type === actionTypesNV01SALG.ALL_TRANSAKSJONER_GET) { 
      response = yield fetchAllTransactions(action.payload.firmaid, action.payload.alfa, action.payload.artikkel, action.payload.page,action.payload.pagesize);
    }else if (action.type === actionTypesNV01SALG.EXPORT_ALL_TRANSAKSJONER) { 
      response = yield fetchAllTransactions(action.payload.firmaid, action.payload.alfa, action.payload.artikkel, action.payload.page,action.payload.pagesize);
    }

    if (response) {
      if (action.type === actionTypesNV01SALG.SALG_SISTE_4_AAR_GET) {
        yield put(actionsNV01.fetchSalgDataSuccess(response));
      } else if (action.type === actionTypesNV01SALG.SALGVAREDATA_GET) {
        yield put(actionsNV01.fetchSalgVareDataSuccess(response));
      }else if (action.type === actionTypesNV01SALG.KUNDE_SALG_DATA_GET) {
        yield put(actionsNV01.fetchKundeSalgDataSuccess(response));
      }else if (action.type === actionTypesNV01SALG.KUNDE_DATA_GET) {
         yield put(actionsNV01.fetchKundeDataSuccess(response));
      }else if (action.type ===actionTypesNV01SALG.ALL_TRANSAKSJONER_GET) { 
        yield put(actionsNV01.fetchTRANSAKSJONERSuccess(response));
      }
      else if (action.type ===actionTypesNV01SALG.EXPORT_ALL_TRANSAKSJONER) { 
        yield put(actionsNV01.exportTRANSAKSJONERSuccess(response));
      }
      
    } else {
      yield put(actionsNV01.fetchSalgDataFailure("No data received"));
    }
  } catch (error) {
   
    // Error handling for different actions
    if (action.type === actionTypesNV01SALG.KUNDE_SALG_DATA_GET) {
      yield put(actionsNV01.fetchKundeSalgDataFailure("Ingen data funnet for denne kunden"));
    } else if (action.type === actionTypesNV01SALG.KUNDE_DATA_GET) {
      // Error handling for KundeData
      yield put(actionsNV01.fetchKundeDataFailure("Error fetching customer data"));
    } else if (action.type === actionTypesNV01SALG.ALL_TRANSAKSJONER_GET) { // Handle error for transaction data
      yield put(actionsNV01.fetchTRANSAKSJONERFailure("Error fetching transaction data"));
    } else if (action.type === actionTypesNV01SALG.EXPORT_ALL_TRANSAKSJONER) { // Handle error for transaction data
      yield put(actionsNV01.exportTRANSAKSJONERFailure("Error fetching transaction data"));
    } else {
      yield put(actionsNV01.fetchSalgDataFailure("Error fetching sales data"));
    }
  } finally {
    yield put(actions.setIsloading(false));
  }
}



