import Tooltip from '@mui/material/Tooltip';
import React from 'react';
//import useTooltip from './useTooltip'; // Import your custom hook
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../store/actions/index';

interface ToolTipTfWrapperProps {
    title: React.ReactNode;
    id: string;
    children: React.ReactNode;
    delay?: number;
    placement?: 'left' | 'right' | 'top' | 'bottom'; // Optional placement
}

const ToolTipTfWrapper: React.FC<ToolTipTfWrapperProps> = ({ title, id, children, delay, placement = 'left' }) => {
    const dispatch = useDispatch();

    // holds a records of the tooltip id's already shown
    const tooltipList: string[] = useSelector((state: any) => state.ui.tooltip);

    // Custom hook to manage the tooltip state
    const useTooltip = (delay, id) => {
        // State to manage the open state of the tooltip
        const [open, setOpen] = useState(false);
        // Function to show the tooltip
        const showTooltip = useCallback(() => {
            if (!tooltipList.includes(id)) {
                const timeoutIdIn = setTimeout(() => {
                    setOpen(true);
                }, 4000);
                const timeoutIdOut = setTimeout(() => {
                    setOpen(false);
                    if (!tooltipList.includes(id)) {
                        dispatch(actions.updateTooltipList(id));
                    }
                }, delay);
                return () => { clearTimeout(timeoutIdIn); clearTimeout(timeoutIdOut); }
            }
        }, [delay, tooltipList, id]);
        // Function to hide the tooltip
        const hideTooltip = useCallback(() => {
            setOpen(false);
        }, []);
        // Return the open state and the show/hide functions
        return { open, showTooltip, hideTooltip };
    };
    // Destructure the open state and the show/hide functions
    const { open, showTooltip, hideTooltip } = useTooltip(delay, id);

    // Check if the children is a valid React element
    if (!React.isValidElement(children)) {
        return null; // or handle the error as needed
    }

    // Render the Tooltip component with the specified props
    return (
        <Tooltip
            title={title}
            placement={placement}
            open={open}
            arrow
            id={id ? id : ''}
            onClick={showTooltip}
            onMouseLeave={hideTooltip}
            //onFocus={showTooltip}
            onBlur={hideTooltip}
            disableFocusListener={true}
            componentsProps={{
                tooltip: {
                    sx: {
                        zIndex: 99999,
                        fontSize: 15, fontWeight: 100,
                        color: '#fff',
                    },
                },
            }}
        >{children}
        </Tooltip>
    );
};

export default ToolTipTfWrapper;