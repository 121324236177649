import * as actionTypes from './actionTypes';

export const transGetInit = () => {
  return { type: actionTypes.TRANS_GET_INIT };
};

export const transGet = (payload: any) => {
  return { type: actionTypes.TRANS_GET, payload };
};

export const transGetSuccess = (payload: any, termId: string) => {
  return { type: actionTypes.TRANS_GET_SUCCESS, payload, termId };
};

export const transGetFailed = (error: any) => {
  return { type: actionTypes.TRANS_GET_FAILED, error };
};

//////////////////////////////////////////////////////////////////////// HELP
export const transHelpGetInit = () => {
  return { type: actionTypes.TRANS_HELP_GET_INIT };
};

export const transHelpGet = (payload: any) => {
  return { type: actionTypes.TRANS_HELP_GET, payload };
};

export const transHelpGetSuccess = (payload: any) => {
  return { type: actionTypes.TRANS_HELP_GET_SUCCESS, payload };
};

export const transHelpGetFailed = (error: any) => {
  return { type: actionTypes.TRANS_HELP_GET_FAILED, error };
};

///////////////////////////////////////////////////////////////////////////// HELP
/*
export const APIEditHelpStarted = () => {
  return { type: actionTypes.TRANS_API_EDIT_HELP_INIT };
};

export const APIEditHelpPostStarted = (payload: any) => {
  return { type: actionTypes.TRANS_API_EDIT_HELP_POST_INIT, payload };
};

export const APIEditHelpPostSuccess = (payload: any) => {
  return { type: actionTypes.TRANS_API_EDIT_HELP_POST_SUCCESS, payload };
};

export const APIEditHelpPostFailed = (error: any) => {
  return { type: actionTypes.TRANS_API_EDIT_HELP_POST_FAILED, error };
};
*/


//////////////////////////////////////////////////////////////////////// 
export const transFormDataPostInit = () => {
  return { type: actionTypes.TRANS_FORMDATA_POST_INIT };
};

export const transFormDataPost = (payload: any) => {
  return { type: actionTypes.TRANS_FORMDATA_POST, payload };
};

export const transFormDataPostSuccess = (payload: any) => {
  return { type: actionTypes.TRANS_FORMDATA_POST_SUCCESS, payload };
};

export const transFormDataPostFailed = (error: any) => {
  return { type: actionTypes.TRANS_FORMDATA_POST_FAILED, error };
};

export const transFormDataToWS = (payload: any) => {
  return { type: actionTypes.TRANS_FORMDATA_TO_WEBSOCKET, payload };
};

/*

export const transCloseWebSocket = () => {
  return { type: actionTypes.TRANS_FORMDATA_CLOSE_WEBSOCKET};
};
*/
//////////////////////////////////////////////////////////////////////// 
// OPEN MODAL WINDOW

export const transOpenModalWindowPostInit = () => {
  return { type: actionTypes.TRANS_OMW_FORMDATA_POST_INIT };
};

export const transOpenModalWindowPost = (payload: any) => {
  return { type: actionTypes.TRANS_OMW_POST_FORMDATA_INIT, payload };
};

export const transOpenModalWindowPostSuccess = (payload: any) => {
  return { type: actionTypes.TRANS_OMW_POST_FORMDATA_SUCCESS, payload };
};

export const transOpenModalWindowPostFailed = (error: any) => {
  return { type: actionTypes.TRANS_OMW_POST_FORMDATA_FAILED, error };
};


//////////////////////////////////////////////////////////////////////// 
export const updatePressedKey = (payload: any) => {
  return { type: actionTypes.UPDATE_PRESSED_KEY, payload };
};

export const transErrorDialogUpdated = (payload: any) => {
  return { type: actionTypes.UPDATE_DIALOG_STATE, payload };
};

export const setTermID = (val: string) => {
  const payload = val.includes('?') ? val?.split('transid=')[1].split('&scode')[0] || '' : val || '';
  return { type: actionTypes.TRANS_TARGET_UPDATED, payload };
};

export const updateInputState = (inputName: string, value: string) => {
  return { type: actionTypes.TRANS_UPDATE_INPUT_STATE, inputName, value };
};

export const updateInputArrayState = (payload: any) => {
  return { type: actionTypes.TRANS_UPDATE_INPUT_ARRAY_STATE, payload };
};

export const updateInputArrayLineState = (payload: any) => {
  return { type: actionTypes.TRANS_UPDATE_INPUT_ARRAY_LINE_STATE, payload };
};

export const setInputState = (payload: any) => {
  return { type: actionTypes.TRANS_SET_INPUT_STATE, payload };
};

//
export const setFieldToAttachFocus = (payload: any) => {
  return { type: actionTypes.TRANS_SET_FOCUSED_FIELD, payload };
};

//
export const setCurrentFieldwithFocus = (payload: any) => {
  return { type: actionTypes.TRANS_SET_CURRENT_FOCUSED_FIELD, payload };
};

//
export const setDefaultFieldFocus = (payload: any) => {
  return { type: actionTypes.TRANS_DEFAULT_SET_FOCUSED_FIELD, payload };
};

//
export const setFieldSelectEnabled = (payload: any) => {
  return { type: actionTypes.TRANS_SET_FOCUSED_ENABLED, payload };
};

/*
export const setVSEHelpDialog = (payload: any) => {
  return { type: actionTypes.TRANS_SET_VSE_DIALOG, payload };
};
*/
//
export const setShowHelpContent = (payload: any) => {
  return { type: actionTypes.TRANS_SET_SHOW_HELP_CONTENT, payload };
};

//
export const setShowOpenModalWindow = (payload: any) => {
  return { type: actionTypes.TRANS_SET_SHOW_OPEN_MODAL_WINDOW, payload };
};

//
export const setHelpText = (payload: any) => {
  return { type: actionTypes.TRANS_SET_HELP_TEXT, payload };
};

//
export const setHelpParams = (payload: any) => {
  return { type: actionTypes.TRANS_SET_HELP_PARAMS, payload };
};

//
export const resetHTMLContent = () => {
  return { type: actionTypes.TRANS_RESET_HTML_CONTENT };
};

//
export const resetHTMLHelpContent = () => {
  return { type: actionTypes.TRANS_RESET_HTML_HELP_CONTENT };
};

//
export const resetKeyInputFieldBinding = () => {
  return { type: actionTypes.TRANS_RESET_KEY_INPUT_FIELD_BINDING };
};

//
export const setOmvClickedBtn = (payload: any) => {
  return { type: actionTypes.TRANS_SET_CLICKED_BTN, payload };
};

//
export const setIsTransGetInProgress = (payload: any) => {
  return { type: actionTypes.TRANS_SET_GET_IN_PROGRESS, payload };
};

//
export const setIsTransPostInProgress = (payload: any) => {
  return { type: actionTypes.TRANS_SET_POST_IN_PROGRESS, payload };
};


export const setFormDataForBugReport = (payload) => {
  return { type: actionTypes.TRANS_UPDATE_FORMDATA_FOR_BUG_REPORT, payload };
};


