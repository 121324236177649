import classNames from "classnames";
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import SimpleMSG from '../../models/SimpleMSG';
import SupportFormData from '../../models/SupportFormData';
import UserUpdatePayload from '../../models/UserUpdatePayload';
import * as actions from '../../store/actions/index';
import { AppState } from '../../store/rootReducer';


const useStyles = makeStyles()((theme) =>
  ({
  root: { width: 700, marginTop: 150, marginLeft: 50, paddingRight: theme.spacing(1), },
  title: { fontSize: '2em', margin: '10px', },
  from: { fontSize: '1.4em', margin: '10px', },
  myInput: { width: 700, padding: '12px', margin: '10px', border: '1px solid #ccc', },
  myButton: { width: 300, padding: '12px', border: '1px solid #ccc', },
  myForm: { width: 300, padding: '12px', border: '1px solid #ccc', }
}));


const SupportContent = (/*props*/) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const userData: UserUpdatePayload = useSelector(state => (state as AppState).profile.userProfileData);
  const transError: SimpleMSG = useSelector(state => (state as AppState).trans.error);

  useEffect(() => {
    if (userData.username === '')
      dispatch(actions.userProfileDataFetch());
  }, [dispatch, userData, transError])

  const onSubmit = (e) => {
    e.preventDefault();
    const body = new FormData();
    let fileName = '';
    let subject = '';
    let from = '';
    let description = '';
    const data = Array.prototype.filter.call(e.target.elements,
      (input) => {
        switch (input.id) {
          case 'file':
            fileName = input.value;
            break;
          case 'from':
            from = input.value;
            break;
          case 'subject':
            subject = input.value;
            break;
          case 'description':
            description = input.value;
            break;
          default:
            break;
        }

        if (input.nodeName === 'BUTTON') return false;
        return true;
      });
    Array.prototype.map.call(data, (input) => {
      input.id !== 'file' ? body.append(input.id, input.value) : body.append('file', input.files[0]);

    });
    if (subject !== '' && from !== '')
      dispatch(actions.sendFeedbackToSupportPost(new SupportFormData(from, subject, description, fileName, body)));

  }

  return (
    <div className={classNames(classes.root)}>
      <form onSubmit={onSubmit} encType="multipart/form-data" className="form-add">
        <div className={classNames(classes.title)}>Kontakt oss<br />

        </div>
        <div className={classNames(classes.from)}>{userData.email}</div>
        <input className={classNames(classes.myInput)} type="hidden" id="from" readOnly value={userData.email} />
        <textarea className={classNames(classes.myInput)} id="subject" placeholder="Subject..."></textarea>
        <textarea className={classNames(classes.myInput)} id="description" rows={10} placeholder="Description body..." >
          {transError !== undefined ? transError?.description : ''}
        </textarea>
        <input className={classNames(classes.myInput)} type="file" id="file" accept=".txt" />
        <button className={classNames(classes.myInput)} type="submit">Submit</button>
      </form>
    </div>
  );
}

export default SupportContent;