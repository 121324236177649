import helpRed from '../assets/images/closeSquare.svg';

const SVGCloseSquare = () => {
  return (
    <div >
      <img src={helpRed} alt="Vitec-Help" height={23} width={23} style={{ marginTop: 5, cursor:'pointer', color: 'blue'}} />
    </div>
  );
};

export default SVGCloseSquare;
