import React, { useState } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  IconButton,
  InputAdornment,
  Box,
} from "@mui/material";
import { FilterAlt } from "@mui/icons-material";
import { makeStyles } from 'tss-react/mui';
import { Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import {
  YearlySalesData,
  KundeSalgData,
  TransactionData,
} from "../../../models/NV01SALG";

interface GridViewProps {
  data: (KundeSalgData | TransactionData | YearlySalesData)[];
  kundenummer?: string;
  dataType: string;
}

const useStyles = makeStyles()(() => ({
  root: { margin: 20, fontSize: '1em !important', color: '#fff' },
  container: {
    height: `calc(100vh - 150px)`,
    border: 'none',
    maxHeight: '481px',
    overflowY: 'auto',
  },
}));

const GridView: React.FC<GridViewProps> = ({ data, kundenummer, dataType }) => {
  const [filters, setFilters] = useState<{ [key: string]: string }>({});
  const [filterColumn, setFilterColumn] = useState<string | null>(null);
  const { classes } = useStyles();

  const handleFilterChange = (columnName: string, value: string) => {
    setFilters({ ...filters, [columnName]: value });
  };

  const applyFilters = (
    item: KundeSalgData | TransactionData | YearlySalesData
  ) => {
    return Object.keys(filters).every((key) => {
      const value = item[key as keyof (KundeSalgData | TransactionData | YearlySalesData)]
        ?.toString()
        .toLowerCase();

      if (!value) {
        return false;
      }

      // Specific filtering for "ref_number" column
      if (key === "ref_number") {
        return value.startsWith(filters[key].toLowerCase());
      }

      return value.includes(filters[key].toLowerCase());
    });
  };

  const handleFilterClick = (columnName: string) => {
    if (filterColumn === columnName) {
      setFilters((prevFilters) => {
        const updatedFilters = { ...prevFilters };
        delete updatedFilters[columnName];
        return updatedFilters;
      });
      setFilterColumn(null);
    } else {
      setFilterColumn(columnName);
    }
  };

  // Define column mappings based on data type
  const columnDefinitions: { [key: string]: { label: string; field: string }[] } = {
    customerData: [
      { label: "Kundenummer", field: "kundenummer" },
      { label: "Antall", field: "count" },
      { label: "Alfa", field: "alpha" },
      { label: "Artikkel", field: "item_number" },
      { label: "Varebetegnelse", field: "description" },
      { label: "Varegruppe", field: "article_group" },
    ],
    transactionData: [
      { label: "Dato", field: "date" },
      { label: "Tid", field: "time" },
      { label: "Tekst", field: "routine" },
      { label: "Ref.nr", field: "ref_number" },
      { label: "Kundenr.", field: "customer" },
      { label: "Antall", field: "count" },
      { label: "Ident", field: "user" },
      { label: "Sign", field: "init" },
      { label: "Lager", field: "lk_code" },
      { label: "Lokasjon", field: "location" },
    ],
  };

  const columns = columnDefinitions[dataType] || [];

  return (
    <Paper >
      {/* Table Header */}
      <TableContainer className={classes.container}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((col) => (
           <TableCell key={col.field} sx={{ fontWeight: "bold", padding: '12px 16px' }}>
           <Box sx={{ display: "flex", alignItems: "center" }}>
             {col.label}
             <IconButton size="small" onClick={() => handleFilterClick(col.field)}>
               <FilterAlt />
             </IconButton>
             {col.field === "ref_number" && (
               <Tooltip
                 title="I dette feltet søkes det fra begynnelsen av ref.nummeret."
                 placement="top"
               >
                 <InfoIcon
                   sx={{
                     color: "Green", // Yellow color for the info icon
                     fontSize: 22,    // Make the icon smaller
                     marginLeft: 1,   // Space between the filter icon and info icon
                   }}
                 />
               </Tooltip>
             )}
           </Box>
           {/* Filter Input Field */}
           {filterColumn === col.field && (
             <TextField
               size="small"
               variant="outlined"
               value={filters[col.field] || ""}
               onChange={(e) => handleFilterChange(col.field, e.target.value)}
               InputProps={{
                 endAdornment: (
                   <InputAdornment position="end">
                     <IconButton size="small" onClick={() => handleFilterClick(col.field)}>
                       <FilterAlt />
                     </IconButton>
                   </InputAdornment>
                 ),
               }}
               sx={{ marginTop: 1 }}
             />
           )}
         </TableCell>
         
         
              ))}
            </TableRow>
          </TableHead>
          {/* Scrollable Table Body */}
          <TableBody>
            {data.filter(applyFilters).map((item, index) => (
              <TableRow
                key={index}
                sx={{ height: '45px' }} // Set row height using sx
              >
                {columns.map((col) => (
                  <TableCell
                    key={col.field}
                    sx={{ padding: '12px 16px' }} // Inline padding for each cell
                  >
                    {/* Render customer number separately if present */}
                    {col.field === "kundenummer"
                      ? kundenummer
                      : (item as any)[col.field]?.toString()}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default GridView;
