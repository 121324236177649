import React, { useState } from "react";
import ButtonVitec from "../../../../components/ButtonHighlight";
import Typography from "@mui/material/Typography";

const Header = ({ onFetchTransactions, onFetchSalg, onFetchKundeTopp50,onhandleExport ,isExportDisabled}) => {
  const [activeButton, setActiveButton] = useState(null);

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
    if (buttonType === "transactions") {
      onFetchTransactions();
    } else if (buttonType === "salg") {
      onFetchSalg();
    } else if (buttonType === "kundeTopp50") {
      onFetchKundeTopp50();
    }else if (buttonType === "export") {
      onhandleExport();
    }
  };

  return (
    <div style={{ marginBottom: "20px" }}>
           {/* Button for "Alle transaksjoner" */}
      <ButtonVitec
        _type="submit"
        _fontsize="1em"
        _onclick={() => handleButtonClick("transactions")}
        _label="Alle transaksjoner"
        _tooltip="Tast inn alfa og artikkel og klikk på «Alle Transaksjoner». Programmet lister ut de siste 500 transaksjonene.Ved eksport til Excel får du alle transaksjoner på denne varen.
I tabellen kan du søke ved hjelp av filter i de ulike feltene. I alle felt søker du på innehold i feltet."
        _width={250}
        _height={40}
        _padding={5}
        highlighted={activeButton === "transactions"} // Check if this button is active
      />

      {/* Button for "Salg siste 4 år" */}
      <ButtonVitec
        _type="submit"
        _fontsize="1em"
        _tooltip="Tast inn alfa og artikkel og klikk på «Salg siste 4 år». Programmet viser salg pr. mnd. i antall og beløp for de siste 4 år."
        _onclick={() => handleButtonClick("salg")}
        _label="Salg siste 4 år"
        _width={250}
        _height={40}
        _padding={5}
        highlighted={activeButton === "salg"} // Check if this button is active
      />

      {/* Button for "Kunde Topp 100" */}
      <ButtonVitec
        _type="submit"
        _fontsize="1em"
        _tooltip="Tast inn kundenummer og klikk «Kunde Topp 100». Viser automatisk totalt i år. Mulighet for å få salg i fjor, alene eller totalt med salg hittil i år ved å sette hake på begge år. Det kan også settes inn varegruppe for kundens kjøp i denne varegruppen. Husk å klikk på «Kunde Topp 100» ved endring av år eller varegruppe."
        _onclick={() => handleButtonClick("kundeTopp50")}
        _label="Kunde Topp 100"
        _width={250}
        _height={40}
        _padding={5}
        highlighted={activeButton === "kundeTopp50"} // Check if this button is active
      />
       <ButtonVitec
        _type="button"
        _label="Excel"
        _onclick={() => handleButtonClick("export")}
        _fontsize="1em"
        _width={250}
        _height={40}
        _padding={5}
        _margin={0}
        disabled={isExportDisabled}
         _backgroundColor="#1d6f42"
      />
    </div>
  );
};

export default Header;
