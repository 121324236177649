import { withStyles } from 'tss-react/mui';
import { Component } from 'react';
import { Responsive } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import { connect } from 'react-redux';
import { SizeMe } from 'react-sizeme';
import Utils from '../../felles/Utils';
import GridLayout from '../../models/GridLayout';
import GridLayouts from '../../models/GridLayouts';
import MenuEl from '../../models/MenuEl';
import MenuItemTarget from '../../models/MenuItemTarget';
import * as actions from '../../store/actions/index';
import { history } from '../../store/history';
import { AppState } from '../../store/rootReducer';
import LPContentCardVSE from '../../components/LPContentCardVSE';

//Props sent from parent nodes
interface sentFromParentComponent {
  size: any,
  setCompactType?: (val: any) => void;
}
//
interface StateProps {
  currentBreakpoint: string;
  compactType: any;
  mounted: boolean;
  layouts: any;
  layout: any;
  classes?: any;
  // drawerWidth: number;
 // gridWidth: number;
  cols: any;
  height: number;
  isDrawerOpened: boolean;
  menuItemTarget: MenuItemTarget;
  menuItemList: MenuEl[];
  gridItemHeight: any[];
  isDebugEnabled: boolean;
  isEndKeyActivated: boolean;
}
//
interface DispatchProps {
  setLayouts: (layout: any, layouts: any) => void;
  // setLayout: (layout) => void;
  setMoutedState: (val: boolean) => void;
  setDrawerState: (val: boolean) => void;
  setBreakpointState: (val: any) => void;
  menuItemSetTarget: (val: any) => void;
  menuItemSetSelected: (val: any) => void;
}
//
type Props = sentFromParentComponent & StateProps & DispatchProps;
//
export function mapStateToProps(state: AppState): StateProps {
  return {
    currentBreakpoint: state.ui.currentBreakpoint,
    compactType: state.ui.compactType,
    mounted: state.ui.mounted,
    layout: state.ui.layout,
    layouts: state.ui.layouts,
    // drawerWidth: state.ui.drawerWidth,
    //gridWidth: state.ui.gridWidth,
    cols: state.ui.cols,
    height: state.ui.height,
    isDrawerOpened: state.ui.isDrawerOpened,
    menuItemTarget: state.menuItem.menuItemTarget,
    menuItemList: state.menuItem.menuItems,
    gridItemHeight: state.ui.gridItemHeight,
    isDebugEnabled: state.utils.isDebugEnabled,
    isEndKeyActivated: true,
  }
}

export function mapDispatchToProps(dispatch): DispatchProps {
  return {
    setLayouts: (layout, layouts) => dispatch(actions.setGridLayouts(layout, layouts)),
    // setLayout: (layout) => dispatch(actions.setGridLayout(layout)),
    setMoutedState: (val) => dispatch(actions.setMouted(val)),
    setDrawerState: (val) => dispatch(actions.setDrawerState(val)),
    setBreakpointState: (val) => dispatch(actions.setCurrentBreakPoint(val)),
    menuItemSetTarget: (val) => dispatch(actions.menuItemSetTarget(val)),
    menuItemSetSelected: (val) => dispatch(actions.menuItemSetSelected(val)),
  }
}

class LPContentItem extends Component<Props> {
  //
  _margin = [20, 20];
  gridItemHeight = { xl: 25, lg: 10, md: 10, sm: 10, xs: 10, xxs: 10 };

  //Init
  componentDidMount() {

    // for the tablet IPAD / GALAX
    document.body.removeEventListener('touchmove', Utils.preventDefault);
    //
    this.props.setLayouts(this.generateGridLayouts()[this.props.currentBreakpoint], this.generateGridLayouts());
    if (this.props.currentBreakpoint === 'xs' || this.props.currentBreakpoint === 'xxs') this.props.setDrawerState(false);
    this.props.setMoutedState(true);
    switch (this.props.currentBreakpoint) {
      case 'xxs': this._margin = [20, 25]; break;
      case 'xs': this._margin = [20, 28]; break;
      case 'sm': this._margin = [20, 30]; break;
      case 'md': this._margin = [20, 30]; break;
      case 'lg': this._margin = [20, 33]; break;
      case 'xl': this._margin = [20, 19]; break;
      case 'xxl': this._margin = [20, 20]; break;
      default:
        break;
    }
  }

  //
  onBreakpointChange = (breakpoint) => {
    return this.props.setBreakpointState(breakpoint);
  };

  //
  onCompactTypeChange = () => {
    return this.props.setCompactType ? this.props.setCompactType('vertical') : null;
  };

  //
  onLayoutChange = () => {
    return this.props.setLayouts(this.generateLayout(this.props.currentBreakpoint), this.generateGridLayouts());
  };

  //
  handlePressedEndKey(key, event: any = null) {
    event.preventDefault();
    const n: MenuItemTarget = this.props.menuItemTarget;
    let firstSub = '';
    this.props.menuItemList.forEach((item: MenuEl) => {
      if (item.nodes.length > 0) {
        if (n.rootItem === item.name) {
          firstSub = item.nodes[0].name;
        }
      }
    });
    this.props.menuItemSetSelected([n.rootItem, firstSub + n.rootItem]);
    this.props.menuItemSetTarget(new MenuItemTarget(n.rootItem, firstSub, '', '', 0, 0, 1, 1, false, n.title));
    history.push(Utils.HOMEPAGE_CONTEXT + '/menu');
    return;
  }

  //
  handlePressedKey(key, event) {
    event.preventDefault();
    if (key === 'end') {
      this.handlePressedEndKey(key, event);
      return;
    }
  }

  // Need a Rewrite - Optimisation
  generateGridLayouts() {
    const itemOffsetLG = [0, 8, 16];
    let IndexOffsett = 0;
    const { menuItemTarget, menuItemList } = this.props;
    const gridLayouts: GridLayouts = new GridLayouts();
    if (menuItemList && menuItemList.length > 0) {
      menuItemList.forEach((item: MenuEl) => {
        if (item.name === menuItemTarget.rootItem && item.nodes.length > 1) {
          item.nodes.forEach((subItem: MenuEl) => {
            if (subItem.name === menuItemTarget.item) {
              subItem.nodes.forEach((em: MenuEl, j) => {
                gridLayouts.xxl.push(new GridLayout(8, 2 + em.nodes.length, itemOffsetLG[IndexOffsett], 0, `${j}`));
                gridLayouts.xl.push(new GridLayout(8, 2 + em.nodes.length, itemOffsetLG[IndexOffsett], 0, `${j}`));
                gridLayouts.lg.push(new GridLayout(8, 2 + em.nodes.length, itemOffsetLG[IndexOffsett], 0, `${j}`));
                gridLayouts.md.push(new GridLayout(8, 2 + em.nodes.length, itemOffsetLG[IndexOffsett], 0, `${j}`));
                gridLayouts.sm.push(
                  new GridLayout(
                    12, 2 + em.nodes.length,
                    itemOffsetLG[IndexOffsett] === 8 ? itemOffsetLG[IndexOffsett] + 4 : itemOffsetLG[IndexOffsett],
                    0, `${j}`)
                );
                gridLayouts.xs.push(new GridLayout(24, 2 + em.nodes.length, itemOffsetLG[IndexOffsett], 0, `${j}`));
                gridLayouts.xxs.push(new GridLayout(24, 2 + em.nodes.length, itemOffsetLG[IndexOffsett], 0, `${j}`));
                if (IndexOffsett < 2) IndexOffsett++;
                else IndexOffsett = 0;
              });
            }
          });
        } else {
          // for menu item with no
          item.nodes.forEach((subItem: MenuEl) => {
            if (subItem.name === menuItemTarget.item) {
              subItem.nodes.forEach((em: MenuEl) => {
                const JSXSubItemElemmentsA: string[] = [];
                em.nodes.forEach((eem: MenuEl) => {
                  if (!JSXSubItemElemmentsA.includes(eem.heading)) JSXSubItemElemmentsA.push(eem.heading);
                });

                JSXSubItemElemmentsA.forEach((weem: string, o) => {
                  const JSXSubItemElemments: any = [];
                  em.nodes.forEach((eee: MenuEl) => {
                    if (eee.heading === weem) {
                      JSXSubItemElemments.push(eee);
                    }
                  });

                  gridLayouts.xxl.push(new GridLayout(8, 2 + JSXSubItemElemments.length, itemOffsetLG[IndexOffsett], 0, `${o}`));
                  gridLayouts.xl.push(new GridLayout(8, 2 + JSXSubItemElemments.length, itemOffsetLG[IndexOffsett], 0, `${o}`));
                  gridLayouts.lg.push(new GridLayout(8, 2 + JSXSubItemElemments.length, itemOffsetLG[IndexOffsett], 0, `${o}`));
                  gridLayouts.md.push(new GridLayout(8, 2 + JSXSubItemElemments.length, itemOffsetLG[IndexOffsett], 0, `${o}`));
                  gridLayouts.sm.push(
                    new GridLayout(12, 2 + JSXSubItemElemments.length,
                      itemOffsetLG[IndexOffsett] === 8 ? itemOffsetLG[IndexOffsett] + 4 : itemOffsetLG[IndexOffsett],
                      0, `${o}`));
                  gridLayouts.xs.push(new GridLayout(24, 2 + JSXSubItemElemments.length, itemOffsetLG[IndexOffsett], 0, `${o}`));
                  gridLayouts.xxs.push(new GridLayout(24, 2 + JSXSubItemElemments.length, itemOffsetLG[IndexOffsett], 0, `${o}`));
                  if (IndexOffsett < 2) IndexOffsett++;
                  else IndexOffsett = 0;
                });
              });
            }
          });
        }
      });
    }
    return gridLayouts;
  }

  subItemComposed = () => {
    const { menuItemTarget, menuItemList, classes } = this.props;
    const JSXItemElemments: any = [];
    if (menuItemList && menuItemList.length > 0) {
      menuItemList.forEach((item: MenuEl, i) => {
        if (item.name === menuItemTarget.rootItem && item.nodes.length > 1) {
          item.nodes.forEach((subItem: MenuEl) => {
            if (subItem.name === menuItemTarget.item) {
              subItem.nodes.forEach((em: MenuEl, j) => {
                const JSXSubItemElemments: any = [];
                em.nodes.forEach((eem: MenuEl) => {
                  JSXSubItemElemments.push(eem);
                });
                JSXItemElemments.push(
                  <div className={classes.gridItem} key={j}>
                    <SizeMe monitorWidth={false} monitorHeight={true}>
                      {({ size }) => (
                        <LPContentCardVSE isDebugEnabled={this.props.isDebugEnabled} key={'tree' + i} data={JSXSubItemElemments} title={em.heading} size={size} />
                      )}
                    </SizeMe>
                  </div>
                );
              });
            }
          });
        } else {
          item.nodes.forEach((subItem: MenuEl) => {
            if (subItem.name === menuItemTarget.item) {
              subItem.nodes.forEach((em: MenuEl) => {
                const JSXSubItemElemmentsA: string[] = [];
                em.nodes.forEach((eem: MenuEl) => {
                  if (!JSXSubItemElemmentsA.includes(eem.heading)) JSXSubItemElemmentsA.push(eem.heading);
                });

                JSXSubItemElemmentsA.forEach((weem: string, o) => {
                  const JSXSubItemElemments: any = [];
                  em.nodes.forEach((eee: MenuEl) => {
                    if (eee.heading === weem) {
                      JSXSubItemElemments.push(eee);
                    }
                  });
                  JSXItemElemments.push(
                    <div className={classes.gridItem} key={o}>
                      <SizeMe monitorWidth={false} monitorHeight={true}>
                        {({ size }) => (
                          <LPContentCardVSE isDebugEnabled={this.props.isDebugEnabled} key={'tree' + o} data={JSXSubItemElemments} title={weem} size={size} />
                        )}
                      </SizeMe>
                    </div>
                  );
                });
              });
            }
          });
        }
      });
    }
    //
    return JSXItemElemments;
  };

 

  generateLayout(val) {
    const layouts = this.generateGridLayouts();
    return layouts[val];
  }

  render() {
    //
    const { classes } = this.props;
    //
    return (
      //<div>{this.props.currentBreakpoint}
      <Responsive
        autoSize={true}
        className={classes.root}
        layouts={this.generateGridLayouts()}
        width={this.props.size.width}
        onBreakpointChange={this.onBreakpointChange}
        onLayoutChange={this.onLayoutChange}
        measureBeforeMount={true}
        useCSSTransforms={this.props.mounted}
        isResizable={false}
        isDraggable={false}
        breakpoints={{ xxl: 3500, xl: 2220, lg: 1580, md: 1341, sm: 1026, xs: 500, xxs: 0 }}
        cols={{ xxl: 24, xl: 24, lg: 24, md: 24, sm: 24, xs: 24, xxs: 24 }}
        margin={this._margin}
        rowHeight={this.gridItemHeight[this.props.currentBreakpoint]} //md= 10 LG = 19
      >
        {this.subItemComposed()}
      </Responsive>
      //</div>
    );
  }
}

//
const LPContentItemStyled = withStyles(
  LPContentItem,
  (/*theme, props*/) => ({
    root: {
      width: '100%', marginTop: 0, border: 'none'
    },
    gridItem: { height: 'auto ! important', },
  })
);

export default connect<StateProps, DispatchProps, sentFromParentComponent, AppState>(mapStateToProps, mapDispatchToProps)(LPContentItemStyled);

