
import { makeStyles } from 'tss-react/mui';
import LPNavMenuTree from './LPNavMenuTree';
import LPNavigationSearch from './LPNavSearch';

const useStyles: any = makeStyles()((/*theme: Theme*/) => ({
  root: {
    height: '100%',
    maxWidth: 400,
    backgroundColor: '#2E2D2C',
    marginTop: 16,
    marginBottom: 16,
    marginLeft: 16,
    padding: 16,
    borderRadius: 5
  },
}));

export default function LPNavigationWrapper() {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <LPNavigationSearch />
        <LPNavMenuTree />
      </div>
    </div>
  );
}
