import React from "react";
import VarerDetails from "./components/VarerDetail";
import YearlySalesTable from "./components/YearlySales";

import { SalgVarerData,YearlySalesData,ProductSales } from "../../../models/NV01SALG";

interface YearlySalesProps {
  data: ProductSales[];
  sistesalg: string; // Ensure sistesalg is included in the props
  varerdata: SalgVarerData[];
}

const YearlySales: React.FC<YearlySalesProps> = ({
  data,
  sistesalg,
  varerdata,
}) => {

   // Extract YearlySalesData[] from the ProductSales[] data
   const extractYearlySalesData = (productSales: ProductSales[]): YearlySalesData[] => {
    // Assuming that you want to extract `total_sales` (which is YearlySalesData[]) from each product
    return productSales.flatMap((product) => product.total_sales);
  };
const data2=JSON.parse(JSON.stringify(data, null, 2)); data=data2;
  const yearlySalesData: YearlySalesData[] = extractYearlySalesData(data);
  return (
    <div>   
     <YearlySalesTable data={yearlySalesData} /> 
    </div>
  );
};

export default YearlySales;
