import { Backdrop, Button, CircularProgress, CssBaseline, Dialog, DialogActions, DialogTitle, Theme } from '@mui/material';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import Utils from "../../felles/Utils";
import MenuEl from '../../models/MenuEl';
import UISize from "../../models/UISize";
import * as actions from '../../store/actions/index';
import { AppState } from '../../store/rootReducer';
import SupportContent from "./SupportContent";
import LPAppBar from '../../components/LPAppBar';
import LPDrawerHeader from '../../components/LPDrawerHeader';
import LPDrawer from '../../components/LPDrawer';

const useStyles = makeStyles()((theme: Theme) =>
({
  root: { display: 'flex', height: '100vh', width: '100vw', },
  content: {
    marginTop: -10,
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }), marginLeft: -340,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }), marginLeft: 0,
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: '#fff', },
}),
);

export default function LPSupport() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const menuItems: MenuEl[] = useSelector(state => (state as AppState).menuItem.menuItems);
  const location = useLocation();
  const htmlContent: any = useSelector(state => (state as AppState).trans.content);
  const transError: any = useSelector(state => (state as AppState).trans.error);
  const navigate = useNavigate();
  // const getErrorMsg = () => transError && transError.error ? transError.error : '';
  const screenSize: UISize = useSelector(state => (state as AppState).ui.screenSize);
  dispatch(actions.setDrawerState(!(screenSize.width > 0 && screenSize.width < 1200)));
  const drawerOpenState: boolean = useSelector(state => (state as AppState).ui.isDrawerOpened);

  const feedbackPostState: number = useSelector(state => (state as AppState).support.feedbackPostState);

  const handleDiagClose = () => {
    dispatch(actions.transErrorDialogUpdated(false));
    dispatch(actions.setAutoErrorSendingState(0));
    navigate(Utils.HOMEPAGE_CONTEXT + '/menu');
  }

  useEffect(() => {
    htmlContent;
  }, [dispatch, htmlContent, feedbackPostState, location.search, menuItems])

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={feedbackPostState === 1}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog open={feedbackPostState > 1 && feedbackPostState !== 0} onClose={handleDiagClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{feedbackPostState === 2 ? 'Takk for din tilbakemelding' : feedbackPostState === 3 ? 'Feil ved sending av tilbakemelding' : null}</DialogTitle>
        <DialogActions>
          <Button onClick={handleDiagClose} color="primary" autoFocus>Ok</Button>
        </DialogActions>
      </Dialog>
      <CssBaseline />
      <LPAppBar />{transError ? transError.name : ''}
      <LPDrawer />
      <main className={clsx(classes.content, { [classes.contentShift]: drawerOpenState, })}>
        <LPDrawerHeader />
        <SupportContent />{transError ? transError.name : ''}
      </main>
    </div>
  );
}
