import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from '@mui/material';
import { Component } from "react";
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { AppState } from "../../../store/rootReducer";

const rows = [
  { id: "username", numeric: false, disablePadding: true, label: "brukernavn" },
  { id: "firstname", numeric: false, disablePadding: false, label: "Fornavn" },
  { id: "lastname", numeric: false, disablePadding: false, label: "Etternavn" },
  { id: "email", numeric: false, disablePadding: false, label: "Epost" },
  { id: "operatorId", numeric: true, disablePadding: false, label: "Operator ID" },
  { id: "lastLogonTimestamp", numeric: true, disablePadding: false, label: "Siste aktivitet" },
  { id: "action", numeric: false, disablePadding: false, label: "Action" },
];

class UserListTableHead extends Component<Props> {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { classes } = this.props;
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" className={classes.tableCell}>
            <Checkbox sx={{ color: 'white', '&.Mui-checked': { color: "red" } }}
              indeterminate={this.props.selectedUserList.length > 0 && this.props.selectedUserList.length < this.props.rowCount}
              checked={this.props.selectedUserList.length === this.props.rowCount}
              onChange={this.props.onSelectAllClick}
            />
          </TableCell>
          {rows.map(
            row => (
              <TableCell className={classes.tableCell}
                key={row.id}
                align={row.numeric ? "center" : "center"}
                padding={row.disablePadding ? "normal" : "normal"}
                sortDirection={(this.props.orderBy === row.id ? (this.props.order === "desc" ? "desc" : "asc") : undefined)/**/}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  {row.id !== 'action' ? <TableSortLabel
                    active={this.props.orderBy === row.id}
                    direction={(this.props.order === "desc" ? "desc" : "asc")}
                    onClick={this.createSortHandler(row.id)}
                  >{row.label}</TableSortLabel> : <>{row.label + ''}</>}
                </Tooltip>
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}
//
const UserListTableHeadStyled = withStyles(
  UserListTableHead,
  (/*theme, props*/) => ({
    tableCell: {
      marginLeft: -5, padding: 3, textAlign: 'left',
      backgroundColor: '#4b4948', color: 'white', border: 'none',
      "& .MuiTableSortLabel-root:hover, .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon": {
        color: "#9eacff"
      },
      // for active case hover color
      "& .MuiTableSortLabel-root.Mui-active:hover, .MuiTableSortLabel-root.Mui-active:hover .MuiTableSortLabel-icon": {
        color: "#9eacff"
      },
      "& .MuiTableSortLabel-root.Mui-active, .MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon": {
        fontWeight: 600,
        color: "#ffb89e"
      }
    },
  })
);

//
interface sentFromParentComponent {
  numSelected: number;
  order?: string;
  orderBy?: string;
  onSelectAllClick: any;
  rowCount: number;
  onRequestSort: (event: any, property: any) => void;
}

interface StateProps {
  selectedUserList: string[];
  classes?: any;
}

type Props = sentFromParentComponent & StateProps/* & DispatchProps*/;

export function mapStateToProps(state: AppState): StateProps {
  return {
    selectedUserList: state.userAdmin.selectedUserList,
    classes: {},
  };
}

export default connect<StateProps, undefined, sentFromParentComponent, AppState>(mapStateToProps, null)(UserListTableHeadStyled);