
import * as actionTypesNV01 from "./actionTypesNO01";
import { TradingPartnersResponse } from '../../models/NO01Orku';

export const fetchTradingPartnersRequest = (companyId: number) => ({
  type: actionTypesNV01.TRADING_PARTNERS_GET,
  payload: companyId,
});

export const fetchTradingPartnersSuccess = (data: TradingPartnersResponse) => ({
  type: actionTypesNV01.TRADING_PARTNERS_GET_SUCCESS,
  payload: data,
});

export const fetchTradingPartnersFailure = (error: string) => ({
  type: actionTypesNV01.TRADING_PARTNERS_GET_FAILURE,
  payload: error,
});
export const fetchTradingPartnersReset = () => ({
  type: actionTypesNV01.TRADING_PARTNERS_RESET,
 
});


