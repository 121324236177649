import { Backdrop, CircularProgress, Dialog, DialogTitle, Theme, Tooltip, Typography, useAutocomplete } from '@mui/material';
import classNames from 'classnames';
import { createRef, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import ButtonVitec from '../../../components/ButtonVitec';
import Utils from '../../../felles/Utils';
import FirmNavnId from '../../../models/FirmNavnId';
import LdapGroup from '../../../models/LdapGroup';
import LdapUser from '../../../models/LdapUser';
import * as actions from '../../../store/actions/index';
import { AppState } from '../../../store/rootReducer';

const useStyles = makeStyles()((theme: Theme) => ({
  label: { display: 'block', color: 'black', },
  input: {
    width: 120,
    color: 'black',
    marginTop: 3,
    marginLeft: 0,
    paddingLeft: 10,
    borderRadius: 0,
    border: '1px solid #ccc',
    height: 38,
  },
  listbox: {
    scrollbarwidth: 'none',
    margin: 0,
    padding: 0,
    zIndex: 1,
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    maxHeight: 200,
    border: '1px solid rgba(0,0,0,.25)',
    '& li': {
      margin: 'auto',
      backgroundColor: 'white',
      color: 'black',
      cursor: 'pointer',
      height: 30,
      width: 110,
      padding: 5,
    },
    '& li[data-focus="true"]': { backgroundColor: '#f6f6f6', color: 'black', cursor: 'pointer', },
    '& li:active': { backgroundColor: '#2977f5', color: 'black', },
  },
  root: { width: '100%', marginTop: -40, paddingRight: theme.spacing(1), color: 'black', },
  customTooltip: {
    // I used the rgba color for the standard "secondary" color
    backgroundColor: '#fff',
    fontSize: '1em',
    color: 'black',
    border: '1px solid #fff',
    margin: 0,
    marginTop: 10,
    marginLeft: -5,
  },
  customArrow: { color: ' #fff', },

  formAdd: {
    marginTop: 'min(20%)',
    marginBottom: 'min(20%)',
    boxShadow: '3px 3px 3px #222',
    horizontalAlign: 'center',
    backgroundColor: '#f6f6f6',
    width: 630,
    margin: '0 auto',
    padding: 30,
    paddingTop: 15,
    paddingBottom: 5,
    border: '1px solid #333',
    borderRadius: '5px',
  },
  myInputWrapper: {
    display: 'flex',
    width: '100%',
    marginBottom: 20,
  },
  myInput: { width: '100%', padding: '10px', marginBottom: '14px', marginTop: '3px', border: '1px solid #ccc' },
  myInputShort: { width: '70px', padding: '10px', marginBottom: '14px', marginTop: '3px', marginLeft: '10px', border: '1px solid #ccc' },
  myInputVeryShort: {
    userSelect: 'none',
    width: '40px',
    padding: '10px',
    marginBottom: '10px',
    marginTop: '3px',
    marginLeft: '10px',
    border: '1px solid #ccc',
  },
  myInputVeryShortC: {
    userSelect: 'none',
    width: '200px',
    padding: '10px',
    marginBottom: '10px',
    marginTop: '3px',
    marginLeft: '10px',
    border: '1px solid #ccc',
  },
  myInputVeryShortB: {
    userSelect: 'none',
    webkitUserSelect: 'none',
    width: '50px',
    padding: '10px',
    marginBottom: '10px',
    marginTop: '3px',
    border: '1px solid #ccc',
  },
  myLabel: { width: 600, fontSize: '1.2em' },
  myTitle: { width: 600, fontSize: '1.6em', marginBottom: '15px', color: 'black' },
  myInputTitle: { width: 600, fontSize: '1.3em', color: 'grey' },
  formError: { fontSize: '1em', marginTop: '30px', color: 'red', align: 'center' },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: '#fff' },
  cprogress: { color: '#000', with: '50%', textAlign: 'center' },
  checkboxWrapper: { color: '#000' },
  checkbox: { display: 'inline-flex', marginLeft: 10, marginBottom: 10 },
  grpCheckbox: { marginLeft: 4, color: '#000', align: 'top' },
  myButtons: { display: 'inline-flex', verticalAlign: 'top' },
  myButton: { padding: '10px', margin: '0px 0px 0px 0px' },
  myButtonB: { padding: '10px', margin: '0px 10px 0px 10px' },
  dialogCustomizedWidth: { padding: '20px 0px 40px 0px', 'min-width': '400px', 'max-width': '400px', 'min-height': '150px' },
  myLabelUsername: { marginLeft: 10, fontSize: '1.2em' },
  myLabelUsernameB: { fontSize: '1.2em' },
  myLabelPrefix: { width: 105, fontSize: '1.2em' },
  paper: { background: 'white', color: 'black', },
  dInput: {
    color: 'black', backgroundColor: 'white',
    '&:focus': { borderRadius: 4, borderColor: 'rgba(255,255,255,0.2)', boxShadow: '0 0 0 0.2rem rgba(0,190,255,0.6)', background: 'rgba(0,0,0,0)', },
  },
  myUserNameA: { display: 'block', width: '100%', height: 70, },

  '@media only screen and (min-width: 1024px) and (max-height: 1366px)  and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 1.5)': {
    formAdd: { width: 700, margin: '0 auto', padding: 10, },
    myInput: { width: '100%', padding: '3px', marginBottom: 5, marginTop: '0px', border: '1px solid #ccc' },
    myInputShort: { width: '70px', padding: '3px', marginBottom: 0, marginTop: '3px', marginLeft: '5px', border: '1px solid #ccc' },
    myInputVeryShort: {
      userSelect: 'none',
      width: '40px',
      padding: '5px',
      marginBottom: '5px',
      marginTop: '3px',
      marginLeft: '10px',
    },
    myInputVeryShortC: {
      userSelect: 'none',
      width: '200px',
      padding: '5px',
      marginBottom: '5px',
      marginTop: '3px',
      marginLeft: '10px',
    },

    myLabelUsername: { marginLeft: 10, fontSize: '1em' },
    myLabelUsernameB: { fontSize: '1em' },
    myLabelPrefix: { width: 105, fontSize: '1em' },
    myInputVeryShortB: { padding: '5px', marginBottom: '5px', marginTop: '3px', },
    myLabel: { width: 600, fontSize: '1em', margin: 0, marginTop: '13px', },
    myTitle: { width: 600, fontSize: '1.3em', marginBottom: '5px', color: 'black' },
    myInputTitle: { width: 600, fontSize: '1em', color: 'grey' },
    checkbox: { display: 'inline-flex', marginLeft: 5, marginBottom: 5 },
    grpCheckbox: { marginLeft: 4, },
    myButton: { paddingLeft: '40px', },
    myButtonB: { padding: '5px', margin: '0px 5px 0px 5px' },
    myUserNameA: { height: 50, },
    input: { height: 27, borderRadius: '5px', },
  },
}));

type FormInputs = {
  groups: string[],
  firmID: string,
  firmname: string,
  prefix: string,
  username: string,
  firstname: string,
  lastname: string,
  email: string,
  emailConfirmation: string,
  mobile: string,
}


const UserAdd = (/*props*/) => {
  const { classes } = useStyles();
  const firmListFromAdmin: FirmNavnId[] = useSelector((state) => (state as AppState).userAdmin.firmListFromAdmin);
  const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } = useAutocomplete({
    id: 'firmID',
    options: [...firmListFromAdmin],
    getOptionLabel: (option) => option.firmID + '',
  });

  const dispatch = useDispatch();
  const open: boolean = useSelector((state) => (state as AppState).ui.isDrawerOpened);
  const { register, getValues, setValue, handleSubmit, formState: { errors } } = useForm<FormInputs>();
  const formRef: any = createRef();
  const userSelectedGroupList: string[] = useSelector((state) => (state as AppState).userAdmin.userSelectedGroupList);
  const userRegistrationState: number = useSelector((state) => (state as AppState).userAdmin.userRegistrationState);
  const isRegisteringUser: boolean = useSelector((state) => (state as AppState).userAdmin.isRegisteringUser);
  const userAdmin: any = useSelector((state) => (state as AppState).auth.userJWT);
  const userDataList: LdapUser[] = useSelector((state) => (state as AppState).userAdmin.userDataList);
  const groupListFromAdmin: LdapGroup[] = useSelector((state) => (state as AppState).userAdmin.groupListFromAdmin);
  const [userIsValid, setUserIsValid] = useState(false);
  const [operatorId, setOperatorId] = useState('');
  const [firmPrefix, setFirmPrefix] = useState('');
  const [firmName, setFirmName] = useState('');
  const navigate = useNavigate();

  /**/
  useEffect(() => {
    dispatch(actions.groupListGet());
    dispatch(actions.firmListFromDBGet());
    setValue('firmID', '');
    setValue('firmname', '');
    setValue('prefix', '');
    setValue('username', '');
    setValue('firstname', '');
    setValue('lastname', '');
    setValue('email', '');
    setValue('emailConfirmation', '');
    setValue('mobile', '');
  }, [dispatch, setValue /*, userLDAPData*/]);

  //
  const onSubmit = (data: any = undefined) => {
    const nu: LdapUser = new LdapUser(
      firmPrefix + data.username,
      data.firstname,
      data.lastname,
      data.mobile,
      firmName || '', // company: string = '',
      '', // displayName: string = '',
      operatorId + '' || '', // OperatorId: string = '',
      '', // userPrincipalName: string = '',
      data.email, // mail: string = '',
      '', // lastLogonTimestamp: string,
      data.memberOf,
      userSelectedGroupList,
      false
    );
    dispatch(actions.userRegistration(nu));
  };

  // needs a rewrite - simply redundant
  const onSubmitWithPWD = (data: any = undefined) => {
    //
    const nu: LdapUser = new LdapUser(
      firmPrefix + data.username,
      data.firstname,
      data.lastname,
      data.mobile,
      firmName || '', // company: string = '',
      '', // displayName: string = '',
      operatorId || '', // OperatorId: string = '',
      '', // userPrincipalName: string = '',
      data.email,
      '', // lastLogonTimestamp: string,
      data.memberOf,
      userSelectedGroupList,
      true
    );
    dispatch(actions.userRegistration(nu));
  };

  const onCancel = () => {
    dispatch(actions.userProfileDataDialogUpdated(false));
    navigate(Utils.HOMEPAGE_CONTEXT + '/menu');
    dispatch(actions.setUserRegistrationState(0));
  };

  const handleCheckboxClick = (event, username: string) => {
    event.stopPropagation();
    const selectedIndex = userSelectedGroupList.indexOf(username as string);
    let newSelectedList: string[] = [];
    if (selectedIndex === -1) {
      newSelectedList = newSelectedList.concat(userSelectedGroupList, username);
    } else if (selectedIndex === 0) {
      newSelectedList = newSelectedList.concat(userSelectedGroupList.slice(1));
    } else if (selectedIndex === userSelectedGroupList.length - 1) {
      newSelectedList = newSelectedList.concat(userSelectedGroupList.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedList = newSelectedList.concat(userSelectedGroupList.slice(0, selectedIndex), userSelectedGroupList.slice(selectedIndex + 1));
    }
    dispatch(actions.setUserSelectedGroupList(newSelectedList));
    setValue('groups', newSelectedList);
  };

  //
  const renderCheckboxList = () => {
    return groupListFromAdmin.sort(Utils.compareByNameFilter).map((group: LdapGroup, index: number) => {
      return group.name !== 'ALLUSERS' /* && group.name !== 'MINIADMIN' && group.name !== 'ADMIN'*/ ? (
        <Tooltip
          key={'tp' + group.name}
          classes={{
            tooltip: classes.customTooltip,
            arrow: classes.customArrow,
          }}
          title={'' + group.description}
          arrow
        >
          <div className={classes.checkbox} key={'dg' + group}>
            <input
              type="checkbox"
              className={classes.checkbox}
              id={group.name}
              name={group.name}
              key={'group' + group.name}
              onClick={(event) => {
                handleCheckboxClick(event, group.name);
              }}
            />
            <div key={'c' + index} className={classes.grpCheckbox}>
              {group.name}
            </div>
          </div>
        </Tooltip>
      ) : null;
    });
  };

  //
  const handleChange = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const target = formRef.current;
    let firmIDInput: any = undefined;
    let firmnameInput: any = undefined;
    let prefixInputField: any = undefined;
    let fName = '';
    //
    try {
      for (let i = 0; i < target.length; i++) {
        fName = target.elements[i].getAttribute('name');
        //
        if (fName === 'firmID') {
          firmIDInput = target.elements[i];
        }

        //
        if (fName === 'firmname') {
          firmnameInput = target.elements[i];
        }

        //
        if (fName === 'prefix') {
          prefixInputField = target.elements[i];
        }
      }

      //
      for (let i = 0; i < target.length; i++) {
        if (e.target.nodeName.toString() === 'INPUT' /* && fName === 'prefix'*/) {
          setValue('firmID', (e.target as any).value.toUpperCase());
          //if (firmListFromAdmin.length > 0) {
          const first = firmListFromAdmin.find((obj) => {
            return obj.firmID === (e.target as any).value;
          });
          //
          //  setValue('prefix', first?.firmPrefix || '');
          setFirmPrefix(first?.firmPrefix || '');
          setFirmName(first?.firmName || '');
          setOperatorId(first?.firmID + '' || '');
          prefixInputField.setAttribute('value', first?.firmPrefix || '');
        }
        //}
        //
        if (e.target.nodeName.toString() === 'LI') {
          const selectedOptions: any = groupedOptions[e.target.getAttribute('data-option-index')];
          setValue('firmID', selectedOptions.firmID + '');
          //setValue('firmname', selectedOptions.firmName.trim());
          setValue('prefix', selectedOptions.firmPrefix.trim());
          setFirmPrefix(selectedOptions.firmPrefix.trim());
          setFirmName(selectedOptions.firmName.trim());
          setOperatorId(selectedOptions.firmID + '');
          firmIDInput.setAttribute('value', selectedOptions.firmID + '');
          firmnameInput?.setAttribute('value', selectedOptions.firmName.trim());
          prefixInputField.setAttribute('value', selectedOptions.firmPrefix.trim());
        }
      }
    } catch (error) {
      console.log(error);

    }
    //

  };

  //
  const handleDiagValidateUserSaved = () => {
    dispatch(actions.userListFetch());
    dispatch(actions.setUserRegistrationState(0));
  };

  //
  const generateUserRegistrationStates = () => {
    switch (true) {
      case userRegistrationState === 0:
        return (
          <>
            {!isRegisteringUser ? (
              <form ref={formRef} className={classes.formAdd}>
                <div className={classes.myTitle}>Legg til ny bruker</div>
                <div className={classes.myInputWrapper}>
                  {groupListFromAdmin.filter((e) => e.name === 'ADMIN').length > 0 ? (
                    <>
                      <div className={classes.myUserNameA}>
                        <label htmlFor="firma-prefiks" className={classNames(classes.myLabelPrefix)}>
                          Firma-prefiks
                        </label>
                        <div>
                          <div {...getRootProps()}>
                            <input
                              {...register("firmID", { required: true })}
                              className={classes.input}
                              {...getInputProps()}
                              onInput={handleChange}
                              onKeyUp={handleChange}
                            />
                          </div>
                          {groupedOptions.length > 0 ? (
                            <ul className={classes.listbox} {...getListboxProps()}>
                              {groupedOptions.map((option, index) => (
                                <li
                                  {...getOptionProps({ option, index })}
                                  onMouseUp={(e) => {
                                    handleChange(e);
                                    //  setValue('firmID', 'BK');
                                  }}
                                >
                                  {option.firmID} - {option.firmPrefix}
                                </li>
                              ))}
                            </ul>
                          ) : null}
                        </div>
                      </div>
                      <div className={classes.myUserNameA}>
                        <label htmlFor="firmname" className={classNames(classes.myLabelUsername)}>
                          Firmanavn
                        </label>
                        <div>
                          <input
                            className={classNames(classes.myInputVeryShortC)}
                            type="text"
                            id="firmname"
                            name="firmname"
                            disabled
                            readOnly
                            onChange={(e) => {
                              setValue('firmname', (e.target as any).value.toUpperCase());
                            }}
                          />
                        </div>
                      </div>
                      <div className={classes.myUserNameA}>
                        <label htmlFor="brukernavn" className={classNames(classes.myLabelUsername)}>
                          Brukernavn
                        </label>
                        <div>
                          <input
                            className={classNames(classes.myInputVeryShort)}
                            type="text"
                            id="prefix"
                            name="prefix"
                            disabled
                            readOnly
                            onChange={(e) => {
                              setValue('prefix', (e.target as any).value.toUpperCase());
                            }}
                          />
                          <input
                            className={classNames(classes.myInputShort)}
                            type="text"
                            id="username"

                            maxLength={6}
                            {...register("username", {
                              required: true, validate: () => {
                                return userIsValid;
                              }
                            })}
                            onChange={(e) => {
                              const found = userDataList.find((obj) => {
                                return obj.username.toString() === getValues('firmID') + (e.target as any).value.toUpperCase();
                              });
                              setValue('username', (e.target as any).value.toUpperCase());
                              setUserIsValid(found === undefined);
                            }}
                            placeholder="***"
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className={classes.myUserNameA}>
                      {' '}
                      {/************************************************************************************ */}
                      <label htmlFor="brukernavn" className={classNames(classes.myLabelUsernameB)}>
                        Brukernavn
                      </label>
                      <div>
                        <input
                          className={classNames(classes.myInputVeryShortB)}
                          type="text"
                          id="prefix"
                          name="prefix"
                          disabled
                          readOnly
                          value={userAdmin.sub.substring(0, 2)}
                        />
                        <input
                          className={classNames(classes.myInputShort)}
                          type="text"
                          id="username"
                          maxLength={6}
                          {...register("username", {
                            required: true, validate: () => {
                              return userIsValid;
                            }
                          })}
                          onChange={(e) => {
                            setFirmPrefix(userAdmin.sub.substring(0, 2));
                            const found = userDataList.find((obj) => {
                              return obj.username.toString() === userAdmin.sub.substring(0, 2) + (e.target as any).value.toUpperCase();
                            });
                            setValue('username', (e.target as any).value.toUpperCase());
                            setUserIsValid(found === undefined);
                          }}
                          placeholder="***"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <label htmlFor="firstname" className={classNames(classes.myLabel)}>
                  Fornavn
                </label>
                <input
                  className={classNames(classes.myInput)}
                  type="text"
                  id="firstname"
                  {...register("firstname", { required: true, maxLength: 40 })}
                  onChange={(e) => {
                    setValue('firstname', (e.target as any).value);
                  }}
                />
                <label htmlFor="lastname" className={classNames(classes.myLabel)}>
                  Etternavn
                </label>
                <input
                  className={classNames(classes.myInput)}
                  type="text"
                  id="lastname"
                  {...register("lastname", { required: true, maxLength: 40 })}
                  onChange={(e) => {
                    setValue('lastname', (e.target as any).value);
                  }}
                />
                <label htmlFor="emailA" className={classNames(classes.myLabel)}>
                  E-post
                </label>
                <input
                  className={classNames(classes.myInput)}
                  type="email"
                  id="email"
                  {...register("email", { required: true, maxLength: 100 })}
                  onChange={(e) => {
                    setValue('email', (e.target as any).value);
                  }}
                />
                <label htmlFor="emailB" className={classNames(classes.myLabel)}>
                  Bekreft E-post
                </label>
                <input
                  className={classNames(classes.myInput)}
                  type="text"
                  id="emailConfirmation"
                  {...register("emailConfirmation", { validate: { emailEqual: (value) => value === getValues().email || 'Email confirmation error!' } })}

                  onChange={(e) => {
                    setValue('emailConfirmation', (e.target as any).value);
                  }}
                />
                <label htmlFor="mobile" className={classNames(classes.myLabel)}>
                  Mobil
                </label>
                <input
                  className={classNames(classes.myInput)}
                  type="tel"
                  id="mobile"
                  {...register("mobile")}
                  onChange={(e) => {
                    setValue('mobile', (e.target as any).value);
                  }}
                />
                {/************************************************************************************************/}
                <label htmlFor="groups" className={classNames(classes.myLabel)}>
                  Grupper
                </label>
                <br />
                {groupListFromAdmin.length < 1 ? (
                  <div className={classes.cprogress}>
                    <CircularProgress color="inherit" />
                  </div>
                ) : (
                  renderCheckboxList()
                )}
                {/************************************************************************************************/}
                <div className={classes.myButtons}>
                  <div className={classes.myButton}>
                    <ButtonVitec _onclick={handleSubmit(onSubmit)} _width={80} _height={37} _type={'submit'} _label={'Lagre'} />
                  </div>
                  <div className={classes.myButton}>
                    <ButtonVitec
                      _onclick={handleSubmit(onSubmitWithPWD)}
                      _width={335}
                      _height={37}
                      _type={'button'}
                      className={classes.myButton}
                      _label={'Lagre og send lenke for å lage passord'}
                    />
                  </div>
                  <div className={classes.myButton}>
                    <ButtonVitec _onclick={() => onCancel()} _width={80} _height={37} _type={'button'} _label={'Avbryt'} />
                  </div>
                </div>
                <div className={classes.formError}>
                  {groupListFromAdmin.filter((e) => e.name === 'ADMIN').length > 0 && errors.firmID && errors.firmID.type === 'required' && (
                    <div>Vennligst skriv inn firmanummeret.</div>
                  )}
                  {errors.username && <div>Brukernavnet er allerede i bruk eller mangler</div>}
                  {errors.firstname && errors.firstname.type === 'required' ? <div>Vennligst skriv inn fornavnet ditt.</div> : <div>&nbsp;</div>}
                  {errors.firstname && errors.firstname.type === 'maxLength' && <div> Maks lengde av fornavnet er 40 bokstaver.</div>}
                  {errors.lastname && errors.lastname.type === 'required' && <div>Vennligst skriv inn Etternavn ditt.</div>}
                  {errors.lastname && errors.lastname.type === 'maxLength' && <div> Maks lengde av Etternavn er 40 bokstaver.</div>}
                  {errors.mobile && errors.mobile.type === 'required' && <div>Vennligst skriv inn mobilnummeret ditt.</div>}
                  {errors.mobile && errors.mobile.type === 'maxLength' && <div> Maks lengde av mobilnummeret er 20 nummer.</div>}
                </div>
              </form>
            ) : (
              <CircularProgress color="inherit" />
            )}
          </>
        );
      case userRegistrationState === 1:
        return <CircularProgress color="inherit" />;
      case userRegistrationState === 2:
        return (
          <Dialog
            open={open}
            fullWidth
            classes={{ paperFullWidth: classes.dialogCustomizedWidth }}

            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Brukerinformasjon lagret</DialogTitle>
            <Typography align="center" variant="subtitle1" component="div">
              <div className={classes.myButtons}>
                <div className={classes.myButtonB}>
                  <ButtonVitec _onclick={handleDiagValidateUserSaved} _width={180} _height={37} _type={'button'} _label={'Legg til ny bruker'} />
                </div>
                <div className={classes.myButtonB}>
                  <ButtonVitec _onclick={onCancel} _width={80} _height={37} _type={'button'} _label={'Ferdig'} />
                </div>
              </div>
            </Typography>
          </Dialog>
        );
      case userRegistrationState === 3:
        return (
          <Dialog
            open={open}
            fullWidth
            classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Brukernavnet er allerede i bruk</DialogTitle>
            <Typography align="center" variant="subtitle1" component="div">
              <div className={classes.myButtonB}>
                <ButtonVitec _onclick={onCancel} _width={80} _height={37} _type={'button'} _label={'Ferdig'} />
              </div>
            </Typography>
          </Dialog>
        );
      default:
        return null;
    }
  };
  //
  return (
    <Backdrop className={classes.backdrop} open={true}>
      {generateUserRegistrationStates()}
    </Backdrop>
  );
};

export default UserAdd;
