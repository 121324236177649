import { Button, TextField, Theme } from '@mui/material';
import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import C from '../../../felles/UtilsCSS';

const useStyles = makeStyles()((t: Theme) => ({
  root: {
    width: '100%',    height: 50,
    '& .MuiButton-root': {
      marginTop: '5px',
      marginBottom: '15px',
      width: '80%',
      backgroundColor: '#d30535',
      '&:hover': { backgroundColor: '#cd5a6e', boxShadow: 'none', },
    },
    //
    '& .MuiInputBase-input': {
      height: 25,
      margin: 0,
      padding: 0,
      paddingLeft: '5px',
      '&:hover': { backgroundColor: '#f6f6f6', },
      //Media
      [C.r(t, C.XXS, C.XXX)]: { height: 40 },
      [C.r(t, C.XS, C.XSX)]: { height: 40 },
      [C.r(t, C.SM, C.SMX)]: { height: 40 },
      [C.r(t, C.MD, C.MDX)]: { height: 40 },
      [C.r(t, C.LG, C.LGX)]: { height: 40 },
      [C.r(t, C.XL, C.XLX)]: { height: 40 },
      [C.r(t, C.XXL, C.YLX)]: { height: 40 },
      [C.SAMSUNG]: { height: 25 },
      [C.IPAD_10]: { height: 25 },
    },
    // root
    [C.r(t, C.XXS, C.XXX)]: { fontSize: '0.7em' },
    [C.r(t, C.XS, C.XSX)]: { fontSize: '0.8em' },
    [C.r(t, C.SM, C.SMX)]: { fontSize: '0.8em' },
    [C.r(t, C.MD, C.MDX)]: { fontSize: '.9em' },
    [C.r(t, C.LG, C.LGX)]: { fontSize: '1em' },
    [C.r(t, C.XL, C.XLX)]: { fontSize: '1em' },
    [C.r(t, C.XXL, C.YLX)]: { fontSize: '1em' },
  },

  formroot: { margin: '5px auto', display: ' inline-block', width: '100%', },
  login: { width: '100%', textAlign: 'center', },
  fieldWrapper: { width: '80%', margin: '0 auto', },
  formTextField: { width: '100%' },
  fLabel: {
    width: '100%', marginTop: 10,
    [C.r(t, C.XXS, C.XXX)]: { marginTop: 0, fontSize: '1em' },
    [C.r(t, C.XS, C.XSX)]: { marginTop: 0, fontSize: '1em' },
    [C.r(t, C.SM, C.SMX)]: { marginTop: 4, fontSize: '1em' },
    [C.r(t, C.MD, C.MDX)]: { marginTop: 5, fontSize: '1em' },
    [C.r(t, C.LG, C.LGX)]: { marginTop: 6, fontSize: '1em' },
    [C.r(t, C.XL, C.XLX)]: { marginTop: 6, fontSize: '1em' },
    [C.r(t, C.XXL, C.YLX)]: { marginTop: 6, fontSize: '1em' },
    [C.IPAD_10]: { fontSize: '1em' },
    [C.SAMSUNG]: { marginTop: 0, fontSize: '.9em' },
  },
  formSubmitBt: { width: '50px', textDecoration: 'none', },
  bt: {
    width: '100px !important', fontSize: '.9em',
    // media q.
    [C.r(t, C.XXS, C.XXX)]: { height: 30, width: '100px !important', fontSize: '.8em' },
    [C.r(t, C.XS, C.XSX)]: { height: 30, width: '110px !important', fontSize: '.8em' },
    [C.r(t, C.SM, C.SMX)]: { height: 30, width: '120px !important', fontSize: '.8em' },
    [C.r(t, C.MD, C.MDX)]: { height: 30, width: '150px !important', fontSize: '1em' },
    [C.r(t, C.LG, C.LGX)]: { height: 35, width: '200px !important', fontSize: '.8em' },
    [C.r(t, C.XL, C.XLX)]: { height: 35, width: '200px !important', fontSize: '.9em' },
    [C.r(t, C.XXL, C.YLX)]: { height: 35, width: '200px !important', fontSize: '1em' },
  },
  btWrap: {
    width: '100%', textAlign: 'center',
    // media q.
    [C.r(t, C.XXS, C.XXX)]: { marginTop: -5 },
    [C.r(t, C.XS, C.XSX)]: { marginTop: -1 },
    [C.r(t, C.SM, C.SMX)]: { marginTop: 0 },
    [C.r(t, C.MD, C.MDX)]: { marginTop: 5 },
    [C.r(t, C.LG, C.LGX)]: { marginTop: 5 },
    [C.r(t, C.XL, C.XLX)]: { marginTop: 5 },
    [C.r(t, C.XXL, C.YLX)]: { marginTop: 5 },
  },
})
);
/*
interface Props {
  children: React.ReactElement;
  open: boolean;
  value: number;
}
*/
export default function CAutodataWeb() {
  const { classes } = useStyles();
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');

  //
  function handleSubmit(event) {
    event.preventDefault();
    // TODO: PARAMETERIZE THE LINK IN .ENV
    const newWindow = window.open(
      'https://gw2.autodata.no/signon.php?frm_ident=' + user + '&frm_passord=' + password + '&frm_action=200',
      '_blank',
      'noopener,noreferrer'
    );
    // rewrite this
    if (!user.includes('')) if (newWindow) newWindow.opener = null;
  }

  //
  function getAutodata() {
    //
    return (
      <>
        <form className={classes.formroot} noValidate autoComplete="off" onSubmit={handleSubmit}>
          <div className={classes.fieldWrapper}>
            <div className={classes.fLabel}>Brukernavn</div>
            <TextField
              className={classes.formTextField}
              type="text"
              name="user"
              autoComplete="new-password"
              onInput={(e) => setUser((e.target as HTMLTextAreaElement).value)}
              placeholder=""
              variant="outlined"
              inputProps={{ tabIndex: 1 }}
            />
            <div className={classes.fLabel}>Passord</div>
            <TextField
              autoComplete="new-password"
              className={classes.formTextField}
              type="password"
              name="password"
              onInput={(e) => setPassword((e.target as HTMLTextAreaElement).value)}
              placeholder=""
              variant="outlined"
              inputProps={{ tabIndex: 2 }}
            />
          </div>
          <div>
            <div className="row marginTopBottom5"> </div>
          </div>
          <div className={classes.btWrap}>
            <Button type="submit" variant="contained" color="primary" disableElevation tabIndex={3} className={classes.bt}>
              Logg inn
            </Button>
          </div>
        </form>
      </>
    );
  }
  return <div className={classes.root}>{getAutodata()}</div>;
}
