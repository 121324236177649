import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { Component } from 'react';
import 'react-grid-layout/css/styles.css';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import U from "../../../../felles/Utils";
import TaleSalesReportItem from '../../../../models/payload/TTReportItem';
import * as actions from '../../../../store/actions/index';
import { AppState } from '../../../../store/rootReducer';


import Utils from "../../../../felles/Utils";
import TaleSearchParams from '../../../../models/TaleSearchParams';
import TallenesTaleTableHeadVaregruppe from './TallenesTaleTableHeadVaregruppe';

interface sentFromParentComponent {
  classes?: any, //place Holder
}

interface StateProps {
  onRequestSort?: (event: any, property: any) => void;
  salesReport: TaleSalesReportItem[];
  taleSearch: TaleSearchParams;
  className?: string;
  classes?: Partial<Record<"root" | "story" | "label" | "container" | "table" | "tableWrapper" | "tableCell" | "spacer", string>>;
}
//
interface DispatchProps {
  setSandwichButtonStateClicked: (val: boolean) => void;
}

type Props = sentFromParentComponent & StateProps & DispatchProps;

export function mapStateToProps(state: AppState): StateProps {
  return {
    salesReport: state.tallenesTale.salesReportVGruppe,
    taleSearch: state.tallenesTale.taleSearchVGruppe,
  }
}

export function mapDispatchToProps(dispatch): DispatchProps {
  return {
    setSandwichButtonStateClicked: (val: boolean) => dispatch(actions.setSandwichButtonStateClicked(val)),
  }
}

class TallenesTaleListVaregruppe extends Component<Props> {
  //
  state = { order: "desc", orderBy: "nettA", page: 0, rowsPerPage: -1, };
  //Init
  componentDidMount() {
    document.body.removeEventListener('touchmove', U.preventDefault);
  }
  //
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "asc";
    if (this.state.orderBy === property && this.state.order === "asc") {
      order = "desc";
    }
    this.setState({ order, orderBy });
  };
  //
  render() {
    const { classes } = this.props;
    const { order, orderBy, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, this.props.salesReport.length - page * rowsPerPage);
    let totalA = 0;
    let totalB = 0;
    return (
      <>
        <div className={classes.tTa}>{this.props.taleSearch ? <>{'Salgsstatistikk etter varegruppe, '
          + this.props.taleSearch.butikk}</> : null}
        </div>
        <TableContainer className={classes.container}>
          <Table stickyHeader size="small" aria-labelledby="tableTitle">
            <TallenesTaleTableHeadVaregruppe order={order} orderBy={orderBy} rowCount={this.props.salesReport.length} onRequestSort={this.handleRequestSort} />
            <TableBody>
              {this.props.salesReport ? U.stableSort(this.props.salesReport, U.getSorting(order, orderBy))
                .map((n: TaleSalesReportItem, i) => {
                  totalA += n.nettA;
                  totalB += n.nettB;
                  //
                  return (
                    <TableRow hover tabIndex={-1} key={i}                    >
                      <TableCell className={classes.tBl}>{n.id}</TableCell>
                      <TableCell className={classes.tBl}>{n.navn}</TableCell>
                      <TableCell className={classes.tBl}>{n.nettA !== 0 ? U.formatter(n.nettA) : '-'}</TableCell>
                      <TableCell className={classes.tBl}>{n.nettB !== 0 ? U.formatter(n.nettB) : '-'}</TableCell>
                      <TableCell className={classes.tBl}><span style={{ color: n.diff < 0 ? 'red' : 'black' }}>{n.diff !== 0 ? U.formatter(n.diff) : '-'}</span></TableCell>
                      <TableCell className={classes.tBl}>{n.percent !== 0 ? <span style={{ color: n.diff < 0 ? 'red' : 'black' }}>{n.percent}</span> : null}</TableCell>
                    </TableRow>
                  );
                }) : null}
              {
                this.props.salesReport?.length > 0 ? <>
                  <TableRow style={{ height: 49 * emptyRows }}><TableCell colSpan={5} /></TableRow>
                  <TableRow hover tabIndex={-1} key={'LASTTR'}                    >
                    <TableCell className={classes.tBl}></TableCell>
                    <TableCell className={classes.tBl}>Totale summene: </TableCell>
                    <TableCell className={classes.tBl}>{U.formatter(totalA)}</TableCell>
                    <TableCell className={classes.tBl}>{U.formatter(totalB)}</TableCell>
                    <TableCell className={classes.tBl}><span style={{ color: totalA - totalB < 0 ? 'red' : 'black' }}>{totalA - totalB < 0 ? ' ' : null}{U.formatter(totalA - totalB)}</span></TableCell>
                    <TableCell className={classes.tBl}>
                      {Utils.getPercentAB(totalA, totalB) !== 0 ? <span style={{ color: (totalA - totalB) < 0 ? 'red' : 'black' }}>{Utils.getPercentAB(totalA, totalB)}%</span> : null}
                    </TableCell>
                  </TableRow>
                  {emptyRows > 0 && (<TableRow style={{ height: 49 * emptyRows }}><TableCell colSpan={6} /></TableRow>)}</> : null
              }
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}

const TallenesTaleListVaregruppeStyled = withStyles(
  TallenesTaleListVaregruppe,
  () => ({
    tTa: { width: '100%', margin: '0 auto', marginBottom: 15, marginLeft: 20, marginTop: 15, fontSize: '1.3em', fontWeight: 'bold', },
    tBl: { textAlign: 'left', paddingLeft: 15, backgroundColor: '#fff', color: '#575655', borderBottom: '1px solid #c4c4c4', },
    container: { width: '100%', height: `calc(100vh - ${357}px)`, margin: '0 auto', },
  })
);

export default connect<StateProps, DispatchProps, sentFromParentComponent, AppState>(mapStateToProps, mapDispatchToProps)(TallenesTaleListVaregruppeStyled);


