import { Theme } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import U from '../../../felles/Utils';
import C from '../../../felles/UtilsCSS';
import * as actions from '../../../store/actions/index';
import { AppState } from '../../../store/rootReducer';


const useStyles = makeStyles()((theme: Theme) =>
({
  root: { width: '100%' },
  margin: { height: theme.spacing(3) },
  gridItem: {
    display: 'flex',
    padding: '10px 0',
    cursor: 'pointer',
    '&:hover': { backgroundColor: '#f6f6f6' },
  [C.r(theme, C.XXS, C.XXX)]: {fontSize: '.9em' },
  [C.r(theme, C.XS, C.XSX)]: {fontSize: '.9em' },
  [C.r(theme, C.SM, C.SMX)]: {fontSize: '1em' },
  [C.r(theme, C.MD, C.MDX)]: {fontSize: '1em' },
  [C.r(theme, C.LG, C.LGX)]: {fontSize: '1em' },
  [C.r(theme, C.XL, C.XLX)]: {fontSize: '1.1em' },
  [C.r(theme, C.XXL, C.YLX)]: {fontSize: '1.2em' },
  [C.IPAD_10]: {    padding: '5px 0',  },
  [C.SAMSUNG]: {     padding: '5px 0',  },


  },
  gridItemDate: { color: '#d30535', whiteSpace: 'nowrap' },
  gridItemTitle: { paddingLeft: '10px', color: '#2E2D2C' },

})
);

export default function CNyheter() {
  const { classes } = useStyles();
  const LINK_SITE = 'https://www.vitec-autosystemer.com';
  const dispatch = useDispatch();
  const nyheterContent: any = useSelector((state) => (state as AppState).utils.nyheterContent);
  const isNyheterContentLoading: any = useSelector((state) => (state as AppState).utils.isNyheterContentLoading);

  useEffect(() => {
    if (!nyheterContent && !isNyheterContentLoading) {
      dispatch(actions.nyheterMeldingFetchStarted());
    }
  }, [dispatch, nyheterContent, isNyheterContentLoading]);

  function handleHTMLResponseUpdate() {
    if (nyheterContent) {
      const _nyhetPost: any[] = [];
      (nyheterContent?.listitems as Array<any>)?.sort((a: any, b: any) => {
        return new Date(b.sortdate).getTime() - new Date(a.sortdate).getTime();
      }).forEach((item: any) => {
        _nyhetPost.push(
          <div key={U.I} className={classes.gridItem} onClick={(e) => handleClick(e, item.url)}>
            <div className={classes.gridItemDate}>{item.sortdate.substring(0, 10)}</div>
            <div className={classes.gridItemTitle}>{item.heading}</div>
          </div>
        );
      });
      return _nyhetPost;
    }
  }

  const handleClick = (event: any, l: any) => {
    event.preventDefault();
    const newWindow = window.open(LINK_SITE + '' + l, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };
  //
  return <div className={classes.root}>{handleHTMLResponseUpdate() || 'Loading...'}</div>;
}
