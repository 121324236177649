import { CssBaseline } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import PDFFormData from '../../models/PDFFormData';
import * as actions from '../../store/actions/index';

const useStyles: any = makeStyles()((theme) =>
({
  root: { display: 'flex', height: '100vh', width: '100vw' },
  appBar: {
    paddingTop: 21,
    backgroundColor: '#945f67',
    height: 100,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  rot: {
    width: 700,
    marginTop: 450,
    paddingRight: theme.spacing(1),
    zIndex: 9999,

    margin: ' 0 auto',
  },
  myInput: {
    width: 700,
    padding: '12px',
    margin: '10px',
    border: '1px solid #ccc',
  },
  myButton: {
    width: 300,
    padding: '12px',
    border: '1px solid #ccc',
  },
  myForm: {
    width: 300,
    padding: '12px',
    border: '1px solid #ccc',
  },
})
);

const PDFForm = () => {
  const dispatch = useDispatch();
  const {classes} = useStyles();
  //
  const onSubmit = (e) => {
    e.preventDefault();
    const body = new FormData();
    let fileName = '';
    const data = Array.prototype.filter.call(e.target.elements, (input) => {
      switch (input.type) {
        case 'file':
          fileName = input.value.split('fakepath\\')[1];
          if (fileName.includes('.')) fileName = fileName.split('.')[0] + '.pdf';
          break;
        default:
          break;
      }

      if (input.nodeName === 'BUTTON') return false;
      return true;
    });
    Array.prototype.map.call(data, (input) => {
      input.id !== 'file' ? body.append(input.id, input.value) : body.append('file', input.files[0]);
    });

    dispatch(actions.pdfGet(new PDFFormData(fileName, '', '', '', body)));
  };

  return (
    <div className={classes.root}>
      <CssBaseline />

      <div className={classNames(classes.rot)}>
        <form onSubmit={onSubmit} encType="multipart/form-data" className="form-add">
          <input className={classNames(classes.myInput)} type="file" id="file" accept=".txt" />
          <input className={classNames(classes.myInput)} type="email" id="Email" placeholder="Email  - return an attachment when not provided)" />
          <textarea className={classNames(classes.myInput)} id="description" rows={10} placeholder="Email body..."></textarea>
          <button className={classNames(classes.myInput)} type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default PDFForm;
