import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import PageAddUser from "../pages/admin-user/PageAddUser";
import PageProfil from "../pages/admin-user/PageProfil";
import PageUserList from "../pages/admin-user/PageUserList";
import Home from '../pages/home/Home';
import LandingPage from '../pages/landing-page/LandingPage';
import Logout from '../pages/logout/Logout';
import PasswordLinkRecovery from '../pages/password/PasswordLinkRecovery';
import PasswordUpdate from '../pages/password/PasswordUpdate';
import PDFForm from '../pages/pdf/PDFForm';
import LPSupport from '../pages/support/_LPSupport';
import NV03Page from "../pages/_transactions/NV03/NV03Page";
import ACC1Page from "../pages/_transactions/ACC1/ACC1Page";
import CriteriaItemPage from "../pages/_transactions/SS11/CriteriaItemPage";
import TallenesTalePage from "../pages/_transactions/SS10/TallenesTalePage";
import LPVSE from '../pages/vse/VSEPage';
import { AppState } from "../store/rootReducer";
import U from './Utils';
import requireAuth from "./requireAuth";
import NO01Page from '../pages/_transactions/NO01/NO01Page';
import NV01Page from '../pages/_transactions/NV01/NV01VARSPage';
/**
 * Routes configuration for the application.
 * Author: fabrice.miras@vitecsoftware.com
 * Date: 29.07.2024
 */

// Common routes available to all users
const commonRoutes = [
  { path: U.HOMEPAGE_CONTEXT, element: <Home /> },
  { path: `${U.HOMEPAGE_CONTEXT}/home`, element: <Home /> },
  { path: `${U.HOMEPAGE_CONTEXT}/logout`, element: <Logout /> },
  { path: `${U.HOMEPAGE_CONTEXT}/login.html`, element: <Home /> },
  { path: `${U.HOMEPAGE_CONTEXT}/login`, element: <Home /> },
  { path: `${U.HOMEPAGE_CONTEXT}/menu`, element: React.createElement(requireAuth(LandingPage)) },
  { path: `${U.HOMEPAGE_CONTEXT}/vse`, element: React.createElement(requireAuth(LPVSE)) },
  { path: `${U.HOMEPAGE_CONTEXT}/support`, element: React.createElement(requireAuth(LPSupport)) },
  { path: `${U.HOMEPAGE_CONTEXT}/passwordlinkrecovery`, element: <PasswordLinkRecovery /> },
  { path: `${U.HOMEPAGE_CONTEXT}/passwordupdate`, element: <PasswordUpdate /> },
  { path: `${U.HOMEPAGE_CONTEXT}/BR04`, element: React.createElement(requireAuth(PageProfil)) },
  { path: `${U.HOMEPAGE_CONTEXT}/NV03`, element: React.createElement(requireAuth(NV03Page)) },
  { path: `${U.HOMEPAGE_CONTEXT}/NO01`, element: React.createElement(requireAuth(NO01Page)) },
  { path: `${U.HOMEPAGE_CONTEXT}/NV01`, element: React.createElement(requireAuth(NV01Page)) },
];

/**
 * Generates role-based routes based on user roles.
 * @param {Object} userJWT - The JWT token containing user roles.
 * @returns {Array} - Array of route objects.
 */
const roleBasedRoutes = (userJWT) => {
  const routes: any = [];

  if (userJWT?.roles?.includes('ROLE_31') || userJWT?.roles?.includes('ROLE_32')) {
    routes.push({ path: `${U.HOMEPAGE_CONTEXT}/SS10`, element: React.createElement(requireAuth(TallenesTalePage)) });
  }
  if (userJWT?.roles?.includes('ROLE_32')) {
    routes.push({ path: `${U.HOMEPAGE_CONTEXT}/SS11`, element: React.createElement(requireAuth(CriteriaItemPage)) });
  }
  if (userJWT?.roles?.includes('ROLE_ADMIN')) {
    routes.push({ path: `${U.HOMEPAGE_CONTEXT}/BR00`, element: React.createElement(requireAuth(PageUserList)) });
    routes.push({ path: `${U.HOMEPAGE_CONTEXT}/BR01`, element: React.createElement(requireAuth(PageAddUser)) });
  }
  if (userJWT?.roles?.includes('ROLE_MINIADMIN')) {
    routes.push({ path: `${U.HOMEPAGE_CONTEXT}/BR02`, element: React.createElement(requireAuth(PageUserList)) });
    routes.push({ path: `${U.HOMEPAGE_CONTEXT}/BR03`, element: React.createElement(requireAuth(PageAddUser)) });
  }
  if (userJWT?.roles?.includes('ROLE_22')) {
    routes.push({ path: `${U.HOMEPAGE_CONTEXT}/ACC1`, element: React.createElement(requireAuth(ACC1Page, 'ACC1')) });
    routes.push({ path: `${U.HOMEPAGE_CONTEXT}/pdf`, element: <PDFForm /> });
  }

  return routes;
};

/**
 * Combines common routes and role-based routes into a single route configuration.
 * @param {Object} userJWT - The JWT token containing user roles.
 * @returns {Array} - Array of JSX Route elements.
 */
const generateRoutes = (userJWT) => {
  const routes = [
    ...commonRoutes,
    ...roleBasedRoutes(userJWT),
    { path: `${U.HOMEPAGE_CONTEXT}/robots.txt`, element: <Navigate to={`${U.HOMEPAGE_CONTEXT}/robots.txt`} replace={true} /> },
    { path: '*', element: <Navigate to={`${U.HOMEPAGE_CONTEXT}/menu`} replace={true} /> },
  ];

  return routes.map((route, index) => <Route key={index} path={route.path} element={route.element} />);
};

/**
 * Main function to get the route configuration based on user authentication.
 * @returns {JSX.Element} - Routes JSX element containing all route configurations.
 */
export default function getRoutes() {
  const userJWT = useSelector((state: AppState) => state.auth.userJWT);
  const routes = generateRoutes(userJWT);
  return <Routes>{routes}</Routes>;
}
